import { createAsyncThunk } from "@reduxjs/toolkit";

import * as URL from "helpers/url_helper";
import { get, post, put } from "helpers/api_helper";

export const getLevels = createAsyncThunk(
    "getLevels",
    async ({ data, callback }, Thunk) => {
        try {
            let response = await post(URL.GET_LEVELS, data);
            callback && callback(null, response.data);
            return response;
        } catch (error) {
            return Thunk.rejectWithValue(error);
        }
    }
);
export const addLevel = createAsyncThunk(
    "AddLevel",
    async ({ data, callback }, Thunk) => {
        try {
            let response = await post(URL.ADD_LEVEL, data);
            callback && callback(null, response);
            return response;
        } catch (error) {
            callback && callback(error?.data?.message || error.message || error);
            return Thunk.rejectWithValue(error);
        }
    }
);
export const updateLevel = createAsyncThunk(
    "updateLevel",
    async ({ data, callback }, Thunk) => {
        try {
            let response = await put(URL.UPDATE_LEVEL, data);
            callback && callback(null, response.data);
            return response;
        } catch (error) {
            callback && callback(error?.data?.message || error.message || error);
            return Thunk.rejectWithValue(error);
        }
    }
);