import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import * as Path from "routes/Path/index";
import YouTube from "react-youtube";
import { useSelector, useDispatch } from "react-redux";
import { USER } from "common/viewContent";
import Select from "react-select";
import {
  ARCHIEV_RELATIVE,
  USER_RELATIVE_PROFILE,
  deletePhoto,
  deleteVideo,
  updateVideo,
  updateImage,
  LINK_QR_TO_USER,
  UNLINK_QR_TO_USER,
  UPDATEUSER_RELATIVE_STATUS,
} from "store/Relative/actions";
import FadeLoader from "react-spinners/FadeLoader";
import ConfirmModal from "components/Modals/ConfirmModal";
import EditVideoModal from "components/Modals/EditVideoModal";
import EditPhotoModal from "components/Modals/EditPhotoModal";
import { Badge } from "react-bootstrap";
import { toast } from "react-toastify";
import { QRDETAILS, getSettings } from "store/actions";
import { calculateAge, formatYearsAndMonths } from 'helpers/common';
import BackButtom from "components/BackButtom";

const UserRelativeProfile = () => {
  const { loading: profileLoading, relative } = useSelector(
    (state) => state.Relative
  );
  const [activeTab, setActiveTab] = useState("bio");
  const { id: userId } = useParams();
  const dispatch = useDispatch();
  const [confirmModalVideo, setConfirModalVideo] = useState(false);
  const [confirmModalImage, setConfirModalImage] = useState(false);
  const [editVideoModal, setEditVideoModal] = useState(false);
  const [editPhotoModal, setEditPhotoModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [editedVideoData, setEditedVideoData] = useState(null);
  const [editedPhotoData, setEditedPhotoData] = useState(null);

  

  // Assume decimalNumber represents age in years with a fraction representing months
  const decimalNumber = relative?.data?.age;
  
  // Calculate age in years and months
  const ageData = calculateAge(decimalNumber);
  
  // Format the age for display
  const formattedAge = formatYearsAndMonths(ageData);




  // link Qr  functionality
  const [qrData, setQrData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedOption, setSelectedOption] = useState(null);
  const [linking, setLinking] = useState(false);
  const [filter, setFilter] = useState({
    pageNo: currentPage,
    limit: 20,
    order: 1,
    orderBy: "dateCreatedUtc",
    search: "",
    status: "pending",
  });
  const fetchData = async (filter) => {
    try {
      const callback = (err, res) => {
        if (err) {
          toast.error(err);
        } else {
          console.log(res, "response  data ");
          setQrData(res);
          toast.success(res.message);
        }
      };
      dispatch(QRDETAILS({ data: filter, callback }));
    } catch (error) {
      console.error(error, "<===err");
    }
  };
  useEffect(() => {
    fetchData(filter);
  }, [filter]);
  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const selectOptions = qrData.map((item) => ({
    value: item?.qrCodeID,
    label: item.qrCodeID,
  }));
  const handleInputChange = (inputValue) => {
    setFilter((prevState) => ({
      ...prevState,
      search: inputValue,
    }));
  };

  const handleLink = () => {
    if (selectedOption && !linking) {
      setLinking(true);
      const qrCodeID = selectedOption.value;
      const userProfileId = userId;
      dispatch(
        LINK_QR_TO_USER({
          qrCodeID: qrCodeID,
          userProfileId: userProfileId,
          callback: handleLinkCallback,
        })
      );
    } else if (!selectedOption) {
      toast.error("Please select a user profile");
    }
  };

  const handleLinkCallback = (error, res) => {
    if (error) {
      toast.error("Failed to link QR code to user profile");
    } else {
      toast.success(res.message);
      {
        res && dispatch(USER_RELATIVE_PROFILE({ data: userId }));
      }
    }
    setLinking(false);
  };

  const handleUnLink = () => {
    const type = "unlink";
    const userProfileId = userId;
    dispatch(
      UNLINK_QR_TO_USER({
        qrCodeID: relative?.data?.linkedQrCodeDetails?.qrCodeID,
        userProfileId: userProfileId,
        type:type,
        callback: handleUnLinkCallback,
      })
    );
  };
  const handleUnLinkCallback=(error, res)=>{
    if (error) {
      toast.error("Failed to Unlink QR code to user profile");
    } else {
      toast.success(res.message);
      {
        res && dispatch(USER_RELATIVE_PROFILE({ data: userId }));
      }
    }
    setLinking(false);
  }
  console.log(relative, "relatives=====");

  // images gets by setting
  const { settings, loading } = useSelector((state) => state.Settings);
  useEffect(() => {
    dispatch(getSettings());
  }, []);
  const API_IMAGE_URL = settings?.s3BaseUrl;

  useEffect(() => {
    if (userId) {
      dispatch(USER_RELATIVE_PROFILE({ data: userId }));
    }
  }, [dispatch, userId]);

  const toggleTab = (tab) => {
    setActiveTab(tab);
  };

  const handleEditVideo = (videoId) => {
    console.log("opening videos ", videoId);
    setEditVideoModal(true);
    const videoToEdit = relative.data.videos.find(
      (video) => video._id === videoId
    );
    setEditedVideoData(videoToEdit);
  };

  const handleEditPhoto = (photoId) => {
    console.log("openingimages", photoId);
    setEditPhotoModal(true);
    const photoToEdit = relative.data.photos.find(
      (photo) => photo._id === photoId
    );
    setEditedPhotoData(photoToEdit);
  };

  console.log(editedVideoData, "editedVideoData");
  // Function to handle user status change

  const handleUserStatus = (id) => {
    try {
      const callback = (err, res) => {
        if (err) {
          toast.error(err);
        } else {
          console.log(res);
          toast.success(res.message);
        }
      };
      dispatch(dispatch(UPDATEUSER_RELATIVE_STATUS({ data: id, callback })));
    } catch (error) {
      console.error(error, "<===err");
    }
  };

  const handleDeleteVideo = (videoId) => {
    setConfirModalVideo(true);
    setItemToDelete(videoId);
  };

  const handleDeletePhoto = (photoId) => {
    setConfirModalImage(true);
    setItemToDelete(photoId);
  };

  const confirmDeleteVideo = () => {
    dispatch(deleteVideo({ data: itemToDelete })).then(() => {
      // Remove the deleted video from the list
      const updatedVideos = relative.data.videos.filter(
        (video) => video._id !== itemToDelete
      );
      const updatedRelative = {
        ...relative,
        data: { ...relative.data, videos: updatedVideos },
      };
      dispatch(USER_RELATIVE_PROFILE({ data: userId, updatedRelative }));
      toast.success("Video Deleted Successfully");
      setConfirModalVideo(false);
    });
  };

  const confirmDeletePhoto = () => {
    dispatch(deletePhoto({ data: itemToDelete })).then(() => {
      // Remove the deleted photo from the list
      const updatedPhotos = relative.data.photos.filter(
        (photo) => photo._id !== itemToDelete
      );
      const updatedRelative = {
        ...relative,
        data: { ...relative.data, photos: updatedPhotos },
      };
      dispatch(USER_RELATIVE_PROFILE({ data: userId, updatedRelative }));
      toast.success("Image Deleted Successfully");
      setConfirModalImage(false);
    });
  };

  const confirmEditVideo = (updatedVideoData) => {
    console.log("------------------->");
    console.log(updatedVideoData, "updatedVideoData");
    dispatch(updateVideo({ data: updatedVideoData })).then(() => {
      // Update the video in the list
      const updatedVideos = relative.data.videos.map((video) =>
        video._id === updatedVideoData._id ? updatedVideoData : video
      );
      const updatedRelative = {
        ...relative,
        data: { ...relative.data, videos: updatedVideos },
      };
      dispatch(USER_RELATIVE_PROFILE({ data: userId, updatedRelative }));
      toast.success("Video Updated Successfully");
      setEditVideoModal(false);
    });
  };

  const confirmEditPhoto = (updatedPhotoData) => {
    console.log("==============>");
    dispatch(updateImage({ data: updatedPhotoData })).then(() => {
      // Update the photo in the list
      const updatedPhotos = relative.data.photos.map((photo) =>
        photo._id === updatedPhotoData._id ? updatedPhotoData : photo
      );
      const updatedRelative = {
        ...relative,
        data: { ...relative.data, photos: updatedPhotos },
      };
      dispatch(USER_RELATIVE_PROFILE({ data: userId, updatedRelative }));
      toast.success("Image Updated Successfully");
      setEditPhotoModal(false);
    });
  };

  const STATUS = {
    active: "Active",
    inactive: "Inactive",
    blocked: "Blocked",
    deleted: "Deleted",
    pending: "Pending",
    archive: "Archive",
  };

  const opts = {
    height: "120",
    width: "200",
    playerVars: {
      autoplay: 0, // 0 or 1 for autoplay
    },
  };




  console.log(qrData, "qrCodeID=========<?>");
  console.log(relative, "https://youtube.com/embed/hghg");
  return (
    <>
      {profileLoading && (
        <div className="main-loader">
          <FadeLoader size={1000} />
        </div>
      )}

      <ConfirmModal
        show={confirmModalVideo}
        handleClose={() => setConfirModalVideo(false)}
        title={"Delete Video"}
        content={"Are you sure you want to delete the video?"}
        handleConfirm={confirmDeleteVideo}
        loading={profileLoading}
      />
      <ConfirmModal
        show={confirmModalImage}
        handleClose={() => setConfirModalImage(false)}
        title={"Delete Image"}
        content={"Are you sure you want to delete the image?"}
        handleConfirm={confirmDeletePhoto}
        loading={profileLoading}
      />

      <EditVideoModal
        show={editVideoModal}
        handleClose={() => setEditVideoModal(false)}
        videoData={editedVideoData}
        handleEditVideo={confirmEditVideo}
      />

      <EditPhotoModal
        show={editPhotoModal}
        handleClose={() => setEditPhotoModal(false)}
        photoData={editedPhotoData}
        handleEditPhoto={confirmEditPhoto}
      />

      <section className="section">
        <div className="section-header">
        <BackButtom/>
          <h1 className="">Relative Profile</h1>
          <div className="section-header-breadcrumb">
            <div className="breadcrumb-item active">
              <Link to={"/admin/users/"+relative?.data?.userId}>UserDetail</Link>
            </div>
            <div className="breadcrumb-item">relative profile</div>
          </div>
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-3 px-2 align-items-center justify-content-center">
              <div className="d-flex flex-column align-items-center mb-2">
                <img
                  src={API_IMAGE_URL + relative?.data?.profileImage}
                  alt="profileImage"
                  className="img-fluid rounded-circle mb-2"
                  style={{ width: "100px", height: "100px" }}
                />
              </div>
              {/* <div className="position-relative">
                  <img
                    style={{
                      width: "100px",
                      height: "100px",
                      borderRadius: "10px",
                      backgroundColor: "#000",
                    }}
                    src={
                      API_IMAGE_URL + relative?.data?.linkedQrCodeDetails?.link
                    }
                    alt={`QR Code ${relative?.data?.qrCodeID}`}
                  />
                  
                </div> */}
            </div>

            <div className="col-md-9">
              <div className="">
                <div className="d-flex justify-content-between p-4 px-4  ">
                  <p className="d-flex align-content-center font-monospace  fw-bold ">
                    Profile details
                  </p>
                  {relative?.data?.linkedQrCodeDetails?.status === "linked" ? (
                    <button
                      className="btn btn-primary px-4"
                      onClick={handleUnLink}
                    >
                      {linking ? "Unlinking..." : "Remove Qr To Profile"}
                    </button>
                  ) : (
                    <div className="d-flex justify-content-end gap-2 ">
                      <Select
                      className=""
                        value={selectedOption}
                        onChange={handleChange}
                        options={selectOptions}
                        onInputChange={handleInputChange}
                        name="qrCodeId"
                        placeholder="Choose your Qr code"
                        isSearchable
                      />
                      <button
                        className="btn btn-primary px-3"
                        onClick={handleLink}
                        disabled={!selectedOption || linking}
                      >
                        {linking ? "Linking..." : "Link Qr To Profile"}
                      </button>
                    </div>
                  )}
                </div>
                <table className="table">
                  <tbody>
                    <tr>
                      <td className="border-1">
                        <strong>Name:</strong>
                      </td>
                      <td className="border-1"> {relative?.data?.fullName}</td>
                      <td className="border-1">
                        <strong>Age:</strong>
                      </td>
                      <td className="border-1">{formattedAge}</td>
                    </tr>
                    <tr>
                      <td className="border-1">
                        <strong>Birthday:</strong>
                      </td>
                      <td className="border-1">
                        {moment(relative?.data?.birthDate).format(
                          "MMMM DD, YYYY HH:mm A"
                        )}
                      </td>
                      <td className="border-1">
                        <strong>BirthPlace :</strong>
                      </td>
                      <td colSpan="" className="border-1">
                        {relative?.data?.birthPlace}
                      </td>
                    </tr>
                    <tr>
                      <td className="border-1">
                        <strong>GravePlace :</strong>
                      </td>
                      <td colSpan="" className="border-1">
                        {relative?.data?.gravePlace}
                      </td>
                      <td className="border-1">
                        <strong>GraveCemetery :</strong>
                      </td>
                      <td colSpan="" className="border-1">
                        {relative?.data?.graveCemetery}
                      </td>
                    </tr>
                    <tr>
                      <td className="border-1">
                        <strong>QR Code  ID :</strong>
                      </td>
                      <td colSpan="" className="border-1">
                        {relative?.data?.linkedQrCodeDetails?.qrCodeID}
                      </td>
                      <td className="border-1">
                        <strong>QrCode Link Status</strong>
                      </td>
                      <td colSpan="" className="border-1">
                        <Badge
                          className={`text-capitalize font-size-13 badge-soft-${relative?.data?.linkedQrCodeDetails?.status?.status}`}
                          color={
                            relative?.data?.linkedQrCodeDetails?.status
                              ?.badgeClass
                          }
                        >
                          {relative?.data?.linkedQrCodeDetails?.status}
                        </Badge>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <button
                    className={`nav-link ${
                      activeTab === "bio" ? "active" : ""
                    }`}
                    onClick={() => toggleTab("bio")}
                  >
                    Bio
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className={`nav-link ${
                      activeTab === "photo" ? "active" : ""
                    }`}
                    onClick={() => toggleTab("photo")}
                  >
                    Photo
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className={`nav-link ${
                      activeTab === "video" ? "active" : ""
                    }`}
                    onClick={() => toggleTab("video")}
                  >
                    Video
                  </button>
                </li>
                
              </ul>
              <div className="tab-content align-items-center">
                {activeTab === "bio" && (
                  <div className="tab-pane fade show active">
                    <p
                      dangerouslySetInnerHTML={{ __html: relative?.data?.bio }}
                    ></p>
                  </div>
                )}
                 {activeTab === "photo" && (
                  <div className="tab-pane fade show active">
                    <div style={{ maxHeight: "300px", overflowY: "auto" }}>
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Images</th>
                            <th>Title</th>
                            <th>Created Date</th>
                            <th>Delete</th>
                          </tr>
                        </thead>
                        <tbody>
                          {relative?.data?.photos?.map((photo, index) => (
                            <tr key={index}>
                              <td>
                                <img
                                  src={API_IMAGE_URL + photo.link}
                                  className="img-fluid"
                                  alt={photo.title}
                                  style={{ width: "70px", height: "60px" }}
                                />
                              </td>
                              <td>{photo.title}</td>
                              <td>
                                {new Date(photo.createdAt).toLocaleDateString()}
                              </td>
                              <td>
                                <button
                                  className=""
                                  style={{
                                    fontSize: "20px",
                                    color: "red",
                                    backgroundColor: "#fff",
                                    border: "none",
                                  }}
                                  onClick={() => handleDeletePhoto(photo._id)}
                                >
                                  <i className="fas fa-trash-alt"></i>
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
                {activeTab === "video" && (
                  <div className="tab-pane fade show active">
                    <div style={{ maxHeight: "300px", overflowY: "auto" }}>
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Video</th>
                            <th>Title</th>
                            <th>Created Date</th>
                            <th>Delete</th>
                          </tr>
                        </thead>
                        <tbody>
                          {relative?.data?.videos.map((video, index) => {
                            // Extract video ID from the YouTube link
                            let videoId = video.link.split("v=")[1]; 
                            const ampersandPosition = videoId.indexOf("&");
                            if (ampersandPosition !== -1) {
                              videoId = videoId.substring(0, ampersandPosition);
                            }

                            return (
                              <tr key={index}>
                                <td>
                                  <div
                                    className="video-container"
                                    style={{ width: "150px", height: "120px" }}
                                  >
                                    {/* Render YouTube component with extracted videoId */}
                                    <YouTube
                                      videoId={videoId}
                                      opts={opts}
                                      allowFullScreen={true}
                                    />
                                  </div>
                                </td>
                                <td>{video.title}</td>
                                <td>
                                  {new Date(
                                    video.createdAt
                                  ).toLocaleDateString()}
                                </td>
                                <td>
                                  <button
                                    className=""
                                    style={{
                                      fontSize: "20px",
                                      color: "red",
                                      backgroundColor: "#fff",
                                      border: "none",
                                    }}
                                    onClick={() => handleDeleteVideo(video._id)}
                                  >
                                    <i className="fas fa-trash-alt"></i>
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
               
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UserRelativeProfile;
