import { createAsyncThunk } from "@reduxjs/toolkit";

import * as URL from "helpers/url_helper";
import { get, put } from "helpers/api_helper";

export const getSettings = createAsyncThunk(
  "getSettings",
  async (payload, Thunk) => {
    try {
      let response = await get(URL.GET_SETTINGS);

      return response.data;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);

export const updateSettings = createAsyncThunk(
  "updateSetting",
  async ({ data, callback }, Thunk) => {
    try {
      let payload = JSON.parse(JSON.stringify(data));
      delete payload._id;
      let response = await put(URL.UPDATE_SETTINGS+data?._id, payload);
      callback && callback(null, response.data);
     clg
      return response.data;
    } catch (error) {
      callback && callback(error);
      return Thunk.rejectWithValue(error);
    }
  }
);