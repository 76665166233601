import { createSlice, current } from "@reduxjs/toolkit";
import * as Act from "./actions";

const initialState = {
    loading: false,
    avatars: [],
    avatarCategories: [],
    avatarCharacterstics: [],
    totalAvatars: 0,
    totalAvatarCatgories: 0,
    totalAvatarCharacterstics: 0,
};

const slice = createSlice({
    name: "Avatar",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        //get list
        builder.addCase(Act.getAvatarList.pending, (state, action) => ({
            ...state,
            loading: true,
        }));
        builder.addCase(Act.getAvatarList.fulfilled, (state, { payload }) => ({
            ...state,
            loading: false,
            avatars: payload.data,
            totalAvatars: payload.totalCount,
        }));
        builder.addCase(Act.getAvatarList.rejected, (state, action) => ({
            ...state,
            loading: false,
            avatars: [],
            totalAvatars: 0,
        }));

        //add avatar 
        builder.addCase(Act.AddAvatar.pending, (start, action) => ({
            ...start,
            loading: true,
        }));
        builder.addCase(Act.AddAvatar.fulfilled, (state, { payload }) => (
            console.log(payload),
            {
                ...state,
                loading: false,
            }));
        builder.addCase(Act.AddAvatar.rejected, (state, action) => ({
            ...state,
            loading: false,
        }));

        //edit avatar 
        builder.addCase(Act.editAvatar.pending, (start, action) => ({
            ...start,
            loading: true,
        }));
        builder.addCase(Act.editAvatar.fulfilled, (state, { payload }) => (
            console.log(payload),
            {
                ...state,
                loading: false,
            }));
        builder.addCase(Act.editAvatar.rejected, (state, action) => ({
            ...state,
            loading: false,
        }));

        //delete avatar 
        builder.addCase(Act.deleteAvatar.pending, (start, action) => ({
            ...start,
            loading: true,
        }));
        builder.addCase(Act.deleteAvatar.fulfilled, (state, { payload }) => (
            console.log(payload),
            {
                ...state,
                loading: false,
            }));
        builder.addCase(Act.deleteAvatar.rejected, (state, action) => ({
            ...state,
            loading: false,
        }));

        //get category list
        builder.addCase(Act.getAvatarCategoryList.pending, (state, action) => ({
            ...state,
            loading: true,
        }));
        builder.addCase(Act.getAvatarCategoryList.fulfilled, (state, { payload }) => ({
            ...state,
            loading: false,
            avatarCategories: payload.data,
            totalAvatarCategories: payload.totalCount,
        }));
        builder.addCase(Act.getAvatarCategoryList.rejected, (state, action) => ({
            ...state,
            loading: false,
            avatarCategories: [],
            totalAvatarCategories: 0,
        }));

        //add avatar category
        builder.addCase(Act.AddAvatarCategory.pending, (start, action) => ({
            ...start,
            loading: true,
        }));
        builder.addCase(Act.AddAvatarCategory.fulfilled, (state, { payload }) => (
            console.log(payload),
            {
                ...state,
                loading: false,
            }));
        builder.addCase(Act.AddAvatarCategory.rejected, (state, action) => ({
            ...state,
            loading: false,
        }));

        //edit avatar category
        builder.addCase(Act.editAvatarCategory.pending, (start, action) => ({
            ...start,
            loading: true,
        }));
        builder.addCase(Act.editAvatarCategory.fulfilled, (state, { payload }) => (
            console.log(payload),
            {
                ...state,
                loading: false,
            }));
        builder.addCase(Act.editAvatarCategory.rejected, (state, action) => ({
            ...state,
            loading: false,
        }));

        //delete avatar category
        builder.addCase(Act.deleteAvatarCategory.pending, (start, action) => ({
            ...start,
            loading: true,
        }));
        builder.addCase(Act.deleteAvatarCategory.fulfilled, (state, { payload }) => (
            console.log(payload),
            {
                ...state,
                loading: false,
            }));
        builder.addCase(Act.deleteAvatarCategory.rejected, (state, action) => ({
            ...state,
            loading: false,
        }));

        //get characterstic list
        builder.addCase(Act.getAvatarCharactersticList.pending, (state, action) => ({
            ...state,
            loading: true,
        }));
        builder.addCase(Act.getAvatarCharactersticList.fulfilled, (state, { payload }) => ({
            ...state,
            loading: false,
            avatarCharacterstics: payload.data,
            totalAvatarCharacterstics: payload.totalCount,
        }));
        builder.addCase(Act.getAvatarCharactersticList.rejected, (state, action) => ({
            ...state,
            loading: false,
            avatarCharacterstics: [],
            totalAvatarCharacterstics: 0,
        }));

        //add avatar characterstic
        builder.addCase(Act.AddAvatarCharacterstic.pending, (start, action) => ({
            ...start,
            loading: true,
        }));
        builder.addCase(Act.AddAvatarCharacterstic.fulfilled, (state, { payload }) => (
            console.log(payload),
            {
                ...state,
                loading: false,
            }));
        builder.addCase(Act.AddAvatarCharacterstic.rejected, (state, action) => ({
            ...state,
            loading: false,
        }));

        //edit avatar characterstic
        builder.addCase(Act.editAvatarCharacterstic.pending, (start, action) => ({
            ...start,
            loading: true,
        }));
        builder.addCase(Act.editAvatarCharacterstic.fulfilled, (state, { payload }) => (
            console.log(payload),
            {
                ...state,
                loading: false,
            }));
        builder.addCase(Act.editAvatarCharacterstic.rejected, (state, action) => ({
            ...state,
            loading: false,
        }));

        //delete avatar characterstic
        builder.addCase(Act.deleteAvatarCharacterstic.pending, (start, action) => ({
            ...start,
            loading: true,
        }));
        builder.addCase(Act.deleteAvatarCharacterstic.fulfilled, (state, { payload }) => (
            console.log(payload),
            {
                ...state,
                loading: false,
            }));
        builder.addCase(Act.deleteAvatarCharacterstic.rejected, (state, action) => ({
            ...state,
            loading: false,
        }));
    },
});

export default slice;
