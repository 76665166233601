import React from 'react';
import Form from 'react-bootstrap/Form';

const ToggleSwitch = ({ checked, onChange, label, inactiveLabel, activeLabel }) => {
  return (
    <Form.Group controlId="paymentModeSwitch">
      <Form.Label>{label}</Form.Label>
      <Form.Check
        type="switch"
        id="custom-switch"
        label={checked ? activeLabel : inactiveLabel}
        checked={checked}
        onChange={onChange}
        custom
      />
    </Form.Group>
  );
};

export default ToggleSwitch;
