import * as Yup from 'yup';

const Validation = Yup.object({
    name: Yup.string().required("Please enter name!"),
    category: Yup.string().required("Please select category!"),
    count: Yup.number().required("Please enter count"),
    imageUrl: Yup.string().required("Please select logo!"),
});

export default Validation;
