import React from "react";

import Badge from "react-bootstrap/Badge";

import { DATE_FORMAT } from "helpers/common";


const STATUS = {
  linked: "linked",
  pending: "InStorage",
};

const columns = ({ deleteHandler }) => [
  {
    dataField: "name",
    text: "Qr Code Id",
    formatter: (_, row) => (
      <span className="text-capitalize">{row.qrCodeDetails.qrCodeID}</span>
    ),
  },
  // {
  //   dataField: "name",
  //   text: "Link",
  //   formatter: (_, row) => (
  //     <span className="text-capitalize">{row.qrCodeDetails.link}</span>
  //   ),
  // },

  {
    dataField: "status",
    text: "Status",
    formatter: (cellContent, row) => (
      <Badge
        className={`text-capitalize font-size-13 badge-soft-${
          row.status === "pending" ? "warning" : "success"
        }`}
      >
        {STATUS[row.qrCodeDetails.status]}
      </Badge>
    ),
  },

  {
    dataField: "createdAt",
    text: "Created At",
    formatter: (_, row) => DATE_FORMAT(row.createdAt),
  },
  {
    text: "Action",
    dataField: "",
    formatter: (_, row) => (
      <>
        {
          <button
            className="fa fa-trash-alt"
            style={{
              fontSize: "20px",
              color: "red",
              backgroundColor: "#fff",
              border: "none",
            }}
            title="delete"
            onClick={() => deleteHandler(row._id)}
          ></button>
        }
      </>
    ),
  },
];

export default columns;
