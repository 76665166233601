const inputFields = [
  {
    name: "level",
    type: "number",
    label: "Level*",
    placeholder: "Level...",
  },
  // {
  //   name: "type",
  //   type: "select",
  //   label: "Type",
  //   placeholder: "",
  //   options: [
  //     { value: "add", label: "Addition" },
  //     { value: "sub", label: "Subtraction" },
  //     { value: null, label: "Select Type" },
  //   ],
  // },
  {
    name: "numbers",
    type: "text",
    label: "Numbers",
    placeholder: "Numbers...",
  },
  {
    name: "frequencies",
    type: "textarea",
    label: "Frequencies",
    placeholder: "Frequencies...",
  },
  {
    name: "isReviewLevel",
    type: "text",
    label: "Review Level*",
    placeholder: "Review Level...",
  },
  {
    name: "reviewData",
    type: "textarea",
    label: "Review Data",
    placeholder: "Review Data...",
  },
  {
    name: "passCount",
    type: "text",
    label: "Win Count to next Level",
    placeholder: "Count...",
  },
  {
    name: "timeInSec",
    type: "text",
    label: "Time to answer (sec)",
    placeholder: "Time(sec)...",
  },
  {
    name: "starsAwarded",
    type: "text",
    label: "Stars awarded on completion",
    placeholder: "Stars awarded...",
  },
];

export default inputFields;
