import { createAsyncThunk } from "@reduxjs/toolkit";

import * as URL from "helpers/url_helper";

import { get, post, put, del } from "helpers/api_helper";

export const QRLINKEDHISTORY = createAsyncThunk(
  "QRLINKEDHISTORY",
  async ({ data, callback }, Thunk) => {
    try {
      let response = await post(URL.QR_HISTORY, data);
      callback && callback(null, response);
      return response;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);


