import { createAsyncThunk } from "@reduxjs/toolkit";

import * as URL from "helpers/url_helper";
import { get, post, put, del } from "helpers/api_helper";

export const getPageList = createAsyncThunk(
  "getPageList",
  async ({ data, callback }, Thunk) => {
    try {
      let response = await post(
        URL.GET_PAGE_LIST,
        data
      );
      callback && callback(null, response.data);
      return response;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);

export const AddPage = createAsyncThunk(
  "AddPage",
  async ({ data, callback }, Thunk) => {
    try {
      let response = await post(URL.ADD_PAGE, data);
      callback && callback(null, response);
      return response;
    } catch (error) {
      callback && callback(error.message, null);
      return Thunk.rejectWithValue(error);
    }
  }
);

export const editPage = createAsyncThunk(
  "editPage",
  async ({ data, callback }, Thunk) => {
    try {
      let response = await put(URL.EDIT_PAGE + data?._id, data);
      callback && callback(null, response);
      return response;
    } catch (error) {
      callback && callback(error.message, null);
      return Thunk.rejectWithValue(error);
    }
  }
);

export const deletePage = createAsyncThunk(
  "deletePage",
  async ({ data, callback }, Thunk) => {
    try {
      let response = await del(URL.DELETE_PAGE + data?._id, data);
      callback && callback(null, response);
      return response;
    } catch (error) {
      callback && callback(error.message, null);
      return Thunk.rejectWithValue(error);
    }
  }
);
