import axios from "axios"
import { toast } from "react-toastify"

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

const API_URL = API_BASE_URL + process.env.REACT_APP_API_BASE_URL_EXTENSION

export const API_VERSION = process.env.REACT_APP_API_VERSION

const axiosApi = axios.create({ baseURL: API_URL + API_VERSION })

export const updateToken = token => { axiosApi.defaults.headers.common["Authorization"] =  "Bearer " + token }

let obj = null
if (typeof window !== "undefined") {
  obj = localStorage.getItem("authToken")
}
console.log("obj", obj)
updateToken(obj ? obj : null)

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

const checkStatusCode = (code, err) => {
  if (code && [403, 501, 502, 503].includes(code)) {

    if (axiosApi.defaults.headers.common["token"] !== undefined) {
      LogoutUser()
      throw new Error(err.response.data.message)
    } else {
      throw new Error(err.response.data.message)
    }
  } else {
    throw new Error(err.message)
  }
}

export async function get(url, config = {}) {
  console.log("url", url, axiosApi?.baseURL);
  return await axiosApi
    .get(url, { ...config })
    .then(response => {
      if (response.data.status === "success") {
        return response.data
      } else {
        throw new Error(response.data.message)
      }
    })
    .catch(e => {
      checkStatusCode(!e.response ? null : e.response.status, e)
    })
}


export async function post(url, data, config = {}) {
  return await axiosApi
    .post(url, data, { ...config })
    .then(response => {
      if (response.data.status === "success") {
        return response.data
      } else {
        throw new Error(response.data.message)
      }
    })
    .catch(e => {
      checkStatusCode(!e.response ? null : e.response.status, e)
    })
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => {
      if (response.data.status === "success") {
        return response.data
      } else {
        throw new Error(response.data.message)
      }
    })
    .catch(e => {
      checkStatusCode(!e.response ? null : e.response.status, e)
    })
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => {
      if (response.data.status === "success") {
        return response.data
      } else {
        throw new Error(response.data.message)
      }
    })
    .catch(e => {
      checkStatusCode(!e.response ? null : e.response.status, e)
    })
}