import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FadeLoader from "react-spinners/FadeLoader";
import { PAYMENTS, PAYMENTSDETAILS } from "common/viewContent";
import Helmet from "react-helmet";
import * as Path from "routes/Path/index";
import { PaymentsDetails, PaymentStatus } from "store/payments/actions"; 
import Select from "react-select";
import {
  Container,
  Row,
  Col,
  Table,
  Badge,
  Button, 
  Form,
} from "react-bootstrap";
import { toast } from "react-toastify";
import BackButtom from "components/BackButtom";

const OrdertDetailsPage = () => {
  const userId = useParams();
  const dispatch = useDispatch();
  const { payment, loading, totalcount } = useSelector(
    (state) => state?.payment
  );
  const [shippingStatus, setshippingStatus] = useState("");

  useEffect(() => {
    dispatch(PaymentsDetails({ data: userId?.id }));
  }, [userId]);

  const handleStatusChange = async () => {
    try {
      let data = { id: payment?.data?._id, shippingStatus };

      console.log(data, "<====data");
      dispatch(PaymentStatus({ data: data, callback: handlePaymentCallback }));
      // toast.success("Status Change Successfull");
    } catch (error) {
      console.error("Error updating payment status:", error);
    }
  };
  const handlePaymentCallback = (error, res) => {
    if (error) {
      toast.error(error);
    } else {
      toast.success("shipping update successfull");
      dispatch(PaymentsDetails({ data: userId?.id }));
    }
  };

  console.log(payment, "payments======");
  const validTransitions = ["processing", "shipped", "delivered"];

  return (
    <>
      {loading && (
        <div className="main-loader">
          <FadeLoader size={1000} />
        </div>
      )}

      <Helmet title={PAYMENTS?.CURRENT_MODULE} />
      <section className="section">
        <div className="section-header">
        <BackButtom/>
          <h1>{PAYMENTSDETAILS?.CURRENT_MODULE}</h1>
          <div className="section-header-breadcrumb">
            <div className="breadcrumb-item active">
              <Link to={"/admin/" + Path.Paymentlist}>PaymentList</Link>
            </div>
            <div className="breadcrumb-item">
              {PAYMENTSDETAILS?.CURRENT_MODULE}{" "}
            </div>
          </div>
        </div>
        <div className="section-body">
          <div className="card">
            <div className="row justify-content-between">
              <div className="col-6">
                <div className="card-header">
                  <h2 className="section-title">
                    {PAYMENTSDETAILS?.TOP_CONTENT}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Container>
        <Row>
          <Col>
            <Table striped bordered hover>
              <tbody>
                <tr>
                  <td>User Details</td>
                  <td>Shipping Details</td>
                  <td>Payment Details</td>
                </tr>
                <tr>
                  <td>
                    <p>Name: {payment?.data?.userDetails?.name}</p>
                    <p>Email: {payment?.data?.userDetails?.email}</p>
                    <p>
                      Mobile Number: {payment?.data?.userDetails?.countryCode}
                      {payment?.data?.userDetails?.mobileNumber}
                    </p>
                  </td>
                  <td>
                    <p>Name: {payment?.data?.shippingDetails?.name}</p>
                    <p>
                      Surname :{"  "}
                      {payment?.data?.shippingDetails?.surname}
                    </p>
                    <p>
                      Address: {payment?.data?.shippingDetails?.address},{" "}
                      {payment?.data?.shippingDetails?.city}
                    </p>
                    <div className="d-flex gap-1">
                      
                      <p>Mobile Number : +{payment?.data?.shippingDetails?.countryCode}</p>
                    <p>  {payment?.data?.shippingDetails?.mobileNumber}</p>
                    </div>
                    <p>
                      ShippingStatus :{" "}
                      <span
                        style={{
                          backgroundColor: "#7b6ee1",
                          color: "white",
                          padding: "3px 6px",
                          borderRadius: "4px",
                        }}
                      >
                        {payment?.data?.shippingStatus &&
                          payment?.data?.shippingStatus.toUpperCase()}
                      </span>
                    </p>
                    <div className="">
                      <p className="fw-bolder">Select New Status</p>
                      <Select
                        className=""
                        options={validTransitions.map((status) => ({
                          value: status,
                          label: status,
                        }))}
                        onChange={(selectedOption) =>
                          setshippingStatus(selectedOption.value)
                        }
                      />
                      <Button className="mt-2" onClick={handleStatusChange}>
                        Update
                      </Button>
                    </div>
                  </td>
                  <td>
                    <p>Purchase ID: {payment?.data?.purchaseID}</p>
                    <p>
                      Price: {payment?.data?.currencySymbol}{" "}
                      {payment?.data?.pricePerUnit}
                    </p>
                    <p>
                      Amount: {payment?.data?.currencySymbol}{" "}
                      {payment?.data?.amount}
                    </p>
                    <p>Status: {payment?.data?.status}</p>
                    <p>PaymentLink: {payment?.data?.paymentLink}</p>
                  </td>
                </tr>
                <tr>
                  <td colSpan="3">
                    <p>
                      Total Amount: {payment?.data?.currencySymbol}{" "}
                      {payment?.data?.amount}
                    </p>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default OrdertDetailsPage;
