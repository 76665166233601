
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { USER_RELATIVE, LINK_QR_TO_USER, getUsers } from "store/actions";
import { toast } from "react-toastify";


export default function QrLinkProfile({ show, handleClose, title, id }) {
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedUserProfile, setSelectedUserProfile] = useState(null);
  const { users, loading, totalCount } = useSelector((state) => state.Users);
  const [usersProfile, setUsersProfile] = useState([]);
  const [linking, setLinking] = useState(false); // Track linking state

  const dispatch = useDispatch();
  const [filter, setFilter] = useState({
    limit: 20,
    order: -1,
    pageNo: 1,
    orderBy: "dateCreatedUtc",
    search: "",
  });

  const [profileFilter, setProfileFilter] = useState({
    filter: "unlinked",
    limit: 20,
    order: -1,
    pageNo: 1,
    orderBy: "dateCreatedUtc",
    search: "",
  });

  useEffect(() => {
    dispatch(getUsers({ data: filter }));
  }, [filter]);

  useEffect(() => {
    if (selectedUser) {
      setProfileFilter({ ...profileFilter, userId: selectedUser.value });
    }
  }, [selectedUser]);

  useEffect(() => {
    if (profileFilter.userId) {
      dispatch(
        USER_RELATIVE({ data: profileFilter, callback: handleUserProfiles })
      );
    }
  }, [profileFilter]);

  const handleUserProfiles = (err, res) => {
    if (err) {
      toast.error(err);
    } else {
      setUsersProfile(res);
    }
  };

  const handleLink = () => {
    if (selectedUserProfile && !linking) {
      setLinking(true);
      const userId = selectedUserProfile.value;
      dispatch(
        LINK_QR_TO_USER({
          qrCodeID: id,
          userProfileId: userId,
          callback: handleLinkCallback,
        })
      );
    } else if (!selectedUserProfile) {
      toast.error("Please select a user profile");
    }
  };

  const handleLinkCallback = (err, res) => {
    setLinking(false);
    if (err) {
      toast.error(err);
    } else {
      toast.success("Qr linked to profile successfully");
      handleClose();
    }
  };

  console.log(id, "Qrids");
  console.log(users, "users");

  return (
    <div className="d-flex justify-content-center ">
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
        dialogClassName="custom-modal"
      >
        <Modal.Header className="py-3 text-dark" closeButton>
          <Modal.Title className="fw-bold text-dark">{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="row ">
            <div className="col-6">
              <p className="  fw-bolder ">Select User</p>
              <Select
                options={
                  Array.isArray(users)
                    ? users.map((user) => ({
                        value: user._id,
                        label: user?.username,
                      }))
                    : []
                }
                onChange={(selectedOption) => setSelectedUser(selectedOption)}
              />
            </div>
            <div className="col-6">
              <p className="fw-bolder ">Select Users Profiles</p>
              <Select
                options={
                  Array.isArray(usersProfile)
                    ? usersProfile.map((userProfile) => ({
                        value: userProfile._id,
                        label: userProfile.name,
                      }))
                    : []
                }
                onChange={(selectedOption) =>
                  setSelectedUserProfile(selectedOption)
                }
              />
            </div>
          </div>
          <div className="d-flex justify-content-start align-content-center p-4 ">
            <button
              className="btn btn-primary"
              onClick={handleLink}
              disabled={!selectedUserProfile || linking} // Disable button when not selecting a user profile or already linking
            >
              {linking ? "Linking..." : "Link Qr"}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
