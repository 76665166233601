import { createSlice, current } from "@reduxjs/toolkit";
import * as Act from "./actions";

const initialState = {
    loading: false,
    levels: [],
    totalCount: 0,
};

const slice = createSlice({
    name: "levels",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(Act.getLevels.pending, (state, action) => ({
            ...state,
            loading: true,
        }));
        builder.addCase(Act.getLevels.fulfilled, (state, { payload }) => ({
            ...state,
            loading: false,
            levels: payload.data,
            totalCount: payload.totalCount,
        }));
        builder.addCase(Act.getLevels.rejected, (state, action) => ({
            ...state,
            loading: false,
            levels: [],
            totalCount: 0,
        }));

        //add level 
        builder.addCase(Act.addLevel.pending, (start, action) => ({
            ...start,
            loading: true,
        }));
        builder.addCase(Act.addLevel.fulfilled, (state, { payload }) => (
            console.log(payload),
            {
                ...state,
                loading: false,
            }));
        builder.addCase(Act.addLevel.rejected, (state, action) => ({
            ...state,
            loading: false,
        }));

        //update level
        builder.addCase(Act.updateLevel.pending, (state, action) => ({
            ...state,
            loading: true,
        }));
        builder.addCase(Act.updateLevel.fulfilled, (state, { payload }) => ({
            ...state,
            loading: false,
        }));
        builder.addCase(Act.updateLevel.rejected, (state, action) => ({
            ...state,
            loading: false,
        }));

    },
});

export default slice;