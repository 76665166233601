const inputFields = [
  {
    name: "name",
    type: "text",
    label: "Name*",
    placeholder: "Name...",
  },
  {
    name: "category",
    type: "select",
    label: "Category*",
    placeholder: "Select Category...",
    options: [
      { value: "level", label: "Level" },
      { value: "operation", label: "Operation" },
      { value: "practiceDays", label: "Day's Practice" },
      { value: "practiceMins", label: "Min's Practice" },
    ]
  },
  {
    name: "count",
    type: "text",
    label: "Count*",
    placeholder: "Count...",
  },
  {
    name: "levelType",
    type: "select",
    label: "Level Type",
    placeholder: "Select Type...",
    options: [
      { value: "add", label: "Addition" },
      { value: "sub", label: "Subtraction" },
    ]
  },
  {
    name: "imageUrl",
    type: "image",
    label: "Logo*",
    placeholder: "Select Logo...",
  },
];

export default inputFields;
