import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";

const EditVideoModal = ({ show, handleClose, videoData, handleEditVideo }) => {
  const [editedVideo, setEditedVideo] = useState({ ...videoData });

  useEffect(() => {
    setEditedVideo({ ...videoData });
  }, [videoData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedVideo({ ...editedVideo, [name]: value });
  };

  const handleSubmit = () => {
    handleEditVideo(editedVideo);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Video</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Title</Form.Label>
          <Form.Control
            type="text"
            name="title"
            value={editedVideo.title}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Link</Form.Label>
          <Form.Control
            type="text"
            name="link"
            value={editedVideo.link}
            onChange={handleChange}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditVideoModal;
