import React, { useState, useEffect, useCallback } from "react";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import FadeLoader from "react-spinners/FadeLoader";

//imported
import { CONTENT_PAGES } from "common/viewContent";
import * as Path from "routes/Path/index";

import { toast } from "react-toastify";
import Table from "components/Table";
import Columns, { selectRow } from "./Columns";
import AddModal from "components/Modals/AddPage";
import inputFields from "./inputFields";
import Validation from "./Validation";
import { AddPage, editPage, getPageList, deletePage } from "store/pages/actions";
import { getLanguageList } from "store/language/actions";
import FormikForm from "components/shared/FormikForm";

const List = () => {
  const dispatch = useDispatch();

  const { pagesList, loading, totalCount, langList } = useSelector((state) => ({
    pagesList: state.Page?.pagesList,
    loading: state?.Page?.loading,
    totalCount: state?.Page?.totalcount,
    langList: state?.Language?.langList,
  }));

  const [columns, setcolumns] = useState([{ dataField: "", text: "" }]);

  const [formData, setFormData] = useState({
    languageId: "",
    name: "",
    content: "",
  });
  const [addModalShow, setAddModalShow] = useState(false);

  const [filter, setFilter] = useState({
    pageNo: 1,
    limit: 20,
    order: -1,
    orderBy: "createdAt",
    search: "",
  });

  const [languageFilter, setLanguageFilter] = useState({
    pageNo: 1,
    limit: 200,
    order: -1,
    orderBy: "createdAt"
  });

  useEffect(() => {
    dispatch(getLanguageList({ data: languageFilter }));
  }, []);

  useEffect(() => {
    if (!langList) return;

    const lang = langList.map((it) => ({
      value: it._id,
      label: it.languageName,
    }));

    inputFields.find((it) => it.name === "languageId").options = lang;

  }, [langList]);

  useEffect(() => {
    getApiData(filter);
    setcolumns(Columns({ deleteHandler, handleEdit }));
  }, [filter]);

  async function getApiData(query) {
    try {
      dispatch(getPageList({ data: query }));
    } 
    catch (error) { }
  }

  const handleStatus = (id, status) => {
    try {
      const callback = (err, res) => {
        if (err) {
          toast.error(err);
        } else {
          getApiData(filter);
          toast.success(res.message);
        }
      };

      dispatch(
        editPage({
          data: { _id: id, status: status },
          callback,
        })
      );
    } 
    catch (error) {
      console.log(error, "<===err");
    }
  };

  const handleEdit = (info) => {
    console.log(info);
    setFormData((prev) => {
      return {
        ...prev,
        _id: info._id,
        languageId: info.languageId,
        name: info.name,
        content: info.content
      };
    });
    setAddModalShow(true);
  };

  const handleSubmit_ = (info) => {
    try {
      const callback = (err, res) => {
        if (err) {
          toast.error(err);
        } else {
          console.log(res);
          setAddModalShow(false);
          getApiData(filter);
          toast.success(res.message);
          setFormData({
            languageId: "",
            name: "",
            content: ""
          });
        }
      };
      if (info.hasOwnProperty("_id")) {
        dispatch(
          editPage({
            data: info,
            callback,
          })
        );
      } else {
        dispatch(
          AddPage({
            data: info,
            callback,
          })
        );
      }
    } catch (error) {
      console.log(error, "<===err");
    }
  };

  const deleteHandler = (id) => {
    try {
      const userConfirmed = window.confirm("Are you sure, you want to delete this content page?");
      if (userConfirmed) {
        const callback = (err, res) => {
          if (err) {
            toast.error(err);
          } else {
            getApiData(filter);
            toast.success(res.message);
          }
        };

        dispatch(
          deletePage({
            data: { _id: id },
            callback,
          })
        );
      }
    } catch (error) {
      console.log(error, "<===err");
    }
  };

  return (
    <>
      <AddModal
        show={addModalShow}
        handleClose={() => {
          setAddModalShow(false);
          setFormData({
            languageId: "",
            name: "",
            content: ""
          });
        }}
        title={`${formData.hasOwnProperty("_id") ? "Edit" : "Add"
          } content page`}
        content={""}
        handleConfirm={handleSubmit_}
        loading={loading}
        initialValues={formData}
        inputFields={inputFields}
        Validation={Validation}
        size="lg"
      />
      {loading && (
        <div className="main-loader">
          <FadeLoader size={1000} />
        </div>
      )}
      <Helmet title={CONTENT_PAGES.CURRENT_MODULE} />

      <section className="section">
        <div className="section-header">
          <h1>{CONTENT_PAGES.CURRENT_MODULE}</h1>

          <div className="section-header-breadcrumb">
            <div className="breadcrumb-item active">
              <Link to={"/admin/" + Path.dashboard}>Dashboard</Link>
            </div>
            <div className="breadcrumb-item">
              {CONTENT_PAGES.CURRENT_MODULE}{" "}
            </div>
          </div>
        </div>
        <div className="section-body">
          <div className="card">
            <div className="row justify-content-between">
              <div className="col-6">
                <div className="card-header">
                  <h2 className="section-title">{CONTENT_PAGES.TOP_CONTENT}</h2>
                </div>
              </div>
              <div className="col-6">
                <div className="card-header d-flex justify-content-end align-items-center">
                  <button
                    className="btn btn-primary"
                    onClick={() => setAddModalShow(true)}
                  >
                    Add
                  </button>
                </div>
              </div>
              {filter.language_id && (
                <div className="container">
                  <div className="col-6">
                    <FormikForm
                      initialValues={{ select: filter.language_id }}
                      // validationSchema={Validation}
                      onSubmit={handleFilterForm}
                      loading={loading}
                      inputFields={InputFieldsForSearch}
                      // submitting={null}
                      buttonText={"Search"}
                      // is_block={true}
                      col={6}
                    // is_passwordHide={false}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          {!loading && pagesList?.length > 0 && (
            <Table
              data={pagesList}
              columns={columns}
              total={totalCount}
              // setFilter={setFilter}
              // filter={filter}
              toggleConfirmModal={handleStatus}
            />
          )}
          {(!loading && pagesList?.length === 0) ||
            (pagesList.length === 0 && (
              <p style={{ textAlign: "center" }}>No Page Found</p>
            ))}
        </div>
      </section>
    </>
  );
};

export default List;
