// import React, { useEffect, useState } from "react";
// import { useSelector, useDispatch } from "react-redux";
// import { Link, useParams, useNavigate } from "react-router-dom";
// import { USER_PROFILE } from "store/user/actions";
// import {
//   UPDATEUSER_RELATIVE_STATUS,
//   USER_RELATIVE,
//   USER_RELATIVE_PROFILE,
//   updateRelativeProfile,
// } from "store/Relative/actions";
// import { USER } from "common/viewContent";
// import * as Path from "routes/Path/index";
// import { toast } from "react-toastify";
// import Badge from "react-bootstrap/Badge";
// import moment from "moment";
// import FadeLoader from "react-spinners/FadeLoader";

// import {
//   Button,
//   Card,
//   Col,
//   Container,
//   Form,
//   Modal,
//   Row,
// } from "react-bootstrap";
// import { Helmet } from "react-helmet";
// import { calculateAge, formatYearsAndMonths } from "helpers/common";
// import FormikForm from "components/shared/FormikForm";

// const InputFieldsForSearch = [
//   {
//     name: "search",
//     type: "text",
//     label: "",
//     placeholder: "Search using first name, last name, email...",
//   },
//   {
//     name: "select",
//     type: "select",
//     label: "",
//     placeholder: "Select status",
//     options: [
//       { value: "active", label: "Active" },
//       { value: "inactive", label: "Inactive" },
//       { value: "pending", label: "Pending" },
//       { value: "deleted", label: "Deleted" },
//     ],
//   },
// ];
// const initialValuesForFilter = {
//   search: "",
//   select: "",
// };

// const UserDetails = () => {
//   const { id: userId } = useParams();
//   const dispatch = useDispatch();
//   const navigate = useNavigate();

//   const [show, setShow] = useState(false);
//   const [formData, setFormData] = useState({
//     name: "",
//     relation: "",
//     birthDate: "",
//     surname: "",
//   });
//   const [editUserRelative, setEditUserRelative] = useState(null); // Initialize as null

//   const handleClose = () => setShow(false);

//   const { loading: profileLoading, user } = useSelector((state) => state.Users);
//   const { loading, relative } = useSelector((state) => state.Relative);
//   const [relative1, setRelative1] = useState();
//   const [filter, setFilter] = useState({
//     userId: userId,
//     status: "",
//     pageNo: 1,
//     limit: 20,
//     order: -1,
//     orderBy: "dateCreatedUtc",
//   });
//   const [formattedDate, setFormattedDate] = useState("");
//   useEffect(() => {
//     dispatch(USER_PROFILE({ data: userId }));
//   }, [userId]);

//   useEffect(() => {
//     if (userId) {
//       dispatch(USER_RELATIVE({ data: { ...filter, userId }, callback }));
//     }
//   }, [userId, filter]);

//   useEffect(() => {
//     if (user && user.data && user.data.dateCreated) {
//       setFormattedDate(
//         moment(user.data.dateCreated).format("MMMM DD, YYYY HH:mm A")
//       );
//     }
//   }, [user]);

//   const callback = (err, res) => {
//     if (err) {
//       toast.error(err);
//     } else {
//       setRelative1(res);
//       toast.success(res.message);
//     }
//   };

//   const handleShow = (id) => {
//     try {
//       const callback = (err, res) => {
//         if (err) {
//           toast.error(err);
//         } else {
//           console.log(res);
//           setEditUserRelative(res);
//           toast.success(res.message);
//           setShow(true);

//           setFormData({
//             _id: id,
//             name: res.name,
//             relation: res.relation,
//             birthDate: res.birthDate,
//             surname: res.surname,
//           });
//         }
//       };
//       dispatch(USER_RELATIVE_PROFILE({ data: id, callback }));
//     } catch (error) {
//       console.error(error, "<===err");
//     }
//   };

//   const handleUserStatus = (id, status) => {
//     const data = { _id: id, status: status };
//     try {
//       const callback = (err, res) => {
//         if (err) {
//           toast.error(err);
//         } else {
//           console.log(res);
//           toast.success(res.message);
//         }
//       };
//       dispatch(dispatch(UPDATEUSER_RELATIVE_STATUS({ data, callback })));
//     } catch (error) {
//       console.error(error, "<===err");
//     }
//   };

//   const handleInputChange = (e) => {
//     const { id, value } = e.target;
//     setFormData({ ...formData, [id]: value });
//   };

//   const handleEditSub = () => {
//     try {
//       const callback = (err, res) => {
//         if (err) {
//           toast.error(err);
//         } else {
//           setShow(false);
//           setEditUserRelative(res);
//           toast.success("Relative Profile updated successfully!");

//           // Update relative1 state to reflect the changes
//           setRelative1((prevRelative) =>
//             prevRelative.map((item) =>
//               item._id === formData._id ? { ...item, ...formData } : item
//             )
//           );
//         }
//       };
//       dispatch(
//         updateRelativeProfile({
//           data: formData,
//           callback,
//         })
//       );
//     } catch (error) {
//       console.error(error, "<===err");
//     }
//   };

//   const handleFilterForm = (info) => {
//     setFilter({
//       ...filter,
//       pageNo: 1,
//       limit: pageSize,
//       order: -1,
//       orderBy: "dateCreatedUtc",
//       search: info.search,
//       status: info.select,
//     });
//   };

//   const handleViewUser = (id) => {
//     navigate(`/admin/users/profile/${id}`);
//   };

//   // console.log(editUserRelative, "editUserRelative");
//   // console.log(formData.birthDate, "formadata");

//   console.log(relative1, "relative1");
//   // console.log(relative, "relative");
//   // console.log(user, "users");

//   return (
//     <>
//       {profileLoading && (
//         <div className="main-loader">
//           <FadeLoader size={1000} />
//         </div>
//       )}

//       <Modal show={show} onHide={handleClose}>
//         <Modal.Header closeButton>
//           <Modal.Title>Edit Relative</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <div className="row">
//             <div className="col">
//               <Form.Group className="mb-3" controlId="name">
//                 <Form.Label> Name</Form.Label>
//                 <Form.Control
//                   type="text"
//                   placeholder="Enter name"
//                   value={formData.name}
//                   onChange={handleInputChange}
//                 />
//               </Form.Group>
//               <Form.Group className="mb-3" controlId="surname">
//                 <Form.Label>Surname</Form.Label>
//                 <Form.Control
//                   type="text"
//                   placeholder="Enter surname"
//                   value={formData.surname}
//                   onChange={handleInputChange}
//                 />
//               </Form.Group>
//               <Form.Group className="mb-3" controlId="relation">
//                 <Form.Label>Relation</Form.Label>
//                 <Form.Control
//                   as="select" // Change input type to select
//                   value={formData.relation} // Bind selected value to formData.relation
//                   onChange={handleInputChange} // Handle change event
//                 >
//                   <option value={formData.relation}>{formData.relation}</option>
//                   // Placeholder option
//                   <option value="father">Father</option> // Sample options
//                   <option value="sister">Sister</option>
//                   <option value="grandFather">GrandFather</option>
//                   {/* Add more options as needed */}
//                 </Form.Control>
//               </Form.Group>

//               <Form.Group className="mb-3" controlId="birthDate">
//                 <Form.Label>BirthDate</Form.Label>
//                 <Form.Control
//                   type="date"
//                   value={formData.birthDate.slice(0, 10)}
//                   onChange={handleInputChange}
//                 />
//               </Form.Group>
//             </div>
//           </div>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={handleClose}>
//             Close
//           </Button>
//           <Button variant="primary" onClick={handleEditSub}>
//             Submit
//           </Button>
//         </Modal.Footer>
//       </Modal>

//       <Helmet title={USER?.CURRENT_MODULE} />
//       <section className="section">
//         <div className="section-header">
//           <h1 className="">User Detail</h1>
//           <div className="section-header-breadcrumb">
//             <div className="breadcrumb-item active">
//               <Link to={"/admin/" + Path.users}>Users</Link>
//             </div>
//             <div className="breadcrumb-item">UserDetail </div>
//           </div>
//         </div>
//         <Container>
//           <Row className="">
//             <Col className="" md={6}>
//               {user && (
//                 <Card>
//                   <Card.Body>
//                     <Card.Text>
//                       <p>
//                         <strong>Name:</strong> {user.data.name}
//                       </p>
//                       <p>
//                         <strong>Email:</strong> {user.data.email}
//                       </p>
//                       <p>
//                         <strong>Phone:</strong> {user.data.mobileNumber}
//                       </p>
//                       <p>
//                         <strong>DateCreated:</strong> {formattedDate}
//                       </p>
//                     </Card.Text>
//                   </Card.Body>
//                 </Card>
//               )}
//             </Col>
//           </Row>
//         </Container>
//       </section>
//       {user && (
//         <section className="section m-2 ">
//           <div className="container">
//             <div className="card">
//               <div className="row justify-content-between">
//                 <div className="col-12">
//                   <div className="card-header">
//                     <FormikForm
//                       initialValues={initialValuesForFilter}
//                       onSubmit={handleFilterForm}
//                       loading={loading}
//                       inputFields={InputFieldsForSearch}
//                       buttonText={"Search"}

//                       // onClick={"select"}
//                     />
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <table className="table">
//               <thead>
//                 <tr>
//                   <th>Sr.No</th>
//                   <th>Relative Name</th>
//                   <th>Surname</th>
//                   <th>Age(Years)</th>
//                   <th>Relationship</th>
//                   <th>Status</th>
//                   <th>QrLinkStatus</th>
//                   <th>Action</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {relative1?.length > 0 &&
//                   relative1.map((relative, index) => (
//                     <tr key={index}>
//                       <td>{index + 1}</td>
//                       <td>{relative.name}</td>
//                       <td>{relative.surname}</td>
//                       <td>
//                         {relative.age &&
//                           formatYearsAndMonths(calculateAge(relative.age))}
//                       </td>
//                       <td>{relative.relation}</td>
//                       <td>
//                         <Badge
//                           className={`text-capitalize font-size-13 badge-soft-${relative.status}`}
//                           color={relative.badgeClass}
//                         >
//                           {relative.status}
//                         </Badge>
//                       </td>
//                       <td>
//                         <Badge
//                           className={`text-capitalize font-size-13 badge-soft-${relative.status}`}
//                           color={relative.badgeClass}
//                         >
//                           {relative?.linkedQrCodeDetails?.status}
//                         </Badge>
//                       </td>
//                       <td className="d-flex">
//                         {relative.status && relative.status == "active" ? (
//                           <button
//                             className="fas fa-toggle-on"
//                             style={{
//                               fontSize: "20px",
//                               color: "green",
//                               backgroundColor: "#fff",
//                               border: "none",
//                             }}
//                             title="Active"
//                             onClick={() =>
//                               handleUserStatus(relative._id, "inactive")
//                             }
//                           ></button>
//                         ) : (
//                           <button
//                             className="fas fa-toggle-off"
//                             style={{
//                               fontSize: "20px",
//                               color: "red",
//                               backgroundColor: "#fff",
//                               border: "none",
//                             }}
//                             title="Inactive"
//                             onClick={() =>
//                               handleUserStatus(relative._id, "active")
//                             }
//                           ></button>
//                         )}
//                         {/* <button
//                           className="fas fa-pen"
//                           style={{
//                             fontSize: "16px",
//                             backgroundColor: "#fff",
//                             border: "none",
//                           }}
//                           title="Edit"
//                           onClick={() => handleShow(relative?._id)}
//                         ></button> */}

//                         <button
//                           className=""
//                           title="View"
//                           onClick={() => handleViewUser(relative?._id)}
//                           style={{
//                             fontSize: "20px",
//                             color: "green",
//                             backgroundColor: "#fff",
//                             border: "none",
//                           }}
//                         >
//                           <i className="fa fa-eye"></i>
//                         </button>
//                       </td>
//                     </tr>
//                   ))}
//               </tbody>
//             </table>
//             {relative1?.length === 0 && (
//               <p style={{ textAlign: "center" }}>No Relative Found</p>
//             )}
//           </div>
//         </section>
//       )}
//     </>
//   );
// };
// export default UserDetails;

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import { USER_PROFILE } from "store/user/actions";
import {
  UPDATEUSER_RELATIVE_STATUS,
  USER_RELATIVE,
  USER_RELATIVE_PROFILE,
  updateRelativeProfile,
} from "store/Relative/actions";
import { USER } from "common/viewContent";
import * as Path from "routes/Path/index";
import { toast } from "react-toastify";
import Badge from "react-bootstrap/Badge";
import moment from "moment";
import FadeLoader from "react-spinners/FadeLoader";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import { Helmet } from "react-helmet";
import { calculateAge, formatYearsAndMonths } from "helpers/common";
import FormikForm from "components/shared/FormikForm";
import Columns from "./Columns"
import Table from "components/Table";
import BackButtom from "components/BackButtom";

const InputFieldsForSearch = [
  {
    name: "search",
    type: "text",
    label: "",
    placeholder: "Search using first name, last name, email...",
  },
  {
    name: "select",
    type: "select",
    label: "",
    placeholder: "Select status",
    options: [
      { value: "active", label: "Active" },
      { value: "inactive", label: "Inactive" },
      { value: "pending", label: "Pending" },
      { value: "deleted", label: "Deleted" },
    ],
  },
];
const initialValuesForFilter = {
  search: "",
  select: "",
};

const UserDetails = () => {
  const { id: userId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    relation: "",
    birthDate: "",
    surname: "",
  });
  const [editUserRelative, setEditUserRelative] = useState(null); 

  const handleClose = () => setShow(false);

  const { loading: profileLoading, user } = useSelector((state) => state.Users);
  const { loading, relative, totalCount } = useSelector(
    (state) => state.Relative
  );
  const [relative1, setRelative1] = useState();
  const [columns, setColumns] = useState();
  const [filter, setFilter] = useState({
    userId: userId,
    status: "",
    pageNo: 1,
    limit: 20,
    order: -1,
    orderBy: "dateCreatedUtc",
  });
  const [formattedDate, setFormattedDate] = useState("");

  useEffect(() => {
    dispatch(USER_PROFILE({ data: userId }));
  }, [userId]);



  useEffect(() => {
    if (userId) {
      dispatch(USER_RELATIVE({ data: { ...filter, userId }, callback }));
      setColumns(Columns({ handleUserStatus, handleViewUser }));
    }
  }, [userId, filter]);


  // useEffect(() => {
  //   if (user && user.data && user.data.dateCreated) {
  //     setFormattedDate(
  //       moment(user.data.dateCreated).format("MMMM DD, YYYY HH:mm A")
  //     );
  //   }
  // }, [user, filter]);

  const callback = (err, res) => {
    if (err) {
      toast.error(err);
    } else {
      setRelative1(res);
      toast.success(res.message);
    }
  };

  // const handleShow = (id) => {
  //   try {
  //     const callback = (err, res) => {
  //       if (err) {
  //         toast.error(err);
  //       } else {
  //         console.log(res);
  //         setEditUserRelative(res);
  //         toast.success(res.message);
  //         setShow(true);

  //         setFormData({
  //           _id: id,
  //           name: res.name,
  //           relation: res.relation,
  //           birthDate: res.birthDate,
  //           surname: res.surname,
  //         });
  //       }
  //     };
  //     dispatch(USER_RELATIVE_PROFILE({ data: id, callback }));
  //   } catch (error) {
  //     console.error(error, "<===err");
  //   }
  // };

  const handleUserStatus = (id, status) => {
    const data = { _id: id, status: status };
    try {
      const callback = (err, res) => {
        if (err) {
          toast.error(err);
        } else {
          console.log(res);
          toast.success(res.message);
        }
      };
      dispatch(UPDATEUSER_RELATIVE_STATUS({ data, callback }));
    } catch (error) {
      console.error(error, "<===err");
    }
  };

  // const handleInputChange = (e) => {
  //   const { id, value } = e.target;
  //   setFormData({ ...formData, [id]: value });
  // };

  // const handleEditSub = () => {
  //   try {
  //     const callback = (err, res) => {
  //       if (err) {
  //         toast.error(err);
  //       } else {
  //         setShow(false);
  //         setEditUserRelative(res);
  //         toast.success("Relative Profile updated successfully!");

  //         // Update relative1 state to reflect the changes
  //         setRelative1((prevRelative) =>
  //           prevRelative.map((item) =>
  //             item._id === formData._id ? { ...item, ...formData } : item
  //           )
  //         );
  //       }
  //     };
  //     dispatch(
  //       updateRelativeProfile({
  //         data: formData,
  //         callback,
  //       })
  //     );
  //   } catch (error) {
  //     console.error(error, "<===err");
  //   }
  // };
  const handleFilterForm = (info) => {
    setFilter({
      ...filter,
      pageNo: 1,
      search: info.search,
      status: info.select,
    });
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleViewUser = (id) => {
    navigate(`/admin/users/profile/${id}`);
  };
  console.log(relative1, "relative =====>");
  return (
    <>
      {profileLoading && (
        <div className="main-loader">
          <FadeLoader size={1000} />
        </div>
      )}
      {/* <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Relative</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col">
              <Form.Group className="mb-3" controlId="name">
                <Form.Label> Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter name"
                  value={formData.name}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="surname">
                <Form.Label>Surname</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter surname"
                  value={formData.surname}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="relation">
                <Form.Label>Relation</Form.Label>
                <Form.Control
                  as="select" // Change input type to select
                  value={formData.relation} // Bind selected value to formData.relation
                  onChange={handleInputChange} // Handle change event
                >
                  <option value={formData.relation}>{formData.relation}</option>
                  <option value="father">Father</option>
                  <option value="sister">Sister</option>
                  <option value="grandFather">GrandFather</option>
                </Form.Control>
              </Form.Group>
              <Form.Group className="mb-3" controlId="birthDate">
                <Form.Label>BirthDate</Form.Label>
                <Form.Control
                  type="date"
                  value={formData.birthDate.slice(0, 10)}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleEditSub}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal> */}
      <Helmet title={USER?.CURRENT_MODULE} />
      <section className="section">
        <div className="section-header">
        <BackButtom/>
          <h1 className="">User Detail</h1>
          <div className="section-header-breadcrumb">
            <div className="breadcrumb-item active">
              <Link to={"/admin/" + Path.users}>Users</Link>
            </div>
            <div className="breadcrumb-item">UserDetail </div>
          </div>
        </div>
        <Container>
          <Row className="">
            <Col className="" md={6}>
              {user && (
                <Card>
                  <Card.Body>
                    <Card.Text>
                      <p>
                        <strong>Name:</strong> {user.data.name}
                      </p>
                      <p>
                        <strong>Email:</strong> {user.data.email}
                      </p>
                      <p>
                        <strong>Phone:</strong> {user.data.mobileNumber}
                      </p>
                      <p>
                        <strong>DateCreated:</strong> {formattedDate}
                      </p>
                    </Card.Text>
                  </Card.Body>
                </Card>
              )}
            </Col>
          </Row>
        </Container>
      </section>
      {user && (
        <section className="section  ">
          <div className="container">
            <h4 className="">Relative Profiles</h4>
            <div className="card">
              <div className="row justify-content-between">
                <div className="col-12">
                  <div className="card-header">
                    <FormikForm
                      initialValues={initialValuesForFilter}
                      onSubmit={handleFilterForm}
                      loading={loading}
                      inputFields={InputFieldsForSearch}
                      buttonText={"Search"}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* <Table
              data={relative1}
              columns={columns}
              setFilter={setFilter}
              // total={pageSize}
              totalCount={totalCount}
              handleTableChange={handlePageChange}
              filter={filter}
              toggleConfirmModal={handleUserStatus}
            /> */}
            <table className="table">
              <thead>
                <tr>
                  <th>Sr.No</th>
                  <th>Relative Name</th>
                  <th>Surname</th>
                  <th>Age(Years)</th>
                  <th>Relationship</th>
                  <th>Status</th>
                  <th>QrLinkStatus</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {relative1?.length > 0 &&
                  relative1.map((relative, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{relative.name}</td>
                      <td>{relative.surname}</td>
                      <td>
                        {relative.age &&
                          formatYearsAndMonths(calculateAge(relative.age))}
                      </td>
                      <td>{relative.relation}</td>
                      <td>
                        <Badge
                          className={`text-capitalize font-size-13 badge-soft-${relative.status}`}
                          color={relative.badgeClass}
                        >
                          {relative.status}
                        </Badge>
                      </td>
                      <td>
                        <Badge
                          className={`text-capitalize font-size-13 badge-soft-${relative.status}`}
                          color={relative.badgeClass}
                        >
                          {relative?.linkedQrCodeDetails?.status}
                        </Badge>
                      </td>
                      <td className="d-flex">
                        {relative.status && relative.status == "active" ? (
                          <button
                            className="fas fa-toggle-on"
                            style={{
                              fontSize: "20px",
                              color: "green",
                              backgroundColor: "#fff",
                              border: "none",
                            }}
                            title="Active"
                            onClick={() =>
                              handleUserStatus(relative._id, "inactive")
                            }
                          ></button>
                        ) : (
                          <button
                            className="fas fa-toggle-off"
                            style={{
                              fontSize: "20px",
                              color: "red",
                              backgroundColor: "#fff",
                              border: "none",
                            }}
                            title="Inactive"
                            onClick={() =>
                              handleUserStatus(relative._id, "active")
                            }
                          ></button>
                        )}
                        <button
                          className=""
                          title="View"
                          onClick={() => handleViewUser(relative?._id)}
                          style={{
                            fontSize: "20px",
                            color: "green",
                            backgroundColor: "#fff",
                            border: "none",
                          }}
                        >
                          <i className="fa fa-eye"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {relative1?.length === 0 && (
              <p style={{ textAlign: "center" }}>No Relative Found</p>
            )}
          </div>
        </section>
      )}
    </>
  );
};

export default UserDetails;
