// import { DATE_FORMAT } from "helpers/common";
// import Badge from "react-bootstrap/Badge";
// import React from "react";
// import { Link } from "react-router-dom";

// const STATUS = {
//   active: "Active",
//   inactive: "Inactive",
//   blocked: "Blocked",
//   deleted: "Deleted",
//   pending: "Pending",
// };

// const Columns = ({ handleUserStatus, handleViewUser }) => [
//   {
//     dataField: "name",
//     text: "Name",
//     formatter: (_, row) => <span className="text-capitalize">{row.name}</span>,
//   },
//   {
//     dataField: "surname",
//     text: " Surname",
//     formatter: (_, row) => (
//       <span className="text-capitalize">{row.surname}</span>
//     ),
//   },
//   {
//     dataField: "age",
//     text: "Age",
//     formatter: (_, row) => <span className="text">{row.age}</span>,
//   },

//   {
//     text: "Status",
//     dataField: "status",
//     formatter: (cellContent, row) => (
//       <Badge
//         className={`text-capitalize font-size-13 badge-soft-${
//           row.status === "pending" ? "warning" : "success"
//         }`}
//         color={row.badgeClass}
//       >
//         {STATUS[row.status]}
//       </Badge>
//     ),
//   },

//   {
//     text: "Action",
//     dataField: "status",
//     formatter: (_, row) => (
//       <>
//         {row?.status && row?.status == "inactive" ? (
//           <button
//             className="fas fa-toggle-on"
//             style={{
//               fontSize: "20px",
//               color: "green",
//               backgroundColor: "#fff",
//               border: "none",
//             }}
//             title="Active"
//             onClick={() => handleUserStatus(row._id, "active")}
//           ></button>
//         ) : (
//           <button
//             className="fas fa-toggle-off"
//             style={{
//               fontSize: "20px",
//               color: "red",
//               backgroundColor: "#fff",
//               border: "none",
//             }}
//             title="Inactive"
//             onClick={() => handleUserStatus(row._id, "inactive")}
//           ></button>
//         )}

//         {
//           <button
//             className="fa fa-eye"
//             style={{
//               fontSize: "20px",
//               color: "green",
//               backgroundColor: "#fff",
//               border: "none",
//             }}
//             title="View"
//             onClick={() => handleViewUser(row._id)}
//           ></button>
//         }
//       </>
//     ),
//   },
// ];
// export default Columns;


import { DATE_FORMAT, calculateAge, formatYearsAndMonths } from "helpers/common";
import Badge from "react-bootstrap/Badge";
import React from "react";
import { Link } from "react-router-dom";

const STATUS = {
  active: "Active",
  inactive: "Inactive",
  blocked: "Blocked",
  deleted: "Deleted",
  pending: "Pending",
};

const Columns = ({ handleUserStatus, handleViewUser }) => [
  {
    dataField: "name",
    text: "Relative Name",
    formatter: (_, row) => <span className="text-capitalize">{row.name}</span>,
  },
  {
    dataField: "surname",
    text: "Surname",
    formatter: (_, row) => (
      <span className="text-capitalize">{row.surname}</span>
    ),
  },
  {
    dataField: "age",
    text: "Age (Years)",
    formatter: (_, row) => (
      <span className="text">
        {row.age && formatYearsAndMonths(calculateAge(row.age))}
      </span>
    ),
  },
  {
    text: "Relationship",
    dataField: "relation",
  },
  {
    text: "Status",
    dataField: "status",
    formatter: (cellContent, row) => (
      <Badge
        className={`text-capitalize font-size-13 badge-soft-${
          row.status === "pending" ? "warning" : "success"
        }`}
        color={row.badgeClass}
      >
        {STATUS[row.status]}
      </Badge>
    ),
  },
  {
    text: "QrLinkStatus",
    dataField: "linkedQrStatus",
    formatter: (_, row) => (
      <Badge
        className={`text-capitalize font-size-13 badge-soft-${
          row.status === "pending" ? "warning" : "success"
        }`}
        color={row.badgeClass}
      >
        {row.linkedQrCodeDetails?.status}
      </Badge>
    ),
  },
  {
    text: "Action",
    dataField: "",
    formatter: (_, row) => (
      <>
        {row?.status && row?.status === "inactive" ? (
          <button
            className="fas fa-toggle-on"
            style={{
              fontSize: "20px",
              color: "green",
              backgroundColor: "#fff",
              border: "none",
            }}
            title="Activate"
            onClick={() => handleUserStatus(row._id, "active")}
          ></button>
        ) : (
          <button
            className="fas fa-toggle-off"
            style={{
              fontSize: "20px",
              color: "red",
              backgroundColor: "#fff",
              border: "none",
            }}
            title="Deactivate"
            onClick={() => handleUserStatus(row._id, "inactive")}
          ></button>
        )}
        <button
          className="fa fa-eye"
          style={{
            fontSize: "20px",
            color: "green",
            backgroundColor: "#fff",
            border: "none",
          }}
          title="View"
          onClick={() => handleViewUser(row._id)}
        ></button>
      </>
    ),
  },
];

export default Columns;

