// import React, { useState, useEffect } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import FadeLoader from "react-spinners/FadeLoader";
// import { MARCHANT } from "common/viewContent";
// import Helmet from "react-helmet";
// import * as Path from "routes/Path/index";
// import Table from "components/Table";
// import { toast } from "react-toastify";
// import Select from "react-select";
// import Columns from "./Columns";
// import FormikForm from "components/shared/FormikForm";
// import {
//   MARCHANTLIST,
//   MarchantAdd,
//   MarchantEdit,
//   deleteMarchant,
// } from "store/Marchant/actions";
// import AddMarchant from "components/Modals/AddMarchant";
// import EditMarchant from "components/Modals/EditMarchant";
// import ConfirmModal from "components/Modals/ConfirmModal";

// // const InputFieldsForSearch = [
// //   {
// //     name: "search",
// //     type: "text",
// //     label: "",
// //     placeholder: "Search using company name & searchByQr..",
// //   },
// //   {
// //     name: "select",
// //     type: "select",
// //     label: "",
// //     placeholder: "Select Status",
// //     options: [
// //       { value: "active", label: "Active" },
// //       { value: "archived", label: "Archieved" },

// //       { value: "inactive", label: "Inactive" },
// //     ],
// //   },
// // ];
// // const initialValuesForFilter = {
// //   search: "",
// //   select: "",
// // };

// const useStatusOptions = [
//   { value: "active", label: "Active" },
//   { value: "archived", label: "Archieved" },

//   { value: "inactive", label: "Inactive" },
// ];

// const Marchant = () => {
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const [addModalShow, setAddModalShow] = useState(false);
//   const [editModalShow, setEditModalShow] = useState(false);

//   const { loading, totalCount } = useSelector((state) => state?.marchants);
//   const [columns, setColumns] = useState([]);
//   const [marchant, setMarchant] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [confirmModalMerchant, setConfirmModalMerchant] = useState(false);
//   const [deleteId, setDeleteId] = useState(null);
//   const [pageSize, setPageSize] = useState(10);
//   const [search, setSearch] = useState("");
//   const [searchByQr, setSearchByQr] = useState("");
//   const [status, setStatus] = useState("active");
//   const [filter, setFilter] = useState({
//     pageNo: 1,
//     limit: pageSize,
//     order: -1,
//     orderBy: "createdAt",
//     status: "active",
//   });

//   const [formData, setFormData] = useState({
//     name: "",
//     email: "",
//     countryCode: "995",
//     mobileNumber: "",
//     address: "",
//     contactPersonName: "",
//     comments: "",
//   });
//   const fetchData = async (filter) => {
//     try {
//       const callback = (err, res) => {
//         if (err) {
//           toast.error(err);
//         } else {
//           console.log(res);
//           setMarchant(res?.data);
//           toast.success(res.data.message);
//         }
//       };
//       dispatch(MARCHANTLIST({ data: filter, callback }));
//     } catch (error) {
//       console.error(error, "<===err");
//     }
//   };
//   useEffect(() => {
//     fetchData(filter);
//     setColumns(
//       Columns({
//         handleEdithandler,
//         handleViewUser,
//         deleteHandler,
//       })
//     );
//   }, [filter]);
//   const handleSubmit_ = (info) => {
//     console.log(info, "info value ==========================================>");
//     try {
//       const callback = (err, res) => {
//         if (err) {
//           toast.error(err);
//         } else {
//           console.log(res);
//           setAddModalShow(false);
//           fetchData(filter);
//           toast.success(res.message);
//         }
//       };
//       dispatch(
//         MarchantAdd({
//           data: info,
//           callback,
//         })
//       );
//     } catch (error) {
//       console.error(error, "<===err");
//     }
//   };
//   // const deleteHandler = (id) => {
//   //   console.log(id, "ids---------------------->");
//   //   const callback = (err, res) => {
//   //     if (err) {
//   //       toast.error(err);
//   //     } else {
//   //       toast.success(res.message);
//   //       fetchData(filter);
//   //     }
//   //   };
//   //   dispatch(deleteMarchant({ data: id, callback }));
//   // };

//   const deleteHandler = (id) => {
//     setConfirmModalMerchant(true);
//     setDeleteId(id);
//   };

//   const handleDeleteConfirm = (event) => {
//     event.preventDefault();
//     console.log("text message");
//     const callback = (err, res) => {
//       if (err) {
//         toast.error(err);
//       } else {
//         toast.success(res.message);
//         fetchData(filter);
//       }
//     };
//     dispatch(deleteMarchant({ data: deleteId, callback }));
//     setConfirmModalMerchant(false);
//     setDeleteId(null);
//   };

//   const handleFilterForm = (info) => {
//     setFilter((pre) => ({
//       ...pre,
//       pageNo: 1,
//       limit: pageSize,
//       search: info.search,
//       status: info.select,
//     }));
//   };
//   const handleEdithandler = (info) => {
//     setFormData({ ...formData, ...info });
//     setEditModalShow(true);
//   };
//   const handleEditSub = (info) => {
//     try {
//       let data = {
//         _id: info?._id,
//         name: info.name,
//         email: info.email,
//         mobileNumber: info.mobileNumber,
//         address: info.address,
//         comments: info.comments,
//         countryCode: info?.countryCode || info?.country_code,
//       };
//       const callback = (err, res) => {
//         if (err) {
//           toast.error(err);
//         } else {
//           setEditModalShow(false);
//           fetchData(filter);
//           toast.success("User updated successfully!");
//           setFormData({});
//         }
//       };
//       dispatch(
//         MarchantEdit({
//           data: data,
//           callback,
//         })
//       );
//     } catch (error) {
//       console.error(error, "<===err");
//     }
//   };
//   const onClickFilter = (e) => {
//     e?.preventDefault();
//     setFilter({
//       ...filter,
//       pageNo: 1,
//       limit: pageSize,
//       search: search,
//       searchByQr: searchByQr,
//       status: status,
//     });
//   };

//   const handlePageChange = (pageNumber) => {
//     // setFilter((prev)=>({...prev, pageNo : pageNumber}));
//     // setCurrentPage(pageNumber);
//   };

//   const handleViewUser = (id) => {
//     navigate(`${id}`);
//   };

//   console.log(totalCount, "marchgant=================================");
//   return (
//     <>
//       <AddMarchant
//         show={addModalShow}
//         handleClose={() => setAddModalShow(false)}
//         title={"Add Merchant"}
//         content={""}
//         handleConfirm={handleSubmit_}
//         loading={loading}
//         initialValues={formData}
//       />

//       <ConfirmModal
//         show={confirmModalMerchant}
//         handleClose={() => setConfirmModalMerchant(false)}
//         title={"Confirmation"}
//         content={"Are you sure you want to delete the Merchant?"}
//         handleConfirm={handleDeleteConfirm}
//         loading={""}
//       />
//       <EditMarchant
//         show={editModalShow}
//         handleClose={() => setEditModalShow(false)}
//         title={"Edit Marchant"}
//         content={""}
//         handleConfirm={handleEditSub}
//         loading={loading}
//         initialValues={formData}
//       />
//       {loading && (
//         <div className="main-loader">
//           <FadeLoader size={1000} />
//         </div>
//       )}
//       <Helmet title={MARCHANT?.CURRENT_MODULE} />
//       <section className="section">
//         <div className="section-header">
//           <h1>{MARCHANT?.CURRENT_MODULE}</h1>
//           <div className="section-header-breadcrumb">
//             <div className="breadcrumb-item active">
//               <Link to={"/admin/" + Path.dashboard}>Dashboard</Link>
//             </div>
//             <div className="breadcrumb-item">{MARCHANT?.CURRENT_MODULE} </div>
//           </div>
//         </div>
//         <div className="section-body">
//           <div className="card">
//             <div className="row justify-content-between">
//               <div className="col-6">
//                 <div className="card-header">
//                   <h2 className="section-title">{MARCHANT?.TOP_CONTENT}</h2>
//                 </div>
//               </div>
//               <div className="col-6">
//                 <div className="card-header d-flex justify-content-end align-items-center">
//                   <button
//                     className="btn btn-primary"
//                     onClick={() => setAddModalShow(true)}
//                   >
//                     Add Merchant
//                   </button>
//                 </div>
//               </div>
//             </div>
//             <div className="col-12">
//               <form onSubmit={onClickFilter}>
//                 <div className="card-header">
//                   <div className="col-lg-4 col-sm-12 col-md-12 my-2">
//                     <input
//                       className="form-control"
//                       name="search"
//                       onChange={(e) => setSearch(e.target.value)}
//                       value={search}
//                       placeholder="Search using name, email..."
//                     />
//                   </div>
//                   <div className="col-lg-4 col-sm-12 col-md-12 my-2">
//                     <input
//                       className="form-control"
//                       name="searchByQr"
//                       onChange={(e) => setSearchByQr(e.target.value)}
//                       value={searchByQr}
//                       placeholder="Search by QR code "
//                     />
//                   </div>
//                   <div className="col-lg-4 col-sm-12 col-md-12 my-2">
//                     <Select
//                       className="form-group"
//                       name={"status"}
//                       options={useStatusOptions} 
//                       onChange={(selectedOption) => {
//                         setStatus(selectedOption.value);
//                       }}
//                       placeholder={"Select status"}
//                       // value={values[field.name]}
//                       value={useStatusOptions.find(
//                         (option) => option.value === status
//                       )} // Find the
//                     />
//                   </div>
//                 </div>
//                 <div className="col-12 my-2">
//                   <button type="submit" className="btn btn-primary btn-lg">
//                     Search
//                   </button>
//                 </div>
//               </form>
//             </div>
//             {/* <div className="col-12">
//               <div className="card-header">
//                 <FormikForm
//                   initialValues={initialValuesForFilter}
//                   onSubmit={handleFilterForm}
//                   inputFields={InputFieldsForSearch}
//                   buttonText={"Search"}
//                 />
//               </div>
//             </div> */}
//           </div>
//           {!loading && marchant?.length > 0 && (
//             <Table
//               data={marchant}
//               columns={columns}
//               setFilter={setFilter}
//               totalCount={totalCount}
//               handleTableChange={handlePageChange}
//               filter={filter}
//             />
//           )}
//           {!loading && !marchant?.length && (
//             <p style={{ textAlign: "center" }}>No Marchant Found</p>
//           )}
//         </div>
//       </section>
//     </>
//   );
// };
// export default Marchant;

import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FadeLoader from "react-spinners/FadeLoader";
import { MARCHANT } from "common/viewContent";
import Helmet from "react-helmet";
import * as Path from "routes/Path/index";
import Table from "components/Table";
import { toast } from "react-toastify";
import Select from "react-select";
import Columns from "./Columns";

import {
  MARCHANTLIST,
  MarchantAdd,
  MarchantEdit,
  deleteMarchant,
} from "store/Marchant/actions";
import AddMarchant from "components/Modals/AddMarchant";
import EditMarchant from "components/Modals/EditMarchant";
import ConfirmModal from "components/Modals/ConfirmModal";


const useStatusOptions = [
  { value: "active", label: "Active" },
  { value: "archived", label: "Archieved" },

  { value: "inactive", label: "Inactive" },
];

const Marchant = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [addModalShow, setAddModalShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);

  const { loading, totalCount } = useSelector((state) => state?.marchants);
  const [columns, setColumns] = useState([]);
  const [marchant, setMarchant] = useState([]);
 
  const [confirmModalMerchant, setConfirmModalMerchant] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");
  const [searchByQr, setSearchByQr] = useState("");
  const [status, setStatus] = useState("active");
  const [filter, setFilter] = useState({
    pageNo: 1,
    limit: pageSize,
    order: -1,
    orderBy: "createdAt",
    status: "active",
  });

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    countryCode: "995",
    mobileNumber: "",
    address: "",
    contactPersonName: "",
    comments: "",
  });
  const fetchData = async (filter) => {
    try {
      const callback = (err, res) => {
        if (err) {
          toast.error(err);
        } else {
          console.log(res);
          setMarchant(res?.data);
          toast.success(res.data.message);
        }
      };
      dispatch(MARCHANTLIST({ data: filter, callback }));
    } catch (error) {
      console.error(error, "<===err");
    }
  };
  useEffect(() => {
    fetchData(filter);
    setColumns(
      Columns({
        handleEdithandler,
        handleViewUser,
        deleteHandler,
      })
    );
  }, [filter]);
  const handleSubmit_ = (info) => {
    console.log(info, "info value ==========================================>");
    try {
      const callback = (err, res) => {
        if (err) {
          toast.error(err);
        } else {
          console.log(res);
          setAddModalShow(false);
          fetchData(filter);
          toast.success(res.message);
        }
      };
      dispatch(
        MarchantAdd({
          data: info,
          callback,
        })
      );
    } catch (error) {
      console.error(error, "<===err");
    }
  };
  // const deleteHandler = (id) => {
  //   console.log(id, "ids---------------------->");
  //   const callback = (err, res) => {
  //     if (err) {
  //       toast.error(err);
  //     } else {
  //       toast.success(res.message);
  //       fetchData(filter);
  //     }
  //   };
  //   dispatch(deleteMarchant({ data: id, callback }));
  // };

  const deleteHandler = (id) => {
    setConfirmModalMerchant(true);
    setDeleteId(id);
  };

  const handleDeleteConfirm = (event) => {
    event.preventDefault();
    console.log("text message");
    const callback = (err, res) => {
      if (err) {
        toast.error(err);
      } else {
        toast.success(res.message);
        fetchData(filter);
      }
    };
    dispatch(deleteMarchant({ data: deleteId, callback }));
    setConfirmModalMerchant(false);
    setDeleteId(null);
  };

  const handleFilterForm = (info) => {
    setFilter((pre) => ({
      ...pre,
      pageNo: 1,
      limit: pageSize,
      search: info.search,
      status: info.select,
    }));
  };
  const handleEdithandler = (info) => {
    setFormData({ ...formData, ...info });
    setEditModalShow(true);
  };
  const handleEditSub = (info) => {
    try {
      let data = {
        _id: info?._id,
        name: info.name,
        email: info.email,
        mobileNumber: info.mobileNumber,
        address: info.address,
        comments: info.comments,
        countryCode: info?.countryCode || info?.country_code,
      };
      const callback = (err, res) => {
        if (err) {
          toast.error(err);
        } else {
          setEditModalShow(false);
          fetchData(filter);
          toast.success("User updated successfully!");
          setFormData({});
        }
      };
      dispatch(
        MarchantEdit({
          data: data,
          callback,
        })
      );
    } catch (error) {
      console.error(error, "<===err");
    }
  };
  const onClickFilter = (e) => {
    e?.preventDefault();
    setFilter({
      ...filter,
      pageNo: 1,
      limit: pageSize,
      search: search,
      searchByQr: searchByQr,
      status: status,
    });
  };

  const handlePageChange = (pageNumber) => {
    // setFilter((prev)=>({...prev, pageNo : pageNumber}));
    // setCurrentPage(pageNumber);
  };
  const clearFilter = () => {
    setFilter((prev) => ({
      ...prev,
      search: "",
      searchByQr: "",
      status: "active",
    }));
    setSearchByQr("");
    setSearch("");
  };
  const handleViewUser = (id) => {
    navigate(`${id}`);
  };

  console.log(totalCount, "marchgant=================================");
  return (
    <>
      <AddMarchant
        show={addModalShow}
        handleClose={() => setAddModalShow(false)}
        title={"Add Merchant"}
        content={""}
        handleConfirm={handleSubmit_}
        loading={loading}
        initialValues={formData}
      />

      <ConfirmModal
        show={confirmModalMerchant}
        handleClose={() => setConfirmModalMerchant(false)}
        title={"Confirmation"}
        content={"Are you sure you want to delete the Merchant?"}
        handleConfirm={handleDeleteConfirm}
        loading={""}
      />
      <EditMarchant
        show={editModalShow}
        handleClose={() => setEditModalShow(false)}
        title={"Edit Marchant"}
        content={""}
        handleConfirm={handleEditSub}
        loading={loading}
        initialValues={formData}
      />
      {loading && (
        <div className="main-loader">
          <FadeLoader size={1000} />
        </div>
      )}
      <Helmet title={MARCHANT?.CURRENT_MODULE} />
      <section className="section">
        <div className="section-header">
          <h1>{MARCHANT?.CURRENT_MODULE}</h1>
          <div className="section-header-breadcrumb">
            <div className="breadcrumb-item active">
              <Link to={"/admin/" + Path.dashboard}>Dashboard</Link>
            </div>
            <div className="breadcrumb-item">{MARCHANT?.CURRENT_MODULE} </div>
          </div>
        </div>
        <div className="section-body">
          <div className="card">
            <div className="row justify-content-between">
              <div className="col-6">
                <div className="card-header">
                  <h2 className="section-title">{MARCHANT?.TOP_CONTENT}</h2>
                </div>
              </div>
              <div className="col-6">
                <div className="card-header d-flex justify-content-end align-items-center">
                  <button
                    className="btn btn-primary"
                    onClick={() => setAddModalShow(true)}
                  >
                    Add Merchant
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12">
              <form onSubmit={onClickFilter}>
                <div className="card-header">
                  <div className="col-lg-4 col-sm-12 col-md-12 my-2">
                    <input
                      className="form-control"
                      name="search"
                      onChange={(e) => setSearch(e.target.value)}
                      value={search}
                      placeholder="Search using name, email..."
                    />
                  </div>
                  <div className="col-lg-4 col-sm-12 col-md-12 my-2">
                    <input
                      className="form-control"
                      name="searchByQr"
                      onChange={(e) => setSearchByQr(e.target.value)}
                      value={searchByQr}
                      placeholder="Search by QR code "
                    />
                  </div>
                  <div className="col-lg-4 col-sm-12 col-md-12 my-2">
                    <Select
                      className="form-group"
                      name={"status"}
                      options={useStatusOptions}
                      onChange={(selectedOption) => {
                        setStatus(selectedOption.value);
                      }}
                      placeholder={"Select status"}
                      // value={values[field.name]}
                      value={useStatusOptions.find(
                        (option) => option.value === status
                      )} // Find the
                    />
                  </div>
                </div>
                <div className="col-12 my-2">
                  <div className="d-flex gap-4 ">
                    <button type="submit" className="btn btn-primary btn-lg">
                      Search
                    </button>
                    <button
                      onClick={() => clearFilter()}
                      type="btn"
                      className="btn btn-primary btn-lg"
                    >
                      Clear filter
                    </button>
                  </div>
                </div>
              </form>
            </div>
            {/* <div className="col-12">
              <div className="card-header">
                <FormikForm
                  initialValues={initialValuesForFilter}
                  onSubmit={handleFilterForm}
                  inputFields={InputFieldsForSearch}
                  buttonText={"Search"}
                />
              </div>
            </div> */}
          </div>
          {!loading && marchant?.length > 0 && (
            <Table
              data={marchant}
              columns={columns}
              setFilter={setFilter}
              totalCount={totalCount}
              handleTableChange={handlePageChange}
              filter={filter}
            />
          )}
          {!loading && !marchant?.length && (
            <p style={{ textAlign: "center" }}>No Marchant Found</p>
          )}
        </div>
      </section>
    </>
  );
};
export default Marchant;

