import React, { useState, useEffect, useCallback } from "react";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FadeLoader from "react-spinners/FadeLoader";
import { AVATAR_CHARACTERSTIC } from "common/viewContent";
import * as Path from "routes/Path/index";
import { getAvatarCharactersticList, AddAvatarCharacterstic, 
  editAvatarCharacterstic, deleteAvatarCharacterstic, getAvatarCategoryList } from "store/actions";
import { toast } from "react-toastify";
import Table from "components/Table";
import Columns from "./Columns";
import AddModal from "components/Modals/AddAvatarCharacterstic";
import FormikForm from "components/shared/FormikForm";
import inputFields from "./inputFields";

const InputFieldsForSearch = [
  {
    name: "search",
    type: "text",
    label: "",
    placeholder: "Search using name...",
  },
];
const initialValuesForFilter = {
  search: "",
};

const List = () => {
  const dispatch = useDispatch();
  const { avatarCharacterstics, loading, totalAvatarCharacterstics, avatarCategories } = useSelector((state) => state.Avatar);
  const [columns, setcolumns] = useState([{ dataField: "", text: "" }]);

  const [filter, setFilter] = useState({
    pageNo: 1,
    limit: 20,
    order: -1,
    orderBy: "createdAt",
    search: "",
  });

  const [filterC, setFilterC] = useState({
    pageNo: 1,
    limit: 2000,
    order: -1,
    orderBy: "createdAt"
  });

  const [formData, setFormData] = useState({
    name: "",
    imageUrl: "",
    baseUrl: "",
    price: 0,
    categoryId: ""
  });

  const [addModalShow, setAddModalShow] = useState(false);

  const [editModalShow, setEditModalShow] = useState(false);

  useEffect(() => {
    getApiData(filter);
    setcolumns(Columns({ deleteHandler, handleEditHandler }));
  }, [filter]);

  useEffect(() => {
    getCApiData(filterC);
  }, [filterC]);

  async function getApiData(query) {
    try {
      dispatch(getAvatarCharactersticList({ data: query }));
    } catch (error) { }
  }

  async function getCApiData(query) {
    try {
      dispatch(getAvatarCategoryList({ data: query }))
    } catch (error) { }
  }

  useEffect(()=> {
    if (!avatarCategories) return; 
    const category = avatarCategories.map((it) => ({
      value: it._id,
      label: it.name,
    }));
    inputFields.find((it) => it.name === "categoryId").options = category;
  }, [avatarCategories])

  const deleteHandler = (id) => {
    try {
      const userConfirmed = window.confirm("Are you sure, you want to delete this avatar characterstic?");
      if (userConfirmed) {
        const callback = (err, res) => {
          if (err) {
            toast.error(err);
          } 
          else {
            getApiData(filter);
            toast.success(res.message);
          }
        };
        dispatch( deleteAvatarCharacterstic({ data: { _id: id },  callback }));
      }
    } catch (error) {
      console.log(error, "<===err");
    }
  };

  const handleSubmit_ = (info) => {
    try {
      const callback = (err, res) => {
        if (err) {
          toast.error(err);
          setAddModalShow(false);
        } 
        else {
          console.log(res);
          setAddModalShow(false);
          getApiData(filter);
          toast.success(res.message);
        }
      };
      info.price = Number(info.price)
      dispatch( AddAvatarCharacterstic({ data: info,  callback }));
    } 
    catch (error) {
      console.log(error, "<===err");
    }
  };

  const handleFilterForm = (info) => {
    setFilter({
      ...filter,
      pageNo: 1,
      limit: 20,
      order: -1,
      orderBy: "createdAt",
      search: info.search,
    });
  };

  const handleEditHandler = (info) => {
    console.log({ ...formData, ...info });
    setFormData({ ...formData, ...info });
    setEditModalShow(true);
  };

  const handleEditSub = (info) => {
    try {
      console.log('info', info);
      const callback = (err, res) => {
        if (err) {
          toast.error(err);
          setEditModalShow(false);
        } 
        else {
          setEditModalShow(false);
          getApiData(filter);
          toast.success(res.message);
          setFormData({})
        }
      };
      let data = {
        _id: info._id,
        name: info.name,
        price: Number(info.price),
        categoryId: info.categoryId,
        imageUrl: info.imageUrl,
        baseUrl: info.baseUrl,
      }
      dispatch( editAvatarCharacterstic({  data: data,  callback  }));
    } 
    catch (error) {
      console.log(error, "<===err");
    }
  };
  return (
    <>
      <AddModal
        show={addModalShow}
        handleClose={() => {
          setAddModalShow(false);
        }}
        title={"Add Avatar Characterstic"}
        content={""}
        handleConfirm={handleSubmit_}
        loading={loading}
        initialValues={formData}
      />

      <AddModal
        show={editModalShow}
        handleClose={() => {
          setEditModalShow(false);
        }}
        title={"Edit Avatar Characterstic"}
        content={""}
        handleConfirm={handleEditSub}
        loading={loading}
        initialValues={formData}
      />
      {loading && (
        <div className="main-loader">
          <FadeLoader size={1000} />
        </div>
      )}
      <Helmet title={AVATAR_CHARACTERSTIC.CURRENT_MODULE} />

      <section className="section">
        <div className="section-header">
          <h1>{AVATAR_CHARACTERSTIC.CURRENT_MODULE}</h1>

          <div className="section-header-breadcrumb">
            <div className="breadcrumb-item active">
            <Link to={"/admin/"+ Path.dashboard}>Dashboard</Link>
            </div>
            <div className="breadcrumb-item">{AVATAR_CHARACTERSTIC.CURRENT_MODULE} </div>
          </div>
        </div>
        <div className="section-body">
          <div className="card">
            <div className="row justify-content-between">
              <div className="col-6">
                <div className="card-header">
                  <h2 className="section-title">{AVATAR_CHARACTERSTIC.TOP_CONTENT}</h2>
                </div>
              </div>
              <div className="col-6">
                <div className="card-header d-flex justify-content-end align-items-center">
                  <button
                    className="btn btn-primary"
                    onClick={() => setAddModalShow(true)}
                  >
                    Add Avatar Characterstic
                  </button>
                </div>
              </div>
              <div className="col-12">
                <div className="card-header">
                  <FormikForm
                    initialValues={initialValuesForFilter}
                    onSubmit={handleFilterForm}
                    loading={loading}
                    inputFields={InputFieldsForSearch}
                    buttonText={"Search"}
                  />
                </div>
              </div>

            </div>
          </div>
          {avatarCharacterstics?.length > 0 && (
            <Table
              data={avatarCharacterstics}
              columns={columns}
              setFilter={setFilter}
              total={totalAvatarCharacterstics}
              filter={filter}
            />
          )}
          {!loading && avatarCharacterstics?.length === 0 && (
            <p style={{ textAlign: "center" }}>No Characterstics Found</p>
          )}
        </div>
      </section>
    </>
  );
};

export default List;