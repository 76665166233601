import React from "react";
import { connect } from "react-redux";

const Footer = () => {
  return (
    <footer className="main-footer">
      <div className="footer-left">
        Copyright &copy; {process.env.REACT_APP_FOOTER_COPYRIGHT_YEAR}{" "}
        {process.env.REACT_APP_APPNAME_FOOTER}{". "}
        <br></br>
        {process.env.REACT_APP_DESIGN_FOOTER}
      </div>
    </footer>
  );
};
const mapStateToPros = (state) => {
  return {
    isAuthenticated: state.Auth.authToken,
    user:state.Auth.user,
  };
};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Footer);
