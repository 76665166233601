import * as Yup from 'yup';

const Validation = Yup.object({
    username: Yup.string().required("User Name is Required"),
    // lastName: Yup.string().required("Last Name is Required"),
    email: Yup.string().required("Email Required").email("Invalid email address"),
    // mobileNumber: Yup.string().required("Mobile Number Required"),
    password: Yup.string().required("Password Required")
});

export default Validation;
