import { DATE_FORMAT } from "helpers/common";
import Badge from "react-bootstrap/Badge";
import React from "react";
import { Link } from "react-router-dom";

const STATUS = {
  "active":"Active", 
  "inactive":"Inactive",
  "blocked":"Blocked",
  "deleted":"Deleted"
}

const TYPE = {
  "add":"Addition",
  "sub":"Subtraction"
}

const Columns = ({ handleEditHandler }) => [
  {
    dataField: "level",
    text: "Level",
    formatter: (_, row) => <span className="text-capitalize">{row.level}</span>,
  },
  // {
  //   dataField: "type",
  //   text: "Type",
  //   formatter: (cellContent, row) => (
  //     <Badge
  //       className={
  //         `text-capitalize font-size-13 badge-soft-${row.type}`
  //       }
  //       color={row.badgeClass}
  //     >
  //       {(TYPE[row.type])}
  //     </Badge>
  //   ),
  // },
  {
    dataField: "numbers",
    text: "Numbers",
    formatter: (_, row) => <span className="text">{row?.numbers?.join(',') || "---"}</span>,
  },
  {
    dataField: "frequencies",
    text: "Frequency",
    formatter: (_, row) => <span className="text">{row?.frequencies ? JSON.stringify(row?.frequencies) : "---"}</span>,
  },
  {
    dataField: "reviewData",
    text: "Review Data",
    formatter: (_, row) => <span className="text-capitalize">{row?.reviewData? JSON.stringify(row?.reviewData) : "---"}</span>,
  },
  {
    text: "Review Level",
    dataField: "isReviewLevel",
    formatter: (_, row) => (
      <>
        {row?.isReviewLevel && row?.isReviewLevel == true ? (
          <button
            className="fas fa-toggle-on"
            style={{
              fontSize: "20px",
              color: "green",
              backgroundColor: "#fff",
              border: "none",
            }}
            title="Yes"
            // onClick={() => handleUserStatus(row._id, "active")}
          ></button>
        ) : (
          <button
            className="fas fa-toggle-off"
            style={{
              fontSize: "20px",
              color: "red",
              backgroundColor: "#fff",
              border: "none",
            }}
            title="No"
            // onClick={() => handleUserStatus(row._id, "inactive")}
          ></button>
        )}
      </>
    ),
  },
  {
    dataField: "passCount",
    text: "Pass Count",
    formatter: (_, row) => <span className="text-capitalize">{row.passCount || 0}</span>,
  },
  {
    dataField: "timeInSec",
    text: "Time (sec)",
    formatter: (_, row) => <span className="text-capitalize">{row.timeInSec || 0}</span>,
  },
  {
    dataField: "starsAwarded",
    text: "Stars Awarded",
    formatter: (_, row) => <span className="text-capitalize">{row.starsAwarded || 0}</span>,
  },
  // {
  //   text: "Created On",
  //   dataField: "createdAt",
  //   formatter: (_, row) => DATE_FORMAT(row.createdAt),
  // },
  // {
  //   text: "Status",
  //   dataField: "status",
  //   formatter: (cellContent, row) => (
  //     <Badge
  //       className={
  //         `text-capitalize font-size-13 badge-soft-${row.status}`
  //       }
  //       color={row.badgeClass}
  //     >
  //       {(STATUS[row.status])}
  //     </Badge>
  //   ),
  // },
  {
    text: "Action",
    dataField: "status",
    formatter: (_, row) => (
      <>
       <button
          className="fas fa-pen"
          style={{
            fontSize: "16px",
            backgroundColor: "#fff",
            border: "none",
          }}
          title="Edit"
          onClick={() =>
            handleEditHandler(row)
          }
        ></button>
      </>
    ),
  },
];
export default Columns;
