import { createAsyncThunk } from "@reduxjs/toolkit";

import * as URL from "helpers/url_helper";
import { get, post, put, del } from "helpers/api_helper";

export const getAvatarList = createAsyncThunk(
    "getAvatarList",
    async ({ data, callback }, Thunk) => {
        try {
            let response = await post(URL.GET_AVATAR_LIST, data);
            callback && callback(null, response.data);
            return response;
        } catch (error) {
            return Thunk.rejectWithValue(error);
        }
    }
);

export const AddAvatar = createAsyncThunk(
    "AddAvatar",
    async ({ data, callback }, Thunk) => {
        try {
            let response = await post(URL.ADD_AVATAR, data);
            callback && callback(null, response);
            return response;
        } catch (error) {
            callback && callback(error.message, null);
            return Thunk.rejectWithValue(error);
        }
    }
);

export const editAvatar = createAsyncThunk(
    "editAvatar",
    async ({ data, callback }, Thunk) => {
        try {
            let response = await put(URL.EDIT_AVATAR + data?._id, data);
            callback && callback(null, response);
            return response;
        } catch (error) {
            callback && callback(error.message, null);
            return Thunk.rejectWithValue(error);
        }
    }
);

export const deleteAvatar = createAsyncThunk(
    "deleteAvatar",
    async ({ data, callback }, Thunk) => {
        try {
            let response = await del(URL.DELETE_AVATAR + data?._id);
            callback && callback(null, response);
            return response;
        } catch (error) {
            callback && callback(error.message, null);
            return Thunk.rejectWithValue(error);
        }
    }
);

export const getAvatarCategoryList = createAsyncThunk(
    "getAvatarCategoryList",
    async ({ data, callback }, Thunk) => {
        try {
            let response = await post(URL.GET_AVATAR_CATEGORY_LIST, data);
            callback && callback(null, response.data);
            return response;
        } catch (error) {
            return Thunk.rejectWithValue(error);
        }
    }
);

export const AddAvatarCategory = createAsyncThunk(
    "AddAvatarCategory",
    async ({ data, callback }, Thunk) => {
        try {
            let response = await post(URL.ADD_AVATAR_CATEGORY, data);
            callback && callback(null, response);
            return response;
        } catch (error) {
            callback && callback(error.message, null);
            return Thunk.rejectWithValue(error);
        }
    }
);

export const editAvatarCategory = createAsyncThunk(
    "editAvatarCategory",
    async ({ data, callback }, Thunk) => {
        try {
            let response = await put(URL.EDIT_AVATAR_CATEGORY + data?._id, data);
            callback && callback(null, response);
            return response;
        } catch (error) {
            callback && callback(error.message, null);
            return Thunk.rejectWithValue(error);
        }
    }
);

export const deleteAvatarCategory = createAsyncThunk(
    "deleteAvatarCategory",
    async ({ data, callback }, Thunk) => {
        try {
            let response = await del(URL.DELETE_AVATAR_CATEGORY + data?._id);
            callback && callback(null, response);
            return response;
        } catch (error) {
            callback && callback(error.message, null);
            return Thunk.rejectWithValue(error);
        }
    }
);

export const getAvatarCharactersticList = createAsyncThunk(
    "getAvatarCharactersticList",
    async ({ data, callback }, Thunk) => {
        try {
            let response = await post(URL.GET_AVATAR_CHARACTERSTIC_LIST, data);
            callback && callback(null, response.data);
            return response;
        } catch (error) {
            return Thunk.rejectWithValue(error);
        }
    }
);

export const AddAvatarCharacterstic = createAsyncThunk(
    "AddAvatarCharacterstic",
    async ({ data, callback }, Thunk) => {
        try {
            let response = await post(URL.ADD_AVATAR_CHARACTERSTIC, data);
            callback && callback(null, response);
            return response;
        } catch (error) {
            callback && callback(error.message, null);
            return Thunk.rejectWithValue(error);
        }
    }
);

export const editAvatarCharacterstic = createAsyncThunk(
    "editAvatarCharacterstic",
    async ({ data, callback }, Thunk) => {
        try {
            let response = await put(URL.EDIT_AVATAR_CHARACTERSTIC + data?._id, data);
            callback && callback(null, response);
            return response;
        } catch (error) {
            callback && callback(error.message, null);
            return Thunk.rejectWithValue(error);
        }
    }
);

export const deleteAvatarCharacterstic = createAsyncThunk(
    "deleteAvatarCharacterstic",
    async ({ data, callback }, Thunk) => {
        try {
            let response = await del(URL.DELETE_AVATAR_CHARACTERSTIC + data?._id);
            callback && callback(null, response);
            return response;
        } catch (error) {
            callback && callback(error.message, null);
            return Thunk.rejectWithValue(error);
        }
    }
);