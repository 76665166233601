import React from "react";

import { DATE_FORMAT } from "helpers/common";
import { Badge } from "react-bootstrap";

const STATUS = {
  active: "Active",
  archived: "Archieved",
  inactive:"Inactive"
};

const columns = ({
  handleViewUser,
  handleEdithandler,
  deleteHandler,

}) => [
  {
    dataField: "name",
    text: "Company Name",
    formatter: (_, row) => <span className="text-capitalize">{row.name}</span>,
  },
  // {
  //   dataField: "linkedQrCount",
  //   text: "Linked Qr",
  //   formatter: (_, row) => (
  //     <span className="text-capitalize">{row.linkedQrCount ? row.linkedQrCount : "---"}</span>
  //   ),
  // },
  {
    dataField: "pendingQrCount",
    text: "QR",
    formatter: (_, row) => (
      <span className="text-capitalize">{row.pendingQrCount ? row.pendingQrCount : "---"}</span>
    ),
  },
  {
    dataField: "mobileNumber",
    text: "Mobile",
    formatter: (_, row) => (
      <span className="text-capitalize">
        {row.mobileNumber ? row.mobileNumber : "---"}
      </span>
    ),
  },
  {
    dataField: "address",
    text: "Address",
    formatter: (_, row) => (
      <span className="text-capitalize">
        {row.address ? row.address : "---"}
      </span>
    ),
  },
  {
    dataField: "status",
    text: "Merchant Status",
    formatter: (cellContent, row) => (
      <Badge
        className={`text-capitalize font-size-13 badge-soft-${
          row.status === "pending" ? "warning" : "success"
        }`}
      >
        {STATUS[row.status]}
      </Badge>
    ),
  },

  {
    text: "Created At",
    dataField: "createdAt",
    formatter: (_, row) => DATE_FORMAT(row.createdAt),
  },
  {
    text: "Action",
    dataField: "",
    formatter: (_, row) => (
      <>
        {
          <button
            className="fa fa-eye"
            style={{
              fontSize: "20px",
              color: "green",
              backgroundColor: "#fff",
              border: "none",
            }}
            title="View"
            onClick={() => handleViewUser(row._id)}
          ></button>
        }

        {
          <button
            className="fa fa-pen"
            style={{
              fontSize: "20px",
              color: "black",
              backgroundColor: "#fff",
              border: "none",
            }}
            title="edit"
            onClick={() => handleEdithandler(row)}
          ></button>
        }
        {
          <button
            className="fa fa-trash-alt"
            style={{
              fontSize: "20px",
              color: "red",
              backgroundColor: "#fff",
              border: "none",
            }}
            title="delete"
            onClick={() => deleteHandler(row._id)}
          ></button>
        }
      </>
    ),
  },
 
];
export default columns;
