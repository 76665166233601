import React, { useState } from "react";
import { Modal } from "react-bootstrap";

import FormikForm from "components/shared/FormikForm";
import Validation from "views/marchant/Validation";
import InputFields from "views/marchant/inputFields";
import { DEFAULT_COUNTY_CODE } from "helpers/common";

export default function AddMarchant({
  show,
  handleClose,
  handleConfirm,
  title,
  content,
  loading,
  initialValues,
}) {
  const [selectedCountry, setSelectedCountry] = useState(DEFAULT_COUNTY_CODE);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
      size="lg"
    >
      <Modal.Header className="py-3 text-dark" closeButton>
        <Modal.Title className="fw-bold text-dark">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormikForm
          initialValues={initialValues}
          validationSchema={Validation}
          onSubmit={handleConfirm}
          loading={loading}
          inputFields={InputFields}
          buttonText={"Submit"}
          formSize="md"
          col={6}
          selectedCountry={selectedCountry}
        />
      </Modal.Body>
    </Modal>
  );
}
