import { createAsyncThunk } from "@reduxjs/toolkit";

import * as URL from "helpers/url_helper";
import { get, post, put, del } from "helpers/api_helper";

export const getLanguageList = createAsyncThunk(
  "getLanguageList",
  async ({ data, callback }, Thunk) => {
    try {
      let response = await post(URL.GET_LANGUAGE_LIST, data);
      callback && callback(null, response.data);
      return response;
    } 
    catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);

export const addLanguage = createAsyncThunk(
  "AddLanguage",
  async ({ data, callback }, Thunk) => {
    try {
      let response = await post(URL.ADD_LANGUAGE, data);
      callback && callback(null, response);
      return response;
    } catch (error) {
      callback && callback(error.message, null);
      return Thunk.rejectWithValue(error);
    }
  }
);

export const editLanguage = createAsyncThunk(
  "editLanguage",
  async ({ data, callback }, Thunk) => {
    try {
      let response = await put(URL.EDIT_LANGUAGE + data?._id, data);
      callback && callback(null, response);
      return response;
    } catch (error) {
      callback && callback(error.message, null);
      return Thunk.rejectWithValue(error);
    }
  }
);

export const deleteLanguage = createAsyncThunk(
  "deleteLanguage",
  async ({ data, callback }, Thunk) => {
    try {
      let response = await del(URL.DELETE_LANGUAGE + data?._id, data);
      callback && callback(null, response);
      return response;
    } catch (error) {
      callback && callback(error.message, null);
      return Thunk.rejectWithValue(error);
    }
  }
);

export const getLanguagesCode = createAsyncThunk(
  "getLanguagesCode",
  async ({ data, callback }, Thunk) => {
    try {
      let response = await get(URL.GET_LANGUAGE_CODES, data);
      callback && callback(null, response);
      return response;
    } catch (error) {
      callback && callback(error.message, null);
      return Thunk.rejectWithValue(error);
    }
  }
);
