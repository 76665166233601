import * as Yup from 'yup';

const Validation = Yup.object({
    level: Yup.string().required("Please enter level!"),
    // numbers: Yup.string().required("Please enter numbers!"),
    isReviewLevel: Yup.boolean().required("Please select Review level"),
    passCount: Yup.number().required("Please enter count!"),
    timeInSec: Yup.number().required("Please enter time!"),
    starsAwarded: Yup.number().required("Please enter stars count!"),
});

export default Validation;
