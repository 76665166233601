import * as Yup from "yup";

const Validation = Yup.object().shape({
  count: Yup.number().required("Count is required"),
  price: Yup.number().required("Price is required"),
  currency: Yup.string().required("Currency is required"),
  discount: Yup.number()
    .required("Discount is required")
    .min(0, "Discount must be at least 0")
    .max(99, "Discount cannot exceed 99"),
});

export default Validation;
