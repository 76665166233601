import { createSlice, current } from "@reduxjs/toolkit";
import * as Act from "./actions";

const initialState = {
  loading: false,
  marchant: [],
  totalCount: 0,
};

const slice = createSlice({
  name: "marchant",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    // view user relative
    builder.addCase(Act.MARCHANTLIST.pending, (state, action) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(Act.MARCHANTLIST.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
      marchant: payload.data,
      totalCount: payload.totalcount,
    }));
    builder.addCase(Act.MARCHANTLIST.rejected, (state, action) => ({
      ...state,
      loading: false,
      marchant: null,
    }));
    //marchant qr list
    builder.addCase(Act.MARCHANQRTLIST.pending, (state, action) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(Act.MARCHANQRTLIST.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
      marchant: payload.data,
      totalCount: payload.totalcount,
    }));
    builder.addCase(Act.MARCHANQRTLIST.rejected, (state, action) => ({
      ...state,
      loading: false,
      marchant: null,
    }));
    // payments details
    builder.addCase(Act.MarchantAdd.pending, (state, action) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(Act.MarchantAdd.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
      marchant: payload,
    }));
    builder.addCase(Act.MarchantAdd.rejected, (state, action) => ({
      ...state,
      loading: false,
      marchant: null,
    }));
    //   // delete Qr price
    builder.addCase(Act.deleteMarchant.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(Act.deleteMarchant.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(Act.deleteMarchant.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || action.error;
    });
    // marchant delete qr
    builder.addCase(Act.deleteMarchantQr.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(Act.deleteMarchantQr.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(Act.deleteMarchantQr.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || action.error;
    });

    //   deleteMarchantQr  // edit
    builder.addCase(Act.MarchantEdit.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(Act.MarchantEdit.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(Act.MarchantEdit.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || action.error;
    });

    // marchant view deatils
    builder.addCase(Act.MerchantView.pending, (state, action) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(Act.MerchantView.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
      marchant: payload,
    }));
    builder.addCase(Act.MerchantView.rejected, (state, action) => ({
      ...state,
      loading: false,
      marchant: null,
    }));

    // marchant qr add
    builder.addCase(Act.MarchantQRAdd.pending, (state, action) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(Act.MarchantQRAdd.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
      marchant: payload,
    }));
    builder.addCase(Act.MarchantQRAdd.rejected, (state, action) => ({
      ...state,
      loading: false,
      marchant: null,
    }));
  },
});

export default slice;
