import React from "react";

import Badge from "react-bootstrap/Badge";

import { DATE_FORMAT } from "helpers/common";

const STATUS = {
  active: "Active",
  inactive: "Inactive",
  linked: "linked",
  pending: "Pending",
  rejected: "Rejected",
  completed: "Completed",
  created: "Created",
  processing: "Processing",
};

const columns = ({ handleViewUser }) => [
  {
    dataField: "qrCount",
    text: "Qr Price Plate",
    formatter: (_, row) => (
      <span className="text-capitalize">
        {row.qrCount + " QR Plate" || "---"}
      </span>
    ),
  },
  {
    dataField: "userDetails.email",
    text: "UserEmail",
  },
  {
    dataField: "userDetails.username",
    text: "Username",
  },
  {
    dataField: "amount",
    text: "Amount",
    formatter: (_, row) => (
      <span className="text-capitalize">
        {row?.amount ? row.currencySymbol + " " + row.amount : "---"}
      </span>
    ),
  },
  {
    dataField: "status",
    text: "Payment Status",
    formatter: (cellContent, row) => (
      <Badge
        className={`text-capitalize font-size-13 badge-soft-${
          row.status === "pending" ? "warning" : "success"
        }`}
      >
        {STATUS[row.status]}
      </Badge>
    ),
  },
  {
    dataField: "shippingStatus",
    text: "Shiping Status",
    formatter: (cellContent, row) => (
      <Badge
        className={`text-capitalize font-size-13 badge-soft-${
          row.status === "pending" ? "warning" : "success"
        }`}
      >
        {row.status === "rejected" ? "" : STATUS[row.shippingStatus]}
      </Badge>
    ),
  },
  {
    text: "Created At",
    dataField: "createdAt",
    formatter: (_, row) => DATE_FORMAT(row.createdAt),
  },
  {
    text: "Action",
    dataField: "",
    formatter: (_, row) => (
      <>
        {
          <button
            className="fa fa-eye"
            style={{
              fontSize: "20px",
              color: "green",
              backgroundColor: "#fff",
              border: "none",
            }}
            title="View"
            onClick={() => handleViewUser(row._id)}
          ></button>
        }
      </>
    ),
  },
];
export default columns;
