import { DATE_FORMAT } from "helpers/common";
import Badge from "react-bootstrap/Badge";
import React from "react";
import { Link } from "react-router-dom";

const name = {
  "ABOUT_US": "ABOUT US",
  "PRIVACY_POLICY": "PRIVACY POLICY",
  "TERMS_CONDITIONS": "TERMS & CONDITIONS",
  "CONTACT_US": "CONTACT US"
}
// export const selectRow = (props) => ({
//   mode: "checkbox",
//   clickToSelect: true,
//   selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
//     <div className="custom-control custom-checkbox">
//       <input
//         type="checkbox"
//         className="custom-control-input"
//         ref={(input) => {
//           if (input) input.indeterminate = indeterminate
//         }}
//         {...rest}
//       />
//       <label className="custom-control-label">&nbsp;</label>
//     </div>
//   ),
//   selectionRenderer: ({ mode, rowKey, ...rest }) => (
//     <div className="custom-control custom-checkbox" key={rowKey}>
//       <input type="checkbox" className="custom-control-input" {...rest} />
//       <label className="custom-control-label">&nbsp;</label>
//     </div>
//   ),
//   ...props,
// })

const Columns = ({ deleteHandler, handleEdit }) => [
  {
    dataField: "name",
    text: "Name",
    formatter: (_, row) => (
      <span className="text-capitalize">{name[row.name]}</span>
    ),
  },
  {
    dataField: "language",
    text: "Language",
    formatter: (_, row) => (
      <span className="text-capitalize">{row?.languageDetails?.languageName || "--"}</span>
    ),
  },

  {
    text: "Status",
    dataField: "status",
    formatter: (cellContent, row) => (
      <Badge
        className={
          `text-capitalize font-size-13 badge-soft-${row.status}`
        }
        color={row.badgeClass}
      >
        {row.status}
      </Badge>
    ),
  },
  {
    text: "Created At",
    dataField: "dateCreated",
    formatter: (_, row) => DATE_FORMAT(row.dateCreated),
  },
  {
    text: "Action",

    formatter: (_, row) => (
      <>
        <button
          className="fas fa-pen"
          title="Edit"
          style={{
            backgroundColor: "#fff",
            border: "none",
          }}
          onClick={() => handleEdit(row)}
        ></button>

        {
          <button
            className="fas fa-trash-alt"
            style={{
              backgroundColor: "#fff",
              border: "none",
            }}
            title="Inactive"
            onClick={() => deleteHandler(row._id)}
          ></button>
        }
      </>
    ),
  },
];
export default Columns;
