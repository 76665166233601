const inputFields = [
  {
    name: "username",
    type: "text",
    label: "User Name",
    placeholder: "User Name...",
  },

  {
    name: "email",
    type: "text",
    label: "Email*",
    placeholder: "Email...",
    disabled: true,
  },
  // {
  //   name: "mobileNumber",
  //   type: "phone",
  //   label: "Mobile Number*",
  //   placeholder: "Mobile Number...",
  // },
  {
    name: "password",
    type: "password",
    label: "Password*",
    placeholder: "Password...",
    disabled: true,
  },
  // {
  //   name: "profileImage",
  //   type: "file",
  //   label: "Profile Image",
  //   placeholder:"Profile Image.",
    
  // },
  
];

export default inputFields;
