
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FadeLoader from "react-spinners/FadeLoader";
import { QRHISTORY } from "common/viewContent";
import Helmet from "react-helmet";
import * as Path from "routes/Path/index";
import Table from "components/Table";
import { toast } from "react-toastify";
import Columns from "./Columns";
import { QRLINKEDHISTORY } from "store/qrhistory/actions";
const QrLinkedHistory = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, totalCount } = useSelector((state) => state?.marchants);
  const [columns, setColumns] = useState([]);
  const [marchant, setMarchant] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState({
    limit: 10,
    order: 1,
    pageNo: 1,
    orderBy: "createdAt",
    search: "",
    status: "linked",
  });

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    countryCode: "995",
    mobileNumber: "",
    address: "",
    contactPersonName: "",
    comments: "",
  });
  const fetchData = async (filter) => {
    try {
      const callback = (err, res) => {
        if (err) {
          toast.error(err);
        } else {
          console.log(res);
          setMarchant(res);
          //   toast.success(res.message);
        }
      };
      dispatch(QRLINKEDHISTORY({ data: filter, callback }));
    } catch (error) {
      console.error(error, "<===err");
    }
  };
  useEffect(() => {
    fetchData(filter);
    setColumns(
      Columns({
        handleEdithandler,
        handleViewUser,
      })
    );
  }, [filter]);

  const handleEdithandler = (info) => {
    setFormData({ ...formData, ...info });
    setEditModalShow(true);
  };

  const onClickFilter = (e) => {
    e?.preventDefault();
    setFilter({
      ...filter,
      pageNo: 1,
      limit: pageSize,
      search: search,
    });
  };
  const clearFilter = () => {
    setFilter((prev) => ({
      ...prev,
      search: "",
    }));

    setSearch("");
  };
  const handlePageChange = (pageNumber) => {
    // setFilter((prev)=>({...prev, pageNo : pageNumber}));
    // setCurrentPage(pageNumber);
  };
  const handleViewUser = (id) => {
    navigate(`${id}`);
  };
  console.log(
    marchant?.totalcount,
    "marchgant================================="
  );
  console.log(loading, "loding---");
  return (
    <>
      {/* {!marchant?.data?.length > 0 && (
        <div className="main-loader">
          <FadeLoader size={1000} />
        </div>
      )} */}
      <Helmet title={QRHISTORY?.CURRENT_MODULE} />
      <section className="section">
        <div className="section-header">
          <h1>{QRHISTORY?.CURRENT_MODULE}</h1>
          <div className="section-header-breadcrumb">
            <div className="breadcrumb-item active">
              <Link to={"/admin/" + Path.dashboard}>Dashboard</Link>
            </div>
            <div className="breadcrumb-item">{QRHISTORY?.CURRENT_MODULE} </div>
          </div>
        </div>
        <div className="section-body">
          <div className="card">
            <div className="row justify-content-between">
              <div className="col-6">
                <div className="card-header">
                  <h2 className="section-title">{QRHISTORY?.TOP_CONTENT}</h2>
                </div>
              </div>
              {/* <div className="col-6">
                <div className="card-header d-flex justify-content-end align-items-center">
                  <button
                    className="btn btn-primary"
                    onClick={() => setAddModalShow(true)}
                  >
                    Add Merchant
                  </button>
                </div>
              </div> */}
            </div>
            <div className="col-12">
              <form onSubmit={onClickFilter}>
                <div className="card-header">
                  <div className="col-lg-4 col-sm-12 col-md-12 my-2">
                    <input
                      className="form-control"
                      name="search"
                      onChange={(e) => setSearch(e.target.value)}
                      value={search}
                      placeholder="Search by Qr code id"
                    />
                  </div>
                  {/* <div className="col-lg-4 col-sm-12 col-md-12 my-2">
                    <input
                      className="form-control"
                      name="searchByQr"
                      onChange={(e) => setSearchByQr(e.target.value)}
                      value={searchByQr}
                      placeholder="Search by Qrcode "
                    />
                  </div> */}
                  {/* <div className="col-lg-4 col-sm-12 col-md-12 my-2">
                    <Select
                      className="form-group"
                      name={"status"}
                      options={useStatusOptions} 
                      onChange={(selectedOption) => {
                        setStatus(selectedOption.value);
                      }}
                      placeholder={"Select status"}
                      // value={values[field.name]}
                      value={useStatusOptions.find(
                        (option) => option.value === status
                      )} // Find the
                    />
                  </div> */}
                </div>
                <div className="col-12 my-2">
                  <div className="d-flex gap-4">
                
                    <button type="submit" className="btn btn-primary btn-lg">
                      Search
                    </button>
                    <button
                      onClick={() => clearFilter()}
                      type="btn"
                      className="btn btn-primary btn-lg"
                    >
                      Clear filter
                    </button>
                  </div>
                </div>
              </form>
            </div>
            {/* <div className="col-12">
              <div className="card-header">
                <FormikForm
                  initialValues={initialValuesForFilter}
                  onSubmit={handleFilterForm}
                  inputFields={InputFieldsForSearch}
                  buttonText={"Search"}
                />
              </div>
            </div> */}
          </div>
          {!loading && marchant?.data?.length > 0 && (
            <Table
              data={marchant?.data}
              columns={columns}
              setFilter={setFilter}
              totalCount={marchant?.totalcount}
              handleTableChange={handlePageChange}
              filter={filter}
            />
          )}
          {!loading && !marchant?.data?.length && (
            <p style={{ textAlign: "center" }}>No data Found</p>
          )}
        </div>
      </section>
    </>
  );
};
export default QrLinkedHistory;
