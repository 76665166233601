import { DATE_FORMAT } from "helpers/common";
import Badge from "react-bootstrap/Badge";
import React from "react";
import { Link } from "react-router-dom";


const Columns = ({ deleteHandler, handleEdit }) => [
  {
    dataField: "title",
    text: "Title",
    formatter: (_, row) => (
      <span className="text-capitalize">{row.question}</span>
    ),
  },
  {
    dataField: "language",
    text: "Language",
    formatter: (_, row) => (
      <span className="text-capitalize">{row?.languageDetails?.languageName || "--"}</span>
    ),
  },

  {
    text: "Status",
    dataField: "status",
    formatter: (cellContent, row) => (
      <Badge
        className={
          `text-capitalize font-size-13 badge-soft-${row.status}`
        }
        color={row.badgeClass}
      >
        {row.status}
      </Badge>
    ),
  },
  {
    text: "Created At",
    dataField: "dateCreated",
    formatter: (_, row) => DATE_FORMAT(row.dateCreated),
  },
  {
    text: "Action",
    // dataField: "Inactive",
    formatter: (_, row) => (
      <>
        <button
          className="fas fa-pen"
          title="Edit"
          style={{
            backgroundColor: "#fff",
            border: "none",
          }}
          onClick={() => handleEdit(row)}
        ></button>
        {/* {!row.Inactive ? (
          <button
            className="fas fa-toggle-on"
            style={{
              fontSize: "20px",
              color: "green",
              backgroundColor: "#fff",
              border: "none",
            }}
            title="Active"
            onClick={() => handleStatus(row._id, true)}
          ></button>
        ) : (
          <button
            className="fas fa-toggle-off"
            style={{
              fontSize: "20px",
              color: "red", 
              backgroundColor: "#fff",
              border: "none",
            }}
            title="Inactive"
            onClick={() => handleStatus(row._id, false)}
          ></button>
        )} */}
        {
          <button
            className="fas fa-trash-alt"
            style={{
              backgroundColor: "#fff",
              border: "none",
            }}
            title="Inactive"
            onClick={() => deleteHandler(row._id)}
          ></button>
        }
      </>
    ),
  },
];
export default Columns;
