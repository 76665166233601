import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import * as adminPath from "routes/Path/index"

const Header = () => {
  const navigate = useNavigate();
  const { user, authToken } = useSelector((state) => state.Auth)

  const [isOpen, setIsOpen] = useState(false)
  useEffect(() => {
    document.body.classList.toggle("sidebar-mini", isOpen)
    if (document.body.classList.contains("sidebar-gone")) {
      document.body.classList.remove("sidebar-gone")
      document.body.classList.remove("sidebar-mini")
      document.body.classList.add("sidebar-show", isOpen)
    }
  }, [isOpen])

  //logout function
  const handleLogout = async () => {
    try {
      localStorage.clear()
      toast.success("Logout Succesfully")
      navigate("/login");
    } catch (err) {
      console.log(err)

      if (err && err.data && err.data.message) {
        localStorage.clear()
        toast.error(err.data.message)
      }
    }
  }

  return (
    <>
      <div className="navbar-bg"></div>
      <nav
        className="navbar navbar-expand-lg main-navbar w-100"
        style={{
          zIndex: "999",
          position: "sticky",
          top: "0",
          background: "#f4f6f9",
        }}
      >
        <div className="d-flex align-items-center justify-content-between gap-10 w-100">
          <form className="form-inline">
            <ul className="navbar-nav mr-3">
              <li>
                <Link
                  to="#"
                  data-toggle="sidebar"
                  onClick={() => setIsOpen(!isOpen)}
                  className="nav-link nav-link-lg"
                >
                  <i className="fas fa-bars"></i>
                </Link>
              </li>
            </ul>
          </form>
          <ul className="navbar-nav navbar-right">
            <li className="dropdown">
              <Link
                to="#"
                data-toggle="dropdown"
                className="nav-link dropdown-toggle nav-link-lg nav-link-user"
              >
                {user?.profile_image ? (
                  <img
                    alt="image"
                    src={user?.profile_image}
                    className="rounded-circle mr-1 img_list_round-proifle"
                  />
                ) : (
                  <figure
                    className="avatar mr-2 avatar-sm bg-success text-white userprofile_img"
                    data-initial={
                      user?.first_name ? user?.first_name.charAt(0) : "A"
                    }
                  ></figure>
                )}
                <div className="d-sm-none d-lg-inline-block">
                  {" "}
                  {user?.first_name}
                </div>
              </Link>
              <div className="dropdown-menu dropdown-menu-right">
                <Link className="dropdown-item has-icon" to={adminPath.profile}>
                  <i className="far fa-user"></i> Profile
                </Link>
                <Link
                  className="dropdown-item has-icon"
                  to={adminPath.changePassword}
                >
                  <i className="fa fa-lock"></i> Change Password
                </Link>
                <div className="dropdown-divider"></div>
                <span
                  className="dropdown-item has-icon text-danger logout-span"
                  onClick={handleLogout}
                >
                  <i className="fas fa-sign-out-alt"></i>{" "}
                  <span className="logout-span">Logout</span>
                </span>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Header;
