import React from "react";
import { Button, Modal, Form } from "react-bootstrap";
import LoadingButton from "components/shared/LoadingButton";

export default function ConfirmModal({
  show,
  handleClose,
  handleConfirm,
  title,
  content,
  loading,
}) {
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        size="md"
      >
        <Form onSubmit={handleConfirm}>
          <Modal.Header className="py-3 text-dark" closeButton>
            <Modal.Title className="fw-bold text-dark">{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="align-content-center ">
            {content}
          </Modal.Body>
          <Modal.Footer className="align-content-center ">
            <Button
              variant="secondary"
              onClick={handleClose}
              className={" btn-lg mt-3"}
            >
              Close
            </Button>

            <LoadingButton
              type="submit"
              loading={loading}
              className={"btn btn-primary btn-lg mt-3"}
            >
              Confirm
            </LoadingButton>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
