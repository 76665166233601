const inputFields = [
  {
    name: 'name',
    type: 'text',
    label: ' Name*',
    placeholder: ' Name',
  },

  {
    name: 'email',
    type: 'email',
    label: 'Email*',
    placeholder: 'Email',
  },

  //   {
  //     name: 'profile_image',
  //     type: 'image',
  //     label: 'Image*',
  //     placeholder: '',
  //     allowType: ["image/jpeg", "image/png", "image/jpg",],
  //     allowTypeError: "JPEG, PNG, JPG"
  //   },
];

export default inputFields;