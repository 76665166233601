// import moment from "moment";
// import { toast } from "react-toastify";
// import { utils } from "ethers";

// export const STATUS_BADGE = {
//   active: "success",
//   inactive: "warning",
//   win: "success",
//   loss: "danger",
//   cancel: "primary",
//   nan: "warning",
//   credit: "success",
//   debit: "danger",
//   completed: "success",
// };


// export const GAME_STATUS = {
//   win: "Won",
//   loss: "Loss",
//   cancel: "Refunded",
//   nan: "Pending",
//   completed: "Completed",
//   archived: "No Investment",
// };

// export function custmToFixed(x) {
//   if (Math.abs(x) < 1.0) {
//     var e = parseInt(x.toString().split("e-")[1]);
//     if (e) {
//       x *= Math.pow(10, e - 1);
//       x = "0." + new Array(e).join("0") + x.toString().substring(2);
//     }
//   } else {
//     var e = parseInt(x.toString().split("+")[1]);
//     if (e > 20) {
//       e -= 20;
//       x /= Math.pow(10, e);
//       x += new Array(e + 1).join("0");
//     }
//   }
//   return x;
// }

// export const NumberDigits = (number = 0, digits = 8) => {
//   return Number(Number(number).toFixed(18).toString().slice(0, digits));
// };

// export const FORMAT_SECONDS = (seconds) => {
//   const duration = moment.duration(seconds, "seconds");
//   const times = {
//     years: duration.years(),
//     months: duration.months(),
//     days: duration.days(),
//     hours: duration.hours(),
//     minutes: duration.minutes(),
//     seconds: duration.seconds(),
//   };

//   if (times.years && times.years <= 1) return `${times.years} Year`;
//   if (times.years) return `${times.years} Years`;

//   if (times.months && times.months <= 1) return `${times.months} Month`;
//   if (times.months) return `${times.months} Months`;

//   if (times.days && times.days <= 1) return `${times.days} Day`;
//   if (times.days) return `${times.days} Days`;

//   if (times.hours && times.hours <= 1) return `${times.hours} Hour`;
//   if (times.hours) return `${times.hours} Hours`;

//   if (times.minutes && times.minutes <= 1) return `${times.minutes} Minute`;
//   if (times.minutes) return `${times.minutes} Minutes`;

//   return "";
// };
// export const convertSecondToMinuts = (time) => {
//   const minutes = Math.floor(time / 60);
//   return minutes;
// };

// export const copyClipBoard = (value) => {
//   if (!value) return toast.error("Something wrong!");

//   let textarea = document.createElement("textarea");
//   textarea.textContent = value;
//   textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in Microsoft Edge.
//   document.body.appendChild(textarea);
//   textarea.select();
//   try {
//     document.execCommand("copy"); // Security exception may be thrown by some browsers.
//   } catch (ex) {
//     console.warn("Copy to clipboard failed.", ex);
//     // return prompt("Copy to clipboard: Ctrl+C, Enter", text);
//   } finally {
//     document.body.removeChild(textarea);
//   }

//   toast.success("Copied!");
// };

// export let makeUnixTimestamp = (time) => {
//   try {
//     const date = time ? new Date(time) : new Date();

//     const unixTimestamp = Math.floor(date.getTime() / 1000);
//     return unixTimestamp;
//   } catch (error) {
//     console.log(error, "<===error");
//     return null;
//   }
// };

// export let makeTimestampToDate = (time, format = "YYYY-MM-DDTHH:mm") => {
//   try {
//     // console.log(time, "<===time");
//     if (!time) return null;
//     let date = moment.unix(time).format(format);
//     return date;
//   } catch (error) {
//     console.log(error, "<===error");
//     return null;
//   }
// };

// export const getContractAddress = (type, settings) => {
//   console.log(type, settings, "<===type, settings");
//   if (settings.ICOContract.contractAddress.length === 0) {
//     return null;
//   }

//   let contractAddress = settings.ICOContract.contractAddress.filter(
//     (ele) => ele.type === type
//   );
//   if (contractAddress.length === 0) {
//     return null;
//   }

//   return contractAddress[0][settings.blockchainNetworkMode];
// };

// export const getNetworkUrl = (type, settings) => {
//   // console.log(type, settings, "<=====type, settings")
//   if (settings.blockchain.length === 0) {
//     return null;
//   }

//   let blockchain = settings.blockchain.filter((ele) => {
//     return ele.type === type;
//   });

//   if (blockchain.length === 0) {
//     return null;
//   }

//   blockchain = blockchain[0];

//   if (blockchain.networkUrl.length === 0) {
//     // CONFIGURE_SETTING_FIRST
//     return null;
//   }

//   let blockchainNetworkUrl = blockchain.networkUrl.filter((ele) => {
//     return ele.type === settings.blockchainNetworkMode;
//   });

//   blockchainNetworkUrl = blockchainNetworkUrl[0];

//   if (!blockchainNetworkUrl.url) {
//     // CONFIGURE_SETTING_FIRST
//     return null;
//   }

//   return blockchainNetworkUrl;
// };

// export const getNetworks = (settings) => {
//   if (!settings.blockchain.length) {
//     return null;
//   }
//   let blockchainNetworkUrl = null;

//   let getNetworks = settings.blockchain.map((d) => {
//     blockchainNetworkUrl = d?.networkUrl?.find(
//       (ele) => ele.type === settings.blockchainNetworkMode
//     );
//     return {
//       blockchainType: d.type,
//       ...d,
//       ...blockchainNetworkUrl,
//     };
//   });

//   return getNetworks;
// };

// export const saleType = {
//   0: "null",
//   1: "preSale",
//   2: "privateSale",
//   3: "publicSale",
// };

// export const saleStatusType = {
//   0: "null",
//   1: "PreSale",
//   2: "PrivateSale",
//   3: "PublicSale",
// };

// export const endSaleType = {
//   0: "none",
//   1: "pre",
//   2: "private",
//   3: "public",
// };

// export const saleTypeLabel = {
//   none: "Pre Sale",
//   pre: "Private Sale",
//   private: "Public Sale",
// };

// export function isValidAddress(address) {
//   return utils.isAddress(address);
// }

// export const getContractDetails = (
//   type,
//   settings,
//   contractType,
//   walletType = "Metamask"
// ) => {
//   const _settings = settings[contractType].find(
//     (setting) => setting.type == WALLET_TYPE[walletType]
//   );

//   // console.log(settings,_settings,WALLET_TYPE[walletType], "<=====_settings")
//   if (!_settings) return null;

//   if (_settings && _settings.contractAddress.length === 0) {
//     return null;
//   }

//   let contractAddress = _settings.contractAddress?.filter((ele) => {
//     return ele.type === type;
//   });

//   if (contractAddress.length === 0) {
//     return null;
//   }

//   contractAddress = contractAddress[0];

//   if (!contractAddress[settings.blockchainNetworkMode]) {
//     // CONFIGURE_SETTING_FIRST
//     return null;
//   }

//   return {
//     usdtContract: _settings.usdtContract,
//     abi: contractAddress[settings.blockchainNetworkMode].abi,
//     contractAddress: contractAddress[settings.blockchainNetworkMode].address,
//   };
// };

// export const hexAddressToBase58 = (hexAddress) => {
//   const HEX_PREFIX = "41";
//   let retval = hexAddress;
//   try {
//     let tronWeb = window.tronWeb;
//     if (hexAddress.startsWith("0x")) {
//       hexAddress = HEX_PREFIX + hexAddress.substring(2);
//     }
//     let bArr = tronWeb.utils["code"].hexStr2byteArray(hexAddress);
//     retval = tronWeb.utils["crypto"].getBase58CheckAddress(bArr);
//   } catch (e) {
//     //Handle
//   }
//   return retval;
// };

// export const convertUsdToIcb = (value) => {
//   if (!value) return null;
//   value = Number(value);
//   let icb = Number(value * 10 ** 7);
//   return icb;
// };

// export const convertIcbToUsd = (value) => {
//   if (!value) return null;
//   value = Number(value);
//   let usd = Number(value / 10 ** 7);
//   return usd;
// };
// export const customSortCaret = (order, column) => {
//   if (!order) return (<span>&nbsp;&nbsp;↕</span>); // Default sort icon (none)
//   if (order === 'asc') return (<span>&nbsp;&nbsp;↑</span>); // Ascending sort icon
//   if (order === 'desc') return (<span>&nbsp;&nbsp;↓</span>); // Descending sort icon
//   return null;
// };

import moment from "moment";
import { toast } from "react-toastify";

export const STATUS_BADGE = {
  active: "success",
  inactive: "warning",
  win: "success",
  loss: "danger",
  cancel: "primary",
  nan: "warning",
  credit: "success",
  debit: "danger",
  completed: "success",
};


export const GAME_STATUS = {
  win: "Won",
  loss: "Loss",
  cancel: "Refunded",
  nan: "Pending",
  completed: "Completed",
  archived: "No Investment",
};

export function custmToFixed(x) {
  if (Math.abs(x) < 1.0) {
    var e = parseInt(x.toString().split("e-")[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = "0." + new Array(e).join("0") + x.toString().substring(2);
    }
  } else {
    var e = parseInt(x.toString().split("+")[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += new Array(e + 1).join("0");
    }
  }
  return x;
}

export const NumberDigits = (number = 0, digits = 8) => {
  return Number(Number(number).toFixed(18).toString().slice(0, digits));
};

export const FORMAT_SECONDS = (seconds) => {
  const duration = moment.duration(seconds, "seconds");
  const times = {
    years: duration.years(),
    months: duration.months(),
    days: duration.days(),
    hours: duration.hours(),
    minutes: duration.minutes(),
    seconds: duration.seconds(),
  };

  if (times.years && times.years <= 1) return `${times.years} Year`;
  if (times.years) return `${times.years} Years`;

  if (times.months && times.months <= 1) return `${times.months} Month`;
  if (times.months) return `${times.months} Months`;

  if (times.days && times.days <= 1) return `${times.days} Day`;
  if (times.days) return `${times.days} Days`;

  if (times.hours && times.hours <= 1) return `${times.hours} Hour`;
  if (times.hours) return `${times.hours} Hours`;

  if (times.minutes && times.minutes <= 1) return `${times.minutes} Minute`;
  if (times.minutes) return `${times.minutes} Minutes`;

  return "";
};
export const convertSecondToMinuts = (time) => {
  const minutes = Math.floor(time / 60);
  return minutes;
};

export const copyClipBoard = (value) => {
  if (!value) return toast.error("Something wrong!");

  let textarea = document.createElement("textarea");
  textarea.textContent = value;
  textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in Microsoft Edge.
  document.body.appendChild(textarea);
  textarea.select();
  try {
    document.execCommand("copy"); // Security exception may be thrown by some browsers.
  } catch (ex) {
    console.warn("Copy to clipboard failed.", ex);
    // return prompt("Copy to clipboard: Ctrl+C, Enter", text);
  } finally {
    document.body.removeChild(textarea);
  }

  toast.success("Copied!");
};

export let makeUnixTimestamp = (time) => {
  try {
    const date = time ? new Date(time) : new Date();

    const unixTimestamp = Math.floor(date.getTime() / 1000);
    return unixTimestamp;
  } catch (error) {
    console.log(error, "<===error");
    return null;
  }
};

export let makeTimestampToDate = (time, format = "YYYY-MM-DDTHH:mm") => {
  try {
    // console.log(time, "<===time");
    if (!time) return null;
    let date = moment.unix(time).format(format);
    return date;
  } catch (error) {
    console.log(error, "<===error");
    return null;
  }
};

export const getContractAddress = (type, settings) => {
  console.log(type, settings, "<===type, settings");
  if (settings.ICOContract.contractAddress.length === 0) {
    return null;
  }

  let contractAddress = settings.ICOContract.contractAddress.filter(
    (ele) => ele.type === type
  );
  if (contractAddress.length === 0) {
    return null;
  }

  return contractAddress[0][settings.blockchainNetworkMode];
};

export const getNetworkUrl = (type, settings) => {
  // console.log(type, settings, "<=====type, settings")
  if (settings.blockchain.length === 0) {
    return null;
  }

  let blockchain = settings.blockchain.filter((ele) => {
    return ele.type === type;
  });

  if (blockchain.length === 0) {
    return null;
  }

  blockchain = blockchain[0];

  if (blockchain.networkUrl.length === 0) {
    // CONFIGURE_SETTING_FIRST
    return null;
  }

  let blockchainNetworkUrl = blockchain.networkUrl.filter((ele) => {
    return ele.type === settings.blockchainNetworkMode;
  });

  blockchainNetworkUrl = blockchainNetworkUrl[0];

  if (!blockchainNetworkUrl.url) {
    // CONFIGURE_SETTING_FIRST
    return null;
  }

  return blockchainNetworkUrl;
};

export const getNetworks = (settings) => {
  if (!settings.blockchain.length) {
    return null;
  }
  let blockchainNetworkUrl = null;

  let getNetworks = settings.blockchain.map((d) => {
    blockchainNetworkUrl = d?.networkUrl?.find(
      (ele) => ele.type === settings.blockchainNetworkMode
    );
    return {
      blockchainType: d.type,
      ...d,
      ...blockchainNetworkUrl,
    };
  });

  return getNetworks;
};

export const saleType = {
  0: "null",
  1: "preSale",
  2: "privateSale",
  3: "publicSale",
};

export const saleStatusType = {
  0: "null",
  1: "PreSale",
  2: "PrivateSale",
  3: "PublicSale",
};

export const endSaleType = {
  0: "none",
  1: "pre",
  2: "private",
  3: "public",
};

export const saleTypeLabel = {
  none: "Pre Sale",
  pre: "Private Sale",
  private: "Public Sale",
};


export const getContractDetails = (
  type,
  settings,
  contractType,
  walletType = "Metamask"
) => {
  const _settings = settings[contractType].find(
    (setting) => setting.type == WALLET_TYPE[walletType]
  );

  // console.log(settings,_settings,WALLET_TYPE[walletType], "<=====_settings")
  if (!_settings) return null;

  if (_settings && _settings.contractAddress.length === 0) {
    return null;
  }

  let contractAddress = _settings.contractAddress?.filter((ele) => {
    return ele.type === type;
  });

  if (contractAddress.length === 0) {
    return null;
  }

  contractAddress = contractAddress[0];

  if (!contractAddress[settings.blockchainNetworkMode]) {
    // CONFIGURE_SETTING_FIRST
    return null;
  }

  return {
    usdtContract: _settings.usdtContract,
    abi: contractAddress[settings.blockchainNetworkMode].abi,
    contractAddress: contractAddress[settings.blockchainNetworkMode].address,
  };
};


export const convertUsdToIcb = (value) => {
  if (!value) return null;
  value = Number(value);
  let icb = Number(value * 10 ** 7);
  return icb;
};

export const convertIcbToUsd = (value) => {
  if (!value) return null;
  value = Number(value);
  let usd = Number(value / 10 ** 7);
  return usd;
};

export const customSortCaret = (order, column) => {
  if (!order) return (<span>&nbsp;&nbsp;↕</span>); // Default sort icon (none)
  if (order === 'asc') return (<span>&nbsp;&nbsp;↑</span>); // Ascending sort icon
  if (order === 'desc') return (<span>&nbsp;&nbsp;↓</span>); // Descending sort icon
  return null;
};