export const LOGIN_USER = "/admin/login";
export const FORGET_PASSWORD = "/admin/forgotPassword";
export const UPDATE_ADMIN = "/admin/edit/";
export const VIEW_USER = "/admin/view";
export const RESET_PASSWORD = "/admin/changePassword/";

// File
export const POST_FILE = "/settings/uploadFile";
export const POST_REMOVE_FILE = "/settings/remove";

///Setting apis
export const GET_SETTINGS = "/settings/view";
export const UPDATE_SETTINGS = "/settings/update/";
//dashboard 
export const DASBOARDAPI="settings/dashboard"
//// user

export const GET_USERS = "/user/list";
export const profile = `profile`


export const ADD_USER = "/admin/user/add";
export const UPDATE_USER = "/user/edit/";
export const DELETE_PHOTO ="/photos/"
export const DELETE_VIDEO="/videos/"
export const ARCHIEV_RELATIVE="/user/archive/"





// relative 
export const USER_RELATIVE_PROFILE="user/profile/"
export const USER_RELATIVE="user/profile/list"
export const USER_PROFILE="/user/detail/";
export const UPDATE_USERPROFILE="user/profile/"
export const UPDATE_IMAGE="/photos/"
export const UPDATE_VIDEO="/videos/"
export const LINK_QR_TO_USERS="/qrcodes/"
export const Downloadlink="qrcodes/download/zip"
//qrcreate
export const CREATE_QR_CODE="/qrcodes/create"
export const QRCODE_LIST="/qrcodes/list"
export const QRPRICE_CREATE="qrPrices/create"
export const QRPRICE_LIST="qrPrices/list"
export const QRPRICE_LIST_DELETE="qrPrices/"
// payments list
export const PAYMENT_LISTING="payments/list"
export const PAYMENTDETAILS="payments/"
// export const PaymentStatus="payments/"


//levels
export const GET_LEVELS = "/level/list";
export const ADD_LEVEL = "/level/create";
export const UPDATE_LEVEL = "/level/edit";

//FAQ
export const GET_FAQ_LIST = "/admin/faq/list";
export const ADD_FAQ = "/admin/faq/add";
export const EDIT_FAQ = "/admin/faq/";
export const DELETE_FAQ = "/admin/faq/";

// pages
export const GET_PAGE_LIST = "/admin/pages/list";
export const ADD_PAGE = "/admin/pages/add";
export const EDIT_PAGE = "/admin/pages/";
export const DELETE_PAGE = "/admin/pages/";

//language
export const GET_LANGUAGE_LIST = "/admin/language";
export const ADD_LANGUAGE = "/admin/language/add";
export const EDIT_LANGUAGE = "/admin/language/";
export const DELETE_LANGUAGE = "/admin/language/";
export const GET_LANGUAGE_CODES = "/admin/language/codes";

//badges
export const GET_BADGE_LIST = "/admin/badge/list";
export const ADD_BADGE = "/admin/badge/add";
export const EDIT_BADGE = "/admin/badge/";
export const DELETE_BADGE = "/admin/badge/";

//avatar
export const GET_AVATAR_LIST = "/avatar/list";
export const ADD_AVATAR = "/avatar/add";
export const EDIT_AVATAR = "/avatar/";
export const DELETE_AVATAR = "/avatar/";

//avatar category
export const GET_AVATAR_CATEGORY_LIST = "/avatar/category/list";
export const ADD_AVATAR_CATEGORY = "/avatar/category/add";
export const EDIT_AVATAR_CATEGORY = "/avatar/category/";
export const DELETE_AVATAR_CATEGORY = "/avatar/category/";

//avatar characterstic
export const GET_AVATAR_CHARACTERSTIC_LIST = "/avatar/characterstic/list";
export const ADD_AVATAR_CHARACTERSTIC= "/avatar/characterstic/add";
export const EDIT_AVATAR_CHARACTERSTIC = "/avatar/characterstic/";
export const DELETE_AVATAR_CHARACTERSTIC = "/avatar/characterstic/";
// marchant list
export const MARCHANT_LIST="/merchants/list"
export const MARCHANT_EDIT="merchants/update/"
export const MARCHANT_Add="/merchants/add"
export const MARCHANT_DELETE="/merchants/remove/"
export const MARCHANT_QR_LIST="merchants/qrcode/list"
export const MARCHANTVIEWDETAILS="/merchants/"
export const MARCHANT_QR_ADD="merchants/qrcode/add"
export const MARCHANT_QR_DELETE="/merchants/qrcode/remove/"
// qr history
export const QR_HISTORY="/qrcodes/listWithDetails"
