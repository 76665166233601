import { createAsyncThunk } from "@reduxjs/toolkit";

import * as URL from "helpers/url_helper";

import { get, post, put, del } from "helpers/api_helper";

export const MARCHANTLIST = createAsyncThunk(
  "MARCHANTLIST",
  async ({ data, callback }, Thunk) => {
    try {
      let response = await post(URL.MARCHANT_LIST, data);
      callback && callback(null, response);
      return response;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);

//payment  details api calling
export const MarchantAdd = createAsyncThunk(
  "MarchantAdd",
  async ({ data, callback }, Thunk) => {
    try {
      let response = await post(URL.MARCHANT_Add ,data);
      callback && callback(null, response);
      return response;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);

// delete marchant
export const deleteMarchant = createAsyncThunk(
  "deleteMarchant",
  async ({ data, callback }, thunkAPI) => {
    console.log(data,"data++=++++++++++++++++++++")
    try {
      const response = await del(URL.MARCHANT_DELETE + data);
      console.log(data,"data----------->")
      callback && callback(null, response);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const MarchantEdit = createAsyncThunk(
  "MarchantEdit",
  async ({ data, callback }, Thunk) => {
    try {
      let payload = JSON.parse(JSON.stringify(data));
      delete payload.id;
      let response = await put(URL.MARCHANT_EDIT + data?._id, payload);

      callback && callback(null, response.data);
      return response;
    } catch (error) {
      callback && callback(error?.data?.message || error.message || error);
      return Thunk.rejectWithValue(error);
    }
  }
);

export const MARCHANQRTLIST = createAsyncThunk(
  "MARCHANQRTLIST",
  async ({ data, callback }, Thunk) => {
    try {
      let response = await post(URL.MARCHANT_QR_LIST, data);
      callback && callback(null, response);
      return response;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);
export const MerchantView = createAsyncThunk(
  "MerchantView",
  async ({ data, callback }, Thunk) => {
    try {
      let response = await get(URL.MARCHANTVIEWDETAILS + data);
      callback && callback(null, response);
      return response;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);

export const MarchantQRAdd = createAsyncThunk(
  "MarchantQRAdd",
  async ({ data, callback }, Thunk) => {
    try {
      let response = await post(URL.MARCHANT_QR_ADD ,data);
      callback && callback(null, response);
      return response;
    } catch (error) {
      callback && callback(error?.data?.message || error.message || error);
      return Thunk.rejectWithValue(error);
    }
  }
);
export const deleteMarchantQr = createAsyncThunk(
  "deleteMarchantQr",
  async ({ data, callback }, thunkAPI) => {
    console.log(data,"data++=++++++++++++++++++++")
    try {
      const response = await del(URL.MARCHANT_QR_DELETE + data);
      console.log(data,"data----------->")
      callback && callback(null, response);
      return response;
    } catch (error) {
      callback && callback(error?.data?.message || error.message || error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);