import { createSlice, current } from "@reduxjs/toolkit";
import * as Act from "./actions";

const initialState = {
  loading: false,
  badges: [],
  totalCount: 0,
};

const slice = createSlice({
  name: "Badge",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(Act.getBadgesList.pending, (state, action) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(Act.getBadgesList.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
      badges: payload.data,
      totalCount: payload.totalCount,
    }));
    builder.addCase(Act.getBadgesList.rejected, (state, action) => ({
      ...state,
      loading: false,
      badges: [],
      totalCount: 0,
    }));

    //add badge 
    builder.addCase(Act.AddBadge.pending, (start, action) => ({
      ...start,
      loading: true,
    }));
    builder.addCase(Act.AddBadge.fulfilled, (state, { payload }) => (
      console.log(payload),
      {
        ...state,
        loading: false,
      }));
    builder.addCase(Act.AddBadge.rejected, (state, action) => ({
      ...state,
      loading: false,
    }));

    //edit badge 
    builder.addCase(Act.editBadge.pending, (start, action) => ({
      ...start,
      loading: true,
    }));
    builder.addCase(Act.editBadge.fulfilled, (state, { payload }) => (
      console.log(payload),
      {
        ...state,
        loading: false,
      }));
    builder.addCase(Act.editBadge.rejected, (state, action) => ({
      ...state,
      loading: false,
    }));

    //delete badge 
    builder.addCase(Act.deleteBadge.pending, (start, action) => ({
      ...start,
      loading: true,
    }));
    builder.addCase(Act.deleteBadge.fulfilled, (state, { payload }) => (
      console.log(payload),
      {
        ...state,
        loading: false,
      }));
    builder.addCase(Act.deleteBadge.rejected, (state, action) => ({
      ...state,
      loading: false,
    }));

  },
});

export default slice;
