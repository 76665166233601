const inputFields = [
  {
    name: "type",
    type: "select",
    label: "Type*",
    placeholder: "Select Type...",
    options: [
      { value: "outfit", label: "Outfit" },
      { value: "skin", label: "Skin" },
    ]
  },
  {
    name: "name",
    type: "text",
    label: "Name*",
    placeholder: "Name...",
  },
  {
    name: "imageUrl",
    type: "image",
    label: "Logo*",
    placeholder: "Select Logo...",
  },
];

export default inputFields;
