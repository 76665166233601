// inputFields.js
const InputFields = [
  {
    name: "name",
    type: "text",
    label: "Name",
  },
  {
    name: "email",
    type: "email",
    label: "Email",
  },

  {
    name: "mobileNumber",
    type: "phone",
    label: "PhoneNumber",
  },
  {
    name: "address",
    type: "textarea",
    label: "Address",
  },
  {
    name: "contactPersonName",
    type: "text",
    label: "Contact Person",
  },

  {
    name: "comments",
    type: "text",
    label: "Comments",
  },
];

export default InputFields;
