import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/******** imported ***********/
import Routs from "./routes";
import store from "./store";

function App() {
  // console.log("app")
  return (
    <Provider store={store}>
      <ToastContainer />
      <BrowserRouter>
        <Routs />
      </BrowserRouter>
    </Provider>
  );
}

export default App;
