import { createSlice, current } from "@reduxjs/toolkit";
import * as Act from "./actions";

const initialState = {
  loading: false,
  users: [],
  totalCount: 0,
};

const slice = createSlice({
  name: "users",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(Act.getUsers.pending, (state, action) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(Act.getUsers.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
      users: payload.data,
      totalCount: payload.totalcount,
    }));
    builder.addCase(Act.getUsers.rejected, (state, action) => ({
      ...state,
      loading: false,
      users: [],
      totalCount: 0,
    }));

    ///change users status
    builder.addCase(Act.updateUser.pending, (state, action) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(Act.updateUser.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
    }));
    builder.addCase(Act.updateUser.rejected, (state, action) => ({
      ...state,
      loading: false,
    }));


    //add user 
    builder.addCase(Act.AddUser.pending, (start, action) => ({
      ...start,
      loading: true,
    }));
    builder.addCase(Act.AddUser.fulfilled, (state, { payload }) => (
      console.log(payload),
      { 
      ...state,
      loading: false,
    }));
    builder.addCase(Act.AddUser.rejected, (state, action) => ({
      ...state,
      loading: false,
    }));
     // Case for viewing user  
     builder.addCase(Act.USER_PROFILE.pending, (state, action) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(Act.USER_PROFILE.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
      user: payload,
    }));
    builder.addCase(Act.USER_PROFILE.rejected, (state, action) => ({
      ...state,
      loading: false,
      user: null,
    }));
    // view user relative
    // builder.addCase(Act.USER_RELATIVE.pending, (state, action) => ({
    //   ...state,
    //   loading: true,
    // }));
    // builder.addCase(Act.USER_RELATIVE.fulfilled, (state, { payload }) => ({
    //   ...state,
    //   loading: false,
    //   // user: payload,
    // }));
    // builder.addCase(Act.USER_RELATIVE.rejected, (state, action) => ({
    //   ...state,
    //   loading: false,
    //   user: null,
    // }));
    // user relative profile
    // builder.addCase(Act.USER_RELATIVE_PROFILE.pending, (state, action) => ({
    //   ...state,
    //   loading: true,
    // }));
    // builder.addCase(Act.USER_RELATIVE_PROFILE.fulfilled, (state, { payload }) => ({
    //   ...state,
    //   loading: false,
    //   user: payload,
    // }));
    // builder.addCase(Act.USER_RELATIVE_PROFILE.rejected, (state, action) => ({
    //   ...state,
    //   loading: false,
    //   user: null,
    // }));
  },
});

export default slice;
