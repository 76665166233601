
import { createAsyncThunk } from "@reduxjs/toolkit";
import * as URL from "helpers/url_helper";
import { get } from "helpers/api_helper";

export const getDashboard = createAsyncThunk(
  "dashboard/getDashboard",
  async (_, { rejectWithValue },callback) => { 
    try {
      const response = await get(URL.DASBOARDAPI);
      callback && callback(null, response.data);
      return response;
    } catch (error) {
      callback && callback(error, null);
      return rejectWithValue(error.message); 
    }
  }
);
