import { DATE_FORMAT } from "helpers/common";
import Badge from "react-bootstrap/Badge";
import React from "react";
import { Link } from "react-router-dom";

const STATUS = {
  "active": "Active",
  "deleted": "Deleted"
}
const Columns = ({ deleteHandler, handleEditHandler }) => [
  {
    dataField: "name",
    text: "Name",
    formatter: (_, row) => <span className="text-capitalize">{row.name}</span>,
  },
  {
    dataField: "imageUrl",
    text: "Logo",
    formatter: (_, row) => <img
      src={row?.baseUrl + row?.imageUrl}
      className="main-sidebar-logo img-fluid img-avatar-logo"
    />,
  },
  // {
  //   dataField: "category",
  //   text: "Category",
  //   formatter: (_, row) => <span className="text-capitalize">{CATEGORY[row.category]}</span>,
  // },
  // {
  //   dataField: "count",
  //   text: "Count",
  //   formatter: (_, row) => <span className="text">{row.count}</span>,
  // },
  // {
  //   dataField: "levelType",
  //   text: "Type",
  //   formatter: (_, row) => <span className="text">{LEVEL[row?.levelType] || "--"}</span>,
  // },

  // {
  //   text: "Status",
  //   dataField: "status",
  //   formatter: (cellContent, row) => (
  //     <Badge
  //       className={
  //         `text-capitalize font-size-13 badge-soft-${row.status}`
  //       }
  //       color={row.badgeClass}
  //     >
  //       {(STATUS[row.status])}
  //     </Badge>
  //   ),
  // },
  {
    text: "Created At",
    dataField: "dateCreated",
    formatter: (_, row) => DATE_FORMAT(row?.createdAt),
  },
  {
    text: "Action",
    formatter: (_, row) => (
      <>
        {/* {row?.status && row?.status == "inactive" ? (
          <button
            className="fas fa-toggle-on"
            style={{
              fontSize: "20px",
              color: "green",
              backgroundColor: "#fff",
              border: "none",
            }}
            title="Active"
            onClick={() => handleUserStatus(row._id, "active")}
          ></button>
        ) : (
          <button
            className="fas fa-toggle-off"
            style={{
              fontSize: "20px",
              color: "red",
              backgroundColor: "#fff",
              border: "none",
            }}
            title="Inactive"
            onClick={() => handleUserStatus(row._id, "inactive")}
          ></button>
        )} */}

        <button
          className="fas fa-pen"
          style={{
            fontSize: "16px",
            backgroundColor: "#fff",
            border: "none",
          }}
          title="Edit"
          onClick={() =>
            handleEditHandler(row)
          }
        ></button>

        <button
          className="fas fa-trash-alt"
          style={{
            backgroundColor: "#fff",
            border: "none",
          }}
          title="Inactive"
          onClick={() => deleteHandler(row._id)}
        ></button>

      </>
    ),
  },
];
export default Columns;
