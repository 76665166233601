import React from "react";
import { Modal } from "react-bootstrap";
import FormikForm from "components/shared/FormikForm";
import Validation from "views/QrPrice/Validation";
import InputFields from "views/QrPrice/inputFields";

export default function AddQrPrice({
  show,
  handleClose,
  handleConfirm,
  title,
  loading,
  initialValues,
}) {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
      size="lg"
    >
      <Modal.Header className="py-3 text-dark" closeButton>
        <Modal.Title className="fw-bold text-dark">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormikForm
          initialValues={initialValues}
          validationSchema={Validation}
          onSubmit={handleConfirm}
          loading={loading}
          inputFields={InputFields}
          col={6}
          buttonText={"Submit"}
        />
      </Modal.Body>
    </Modal>
  );
}
