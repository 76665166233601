import { createSlice } from "@reduxjs/toolkit";
import * as Act from "./actions";

const initialState = {
  loading: false,
  dashboard: [],
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(Act.getDashboard.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(Act.getDashboard.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.dashboard = payload;
      })
      .addCase(Act.getDashboard.rejected, (state) => {
        state.loading = false;
        state.dashboard = null;
      });
  },
});

export default dashboardSlice.reducer;
