//dashboard
export const dashboard = `dashboard`;

//settings
export const general_setting = `general-setting`;
export const level_setting = `level-setting`;

// //users
export const users = `users`;
export const profile = `profile`;
//details user id
export const singleUser = `users/:id`;
export const UserRelativeProfile = `users/profile/:id`;
export const changePassword = `change-password`;
export const login = `login`;
export const forgot_password = `forgot_password`;
//qrcreate
export const QrCodeCreate = "qrcreate";
export const QrPriceCreate = "qrPrices/create";
// export const QrPriceList = "qrPrices/list";
// export const QrPriceEdit = "qrPrices/:id";
// export const QrPriceDelete = "qrPrices/:id";

// payments list 
export const Paymentlist="paymentlist"
export const PaymentDetailPage="paymentlist/:id"

//faq
export const faq = `faq/list`;
export const faq_add = `faq/add`;
export const faq_edit = `faq/edit/:id`;
export const faqedit = `faq/edit`;
export const faqview = `faq/view`;
export const faq_view = `faq/view/:id`;


//Content pages
export const content_pages = `pages/list`;
export const content_add = `pages/add`;
export const contentedit = `pages/edit`;
export const content_edit = `pages/edit/:id`;

//Badges
export const badges = `badges/list`;
export const badges_add = `badges/add`;
export const badges_edit = `badges/edit/:id`;

//Language
export const language = `language/list`;

//Avatar
export const avatars = `avatars/list`;

//Avatar Category
export const avatarCategories = `avatar-category/list`;

//Avatar characterstic
export const avatarCharacterstics = `avatar-characterstic/list`;
// marchant 
export const marchant=`marchant`
export const merchant_view=`marchant/:id`
// qr history 
export const qrhistory=`qr_history`