import React from "react";

import Badge from "react-bootstrap/Badge";

import { DATE_FORMAT,  } from "helpers/common";

const STATUS = {
  active: "Active",
  inactive: "Inactive",
  linked: "linked",
  pending: "Pending",
};

const columns = ({handlelinkQr,API_IMAGE_URL})=>([
  {
    dataField: "qrCodeID",
    text: "QR Code ID",
  },
  {
    dataField: "link",
    text: "QR Code",
    formatter: (_, row) => (
      <div
        className="col"
        // onClick={() => downloadFile(API_IMAGE_URL + row.link, "abc.svg")}
      >
        <img
          style={{
            width: "100px",
            height: "100px",
            borderRadius: "10px",
            backgroundColor: "#000",
          }}
          src={row.link}
          alt={`QR Code ${row.qrCodeID}`}
        />

        <a
          href={`${API_IMAGE_URL+row.downloadLink}`}
          download={`${API_IMAGE_URL+row.downloadLink}`}
          className="btn btn-primary btn-sm mt-2"
          target="_blank"
        >
          Download QR
        </a>
      </div>
    ),
  },
  {
    dataField: "status",
    text: "Status",
    formatter: (cellContent, row) => (
      <Badge
        className={`text-capitalize font-size-13 badge-soft-${
          row.status === "pending" ? "warning" : "success"
        }`}
      >
        {STATUS[row.status]}
      </Badge>
    ),
  },
  {
    dataField: "name",
    text: "Linked Profile Name",
    formatter: (_, row) => {
      if (row?.status === "linked" && row.userProfileDetails?.userDetails) {
        return row?.userProfileDetails?.fullName;
      } else {
        return "---";
      }
    },
  },
  {
    dataField: "email",
    text: "Email",
    formatter: (_, row) => {
      if (row?.status === "linked" && row?.userProfileDetails?.userDetails) {
        return row?.userProfileDetails?.userDetails?.email;
      } else {
        return "---";
      }
    },
  },
  {
    dataField: "createdAt",
    text: "Created At",
    formatter: (_, row) => DATE_FORMAT(row.createdAt),
  },
  // {
  //   text: "Action",
  //   dataField: "LinkQr",
  //   formatter: (_, row) => (
  //     <button className="btn btn-primary" title="linkQr" onClick={() => handlelinkQr(row.qrCodeID)}>
  //       LinkQr
  //     </button>
  //   ),
  // },
]);

export default columns;
