import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FadeLoader from "react-spinners/FadeLoader";
import { MARCHANTVIEW } from "common/viewContent";
import Helmet from "react-helmet";
import * as Path from "routes/Path/index";
import Table from "components/Table";
import { toast } from "react-toastify";
import Select from "react-select";
import Columns from "views/marchant/MarchantViewDetails/Columns";
import {
  MARCHANQRTLIST,
  MarchantQRAdd,
  MerchantView,
  QRDETAILS,
  deleteMarchantQr,
} from "store/actions";
import { Button } from "react-bootstrap";
import ConfirmModal from "components/Modals/ConfirmModal";

const MarchantView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [columns, setColumns] = useState([]);
  const [marchant, setMarchant] = useState([]);

  const [confirmModalMerchant, setConfirmModalMerchant] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [merchantData, setMerchantData] = useState();
  const [qrData, setQrData] = useState([]);
  const [marchantDeatails, setMarchantDeatils] = useState();
  const [merchantDataLinked, setMerchantDataLinked] = useState();
  const [pageSize, setPageSize] = useState(10);
  const { loading } = useSelector((state) => state?.marchants);
  const [selectedOption, setSelectedOption] = useState([]);
  const [filter, setFilter] = useState({
    pageNo: 1,
    limit: pageSize,
    order: -1,
    orderBy: "createdAt",
  });
  const [filterMarchant, setFilterMarchant] = useState({
    pageNo: 1,
    limit: pageSize,
    order: -1,
    orderBy: "createdAt",
    merchantId: id,
    qrStatus: "pending",
  });
  const [filterMerchantQrLinked, setFilterMarchantMerchantQrLinked] = useState({
    pageNo: 1,
    limit: pageSize,
    order: -1,
    orderBy: "createdAt",
    merchantId: id,
    qrStatus: "linked",
  });
  const [formData, setFormData] = useState({
    merchantId: id,
    qrCodeIds: [],
    type: "single",
  });

  const getMerchantDetails = () => {
    const callback = (err, res) => {
      if (err) {
        console.log(err);
      } else {
        toast.success(res);
        setMarchantDeatils(res);
      }
    };
    dispatch(MerchantView({ data: id, callback }));
  };
  useEffect(() => {
    getMerchantDetails();
  }, []);

  const fetchData = async () => {
    try {
      const callback = (err, res) => {
        if (err) {
          toast.error(err);
        } else {
          setMerchantData(res);
        }
      };
      dispatch(MARCHANQRTLIST({ data: filterMarchant, callback }));
    } catch (error) {
      console.error(error, "<===err");
    }
  };

  useEffect(() => {
    if (filterMarchant.merchantId) {
      fetchData();
      setColumns(Columns({ deleteHandler }));
    }
  }, [filterMarchant, id]);

  const LinkedQrDataFetch = async () => {
    try {
      const callback = (err, res) => {
        if (err) {
          toast.error(err);
        } else {
          setMerchantDataLinked(res);
        }
      };
      dispatch(MARCHANQRTLIST({ data: filterMerchantQrLinked, callback }));
    } catch (error) {
      console.error(error, "<===err");
    }
  };

  useEffect(() => {
    if (filterMerchantQrLinked?.merchantId) {
      LinkedQrDataFetch();
      setColumns(Columns({ deleteHandler }));
    }
  }, [filterMerchantQrLinked, id]);

  const fetchQrData = async (filter) => {
    try {
      const callback = (err, res) => {
        if (err) {
          toast.error(err);
        } else {
          console.log(res, "response  data ");
          setQrData(res);
          toast.success(res.message);
        }
      };
      dispatch(QRDETAILS({ data: filter, callback }));
    } catch (error) {
      console.error(error, "<===err");
    }
  };
  useEffect(() => {
    fetchQrData(filter);
  }, [filter]);

  const selectOptions = qrData.map((item) => ({
    value: item?._id,
    label: item.qrCodeID,
  }));

  const handleInputChange = (inputValue) => {
    setFilter((prevState) => ({
      ...prevState,
      search: inputValue,
    }));
  };

  const handleChange = (selectedOptions) => {
    console.log(formData, "formdata");
    setSelectedOption(selectedOptions);
    setFormData({
      ...formData,
      qrCodeIds: [selectedOptions.value],
    });
    // selectedOptions.map((option) => option.value),
  };

  const handleSubmit = () => {
    console.log(formData);
    if (!formData?.qrCodeIds?.length) {
      return;
    }
    const callback = (err, res) => {
      if (err) {
        console.log(err, "error        message");
        toast.error(err);
      } else {
        toast.success(res?.message);
        fetchData(filter);
        getMerchantDetails();
        setFormData((pre) => ({
          ...pre,
          qrCodeIds: [],
        }));
        setSelectedOption([]);
      }
    };
    dispatch(MarchantQRAdd({ data: formData, callback }));
  };

  const deleteHandler = (id) => {
    setConfirmModalMerchant(true);
    setDeleteId(id);
  };

  const handleDeleteConfirm = (event) => {
    event.preventDefault();
    console.log("text message");
    const callback = (err, res) => {
      if (err) {
        toast.error(err);
      } else {
        toast.success(res.message);
        fetchData(filter);
        getMerchantDetails();
      }
    };
    dispatch(deleteMarchantQr({ data: deleteId, callback }));
    setConfirmModalMerchant(false);
    setDeleteId(null);
  };

  const handlePageChange = (pageNumber) => {
    // setFilterMarchant((prev)=>({...prev, pageNo : pageNumber}));
  };

  console.log(qrData, "qrData===========>");
  console.log(id, "ids================>");
  console.log(
    merchantData?.totalcount,
    "marchantDeatails=========================>"
  );
  console.log(merchantData, "rrreeeeee======");
  console.log(
    merchantDataLinked?.data,
    "merchantDataLinked=========================>"
  );
  return (
    <>
      <ConfirmModal
        show={confirmModalMerchant}
        handleClose={() => setConfirmModalMerchant(false)}
        title={"Confirmation"}
        content={"Are you sure you want to delete the Assign Qr?"}
        handleConfirm={handleDeleteConfirm}
        loading={""}
      />

      <Helmet title={MARCHANTVIEW?.CURRENT_MODULE} />
      <section className="section">
        <div className="section-header">
          <h1>{MARCHANTVIEW?.CURRENT_MODULE}</h1>
          <div className="section-header-breadcrumb">
            <div className="breadcrumb-item active">
              <Link to={"/admin/" + Path.marchant}>Merchants</Link>
            </div>
            <div className="breadcrumb-item">
              {MARCHANTVIEW?.CURRENT_MODULE}
            </div>
          </div>
        </div>
        <div className="section-body">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="card card-statistic-2 p-4 shadow-sm bg-gradient rounded-lg hover-card">
                <div className="row align-items-center justify-content-between">
                  <div className="col-md-12">
                    <span className="fw-bolder">Total Qr Plates : </span>
                    <span className="fw-bolder">
                      {marchantDeatails?.totalAssignedQrCounts}
                    </span>
                    <br />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="card card-statistic-2 p-4 shadow-sm bg-gradient rounded-lg hover-card">
                <div className="row align-items-center justify-content-between">
                  <div className="col-md-12">
                    <span className="fw-bolder">Linked Qr Plates : </span>
                    <span className="fw-bolder">
                      {marchantDeatails?.linkedQrCounts}
                    </span>
                    <br />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="card card-statistic-2 p-4 shadow-sm bg-gradient rounded-lg hover-card">
                <div className="row align-items-center justify-content-between">
                  <div className="col-md-12">
                    <span className="fw-bolder">In Storage Qr Plates : </span>
                    <span className="fw-bolder">
                      {marchantDeatails?.inStorageQrCounts}
                    </span>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="card p-4">
                <div className="cardHeader py-2 border-bottom">
                  <h5 className="m-0 fw-bold ">Merchant Details</h5>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-sm-12 d-flex px-2 gap-4">
                    <div className=" mt-4">
                      <p>
                        {" "}
                        <b>Company Name  :   </b> {marchantDeatails?.data?.name}
                      </p>
                      <p>
                        <b>Mobile number  :   </b>{" "}
                        {marchantDeatails?.data?.mobileNumber}
                      </p>
                      <p>
                        <b>Address :   </b>
                        {marchantDeatails?.data?.address}
                      </p>
                      <p>
                        <b>Contact Person :  </b>
                        {marchantDeatails?.data?.contactPersonName}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12 justify-content-end mt-4">
                    <p>
                      <b>Comment : </b> {marchantDeatails?.data?.comments}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="d-flex card p-4">
                <div className="cardHeader py-2 border-bottom">
                  <h5 className="m-0 fw-bold ">Add Qr Plates</h5>
                </div>
                <div className="d-flex  gap-2 p-4 ">
                  <div className="w-100">
                    <Select
                      className=""
                      value={selectedOption}
                      onChange={handleChange}
                      options={selectOptions}
                      onInputChange={handleInputChange}
                      name="qrCodeId"
                      placeholder="Choose your Qr code"
                      isSearchable
                      // isMulti
                    />
                  </div>
                  <div className="">
                    <Button onClick={handleSubmit}>Submit</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              {loading && (
                <div className="main-loader">
                  <FadeLoader size={1000} />
                </div>
              )}
              {!loading && merchantData?.data?.length > 0 && (
                <>
                  <Table
                    title="Pending Qr List"
                    data={merchantData?.data}
                    columns={columns}
                    setFilter={setFilterMarchant}
                    totalCount={merchantData?.totalcount}
                    handleTableChange={handlePageChange}
                    filter={filterMarchant}
                  />
                </>
              )}
              {!loading && !merchantData?.data && (
                <p style={{ textAlign: "center" }}>No Marchant Found</p>
              )}
            </div>
            <div className="col-lg-6">
              {!loading && merchantDataLinked?.data?.length > 0 && (
                <>
                  <Table
                    title="Linked Qr List"
                    data={merchantDataLinked?.data}
                    columns={columns}
                    setFilter={setFilterMarchantMerchantQrLinked}
                    totalCount={merchantDataLinked?.totalcount}
                    handleTableChange={handlePageChange}
                    filter={merchantDataLinked}
                  />
                </>
              )}
              {!loading && !merchantData?.data && (
                <p style={{ textAlign: "center" }}>No Marchant Found</p>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default MarchantView;
