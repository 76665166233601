import { createAsyncThunk } from "@reduxjs/toolkit";

import * as URL from "helpers/url_helper";
import { get, post, del, put } from "helpers/api_helper";

export const getFAQList = createAsyncThunk(
  "getFAQList",
  async ({ data, callback }, Thunk) => {
    try {
      let response = await post(`${URL.GET_FAQ_LIST}`, data);
      callback && callback(null, response.data);
      return response;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);

export const AddFAQ = createAsyncThunk(
  "AddFAQ",
  async ({ data, callback }, Thunk) => {
    try {
      let response = await post(URL.ADD_FAQ, data);
      callback && callback(null, response);
      return response;
    } catch (error) {
      callback && callback(error.message, null);
      return Thunk.rejectWithValue(error);
    }
  }
);

export const editFAQ = createAsyncThunk(
  "editFAQ",
  async ({ data, callback }, Thunk) => {
    try {
      let response = await put(URL.EDIT_FAQ + data._id, data);
      callback && callback(null, response);
      return response;
    } catch (error) {
      callback && callback(error.message, null);
      return Thunk.rejectWithValue(error);
    }
  }
);

export const deleteFAQ = createAsyncThunk(
  "deleteFAQ",
  async ({ data, callback }, Thunk) => {
    try {
      let response = await del(URL.DELETE_FAQ + data._id, data);
      callback && callback(null, response);
      return response;
    } catch (error) {
      callback && callback(error.message, null);
      return Thunk.rejectWithValue(error);
    }
  }
);
