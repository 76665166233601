import React, { useState } from "react";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

//imported
import { CHANGE_PASSWORD } from "common/viewContent";
import * as Path from "routes/Path/index";
import FormikForm from "components/shared/FormikForm";
import Validation from "./Validation";
import InputFields from "./inputFields";
import { resetPass } from "store/actions";
import { toast } from "react-toastify";

const ChangePassword = () => {
  const [sending, setSending] = useState(false);
  const { user } = useSelector((state) => state.Auth);
  const dispatch = useDispatch();

  //values
  const initialValues = {
    _id: user?._id,
    old_password: "",
    new_password: "",
    confirm_password: "",
  };

  //submit data
  const handleSubmit_ = async (values, { resetForm }) => {
    try {
      setSending(true);

      const callBack = (err, response) => {
        if (err) {
          toast.error(err);
        } else {
          toast.success("Password Changed");

          setSending(false);
          resetForm();
        }
      };
      let res = {
        _id: initialValues._id,
        oldPassword: values.old_password,
        newPassword: values.new_password,
      };

      await dispatch(resetPass({ data: res, callBack }));
    } catch (err) {
      console.log(err);
      setSending(false);
    }
  };

  return (
    <>
      <Helmet title={CHANGE_PASSWORD.CURRENT_MODULE} />
      <section className="section">
        <div className="section-header">
          <h1>{CHANGE_PASSWORD.CURRENT_MODULE}</h1>
          <div className="section-header-breadcrumb">
            <div className="breadcrumb-item active">
              <Link to={"/admin/"+Path.dashboard}>Dashboard</Link>
            </div>
            <div className="breadcrumb-item">
              <Link to={"/admin/"+Path.profile}>Profile</Link>
            </div>
            <div className="breadcrumb-item">Change password</div>
          </div>
        </div>
        <div className="section-body">
          {/* <h2 className="section-title">{CHANGE_PASSWORD.CURRENT_MODULE}</h2> */}
          <p className="section-lead">
            You can manage your password from here.
          </p>
          <div className="row mt-sm-4">
            <div className="col-12 col-md-12">
              <div className="card">
                <div className="card-body">
                  <FormikForm
                    initialValues={initialValues}
                    validationSchema={Validation}
                    onSubmit={handleSubmit_}
                    loading={sending}
                    inputFields={InputFields}
                    submitting={sending}
                    buttonText="Submit"
                    is_block={false}
                    col={6}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ChangePassword;
