// import React, { useState, useEffect } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import FadeLoader from "react-spinners/FadeLoader";
// import { PAYMENTS } from "common/viewContent";
// import Helmet from "react-helmet";
// import * as Path from "routes/Path/index";
// import Table from "components/Table";
// import { toast } from "react-toastify";
// import Columns from "./Columns";
// import FormikForm from "components/shared/FormikForm";
// import { PAYMENT_LIST } from "store/payments/actions";
// import Select from "react-select";

// const InputFieldsForSearch = [
//   {
//     name: "search",
//     type: "text",
//     label: "",
//     placeholder: "Search using username & email...",
//   },
//   {
//     name: "select",
//     type: "select",
//     label: "",
//     placeholder: "Select shippingStatus",
//     options: [
//       { value: "processing", label: "Processing" },
//       { value: "pending", label: "Pending" },
//       { value: "shipped", label: "Shipped" },
//       { value: "delivered", label: "Delivered" },
//     ],
//   },
// ];

// const options = [
//   { value: "completed", label: "Completed" },
//   { value: "pending", label: "Pending" },
//   { value: "rejected", label: "Rejected" },
//   { value: "created", label: "Created" },
// ];

// const initialValuesForFilter = {
//   search: "",
//   select: "",
// };

// const Payment = () => {
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const { payment, loading } = useSelector((state) => state?.payment);
//   const [columns, setColumns] = useState([]);
//   const [qrData, setQrData] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [pageSize, setPageSize] = useState(20);
//   const [filter, setFilter] = useState({
//     pageNo: currentPage,
//     limit: pageSize,
//     order: -1,
//     orderBy: "createdAt",
//     status:"completed"
//   });

//   const fetchData = async (filter) => {
//     try {
//       const callback = (err, res) => {
//         if (err) {
//           toast.error(err);
//         } else {
//           console.log(res);
//           setQrData(res);
//           toast.success(res.data.message);
//         }
//       };
//       dispatch(PAYMENT_LIST({ data: filter, callback }));
//     } catch (error) {
//       console.error(error, "<===err");
//     }
//   };

//   useEffect(() => {
//     fetchData(filter);
//     setColumns(Columns({ handleViewUser }));
//   }, [filter]);

//   const handleViewUser = (id) => {
//     navigate(`/admin/paymentlist/${id}`);
//   };

//   const handlePageChange = (pageNumber) => {
//     setCurrentPage(pageNumber);
//     setFilter((prevFilter) => ({ ...prevFilter, pageNo: pageNumber }));
//   };

//   // const handleFilterForm = (info) => {
//   //   setFilter((prevFilter) => ({
//   //     ...prevFilter,
//   //     pageNo: 1,
//   //     search: info.search,
//   //   }));
//   // };
//   const handleFilterForm = (info) => {
//     setFilter((pre)=>({
//       ...pre,
//       pageNo: 1,
//       limit: pageSize,
//       search: info.search,
//       shippingStatus: info.select,
//     }));
//   };

//   return (
//     <>
//       {loading && (
//         <div className="main-loader">
//           <FadeLoader size={1000} />
//         </div>
//       )}
//       <Helmet title={PAYMENTS?.CURRENT_MODULE} />
//       <section className="section">
//         <div className="section-header">
//           <h1>{PAYMENTS?.CURRENT_MODULE}</h1>
//           <div className="section-header-breadcrumb">
//             <div className="breadcrumb-item active">
//               <Link to={"/admin/" + Path.dashboard}>Dashboard</Link>
//             </div>
//             <div className="breadcrumb-item">{PAYMENTS?.CURRENT_MODULE} </div>
//           </div>
//         </div>
//         <div className="section-body">
//           <div className="card">
//             <div className="row justify-content-between">
//               <div className="col-6">
//                 <div className="card-header">
//                   <h2 className="section-title">{PAYMENTS?.TOP_CONTENT}</h2>
//                 </div>
//               </div>
//             </div>
//             <div className="col-12">
//               <div className="card-header">
//                 <div className="col-9">
//                   <FormikForm
//                     initialValues={initialValuesForFilter}
//                     onSubmit={handleFilterForm}
//                     loading={loading}
//                     inputFields={InputFieldsForSearch}
//                     buttonText={"Search"}
//                   />
//                 </div>
//                 <div className="col-3 px-2 " style={{top:"-25px"}}> 
//                   <Select
//                     value={options?.find((item)=>item.value === filter?.status)}
//                     options={options}
//                     placeholder="Select a payment status"
//                     onChange={(data)=>setFilter((pre)=>({...pre,status:data?.value}))}
//                     isClearable
//                   />
//                 </div>
//               </div>
//             </div>
//           </div>
//           {!loading && (
//             <Table
//               data={qrData}
//               columns={columns}
//               setFilter={setFilter}
//               totalCount={payment?.totalcount}
//               handleTableChange={handlePageChange}
//               filter={filter}
//             />
//           )}
//           {!loading && qrData?.length === 0 && (
//             <p style={{ textAlign: "center" }}>No Payments  Found</p>
//           )}
//         </div>
//       </section>
//     </>
//   );
// };

// export default Payment;

import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FadeLoader from "react-spinners/FadeLoader";
import { PAYMENTS } from "common/viewContent";
import Helmet from "react-helmet";
import * as Path from "routes/Path/index";
import Table from "components/Table";
import { toast } from "react-toastify";
import Columns from "./Columns";

import { PAYMENT_LIST } from "store/payments/actions";
import Select from "react-select";

const InputFieldsForSearch = [
  {
    name: "search",
    type: "text",
    label: "",
    placeholder: "Search using username & email...",
  },
  {
    name: "select",
    type: "select",
    label: "",
    placeholder: "Select shippingStatus",
    options: [
      { value: "processing", label: "Processing" },
      { value: "pending", label: "Pending" },
      { value: "shipped", label: "Shipped" },
      { value: "delivered", label: "Delivered" },
    ],
  },
];
const useStatusOptions = [
  { value: "processing", label: "Processing" },
  { value: "pending", label: "Pending" },
  { value: "shipped", label: "Shipped" },
  { value: "delivered", label: "Delivered" },
];

const options = [
  { value: "completed", label: "Completed" },
  { value: "pending", label: "Pending" },
  { value: "rejected", label: "Rejected" },
  { value: "created", label: "Created" },
];

const initialValuesForFilter = {
  search: "",
  select: "",
};

const Payment = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { payment, loading } = useSelector((state) => state?.payment);
  const [columns, setColumns] = useState([]);
  const [qrData, setQrData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [status, setStatus] = useState("");
  const [search, setSearch] = useState();
  const [pageSize, setPageSize] = useState(20);
  const [filter, setFilter] = useState({
    pageNo: currentPage,
    limit: pageSize,
    order: -1,
    orderBy: "createdAt",
    status: "completed",
  });

  const fetchData = async (filter) => {
    try {
      const callback = (err, res) => {
        if (err) {
          toast.error(err);
        } else {
          console.log(res);
          setQrData(res);
          toast.success(res.data.message);
        }
      };
      dispatch(PAYMENT_LIST({ data: filter, callback }));
    } catch (error) {
      console.error(error, "<===err");
    }
  };

  useEffect(() => {
    fetchData(filter);
    setColumns(Columns({ handleViewUser }));
  }, [filter]);

  const clearFilter = () => {
    setFilter((prev) => ({
      ...prev,
      search: "",
    }));

    setSearch("");
  };
  const onClickFilter = (e) => {
    e?.preventDefault();
    setFilter({
      ...filter,

      search: search,
    });
  };

  const handleViewUser = (id) => {
    navigate(`/admin/paymentlist/${id}`);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    setFilter((prevFilter) => ({ ...prevFilter, pageNo: pageNumber }));
  };

  // const handleFilterForm = (info) => {
  //   setFilter((prevFilter) => ({
  //     ...prevFilter,
  //     pageNo: 1,
  //     search: info.search,
  //   }));
  // };
  const handleFilterForm = (info) => {
    setFilter((pre) => ({
      ...pre,
      pageNo: 1,
      limit: pageSize,
      search: info.search,
      shippingStatus: info.select,
    }));
  };

  return (
    <>
      {loading && (
        <div className="main-loader">
          <FadeLoader size={1000} />
        </div>
      )}
      <Helmet title={PAYMENTS?.CURRENT_MODULE} />
      <section className="section">
        <div className="section-header">
          <h1>{PAYMENTS?.CURRENT_MODULE}</h1>
          <div className="section-header-breadcrumb">
            <div className="breadcrumb-item active">
              <Link to={"/admin/" + Path.dashboard}>Dashboard</Link>
            </div>
            <div className="breadcrumb-item">{PAYMENTS?.CURRENT_MODULE} </div>
          </div>
        </div>
        <div className="section-body">
          <div className="card">
            <div className="row justify-content-between">
              <div className="col-6">
                <div className="card-header">
                  <h2 className="section-title">{PAYMENTS?.TOP_CONTENT}</h2>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="card-header">
                <div className="col-9">
                  <form onSubmit={onClickFilter}>
                    <div className="card-header justify-content-between">
                      <div className="col-lg-4 col-sm-12 col-md-12 my-2">
                        <input
                          className="form-control"
                          name="search"
                          onChange={(e) => setSearch(e.target.value)}
                          value={search}
                          placeholder="Search using name, email..."
                        />
                      </div>
                      <div className="col-lg-4 col-sm-12 col-md-12 my-2">
                        <Select
                          className="form-group"
                          name={"status"}
                          options={useStatusOptions}
                          onChange={(selectedOption) => {
                            setStatus(selectedOption?.value);
                          }}
                          placeholder={"Select shipping status"}
                          value={useStatusOptions?.find(
                            (option) => option?.value === status
                          )}
                          isClearable
                        />
                      </div>
                    </div>
                    <div className="col-12 my-2 gap-4 ">
                      <div className="d-flex gap-4">
                        <button
                          type="submit"
                          className="btn btn-primary btn-lg"
                        >
                          Search
                        </button>
                        <button
                          onClick={() => clearFilter()}
                          type="btn"
                          className="btn btn-primary btn-lg"
                        >
                          Clear filter
                        </button>
                      </div>
                    </div>
                  </form>

                  {/* <div className="card-header w-100 ">
                  <FormikForm
                    initialValues={initialValuesForFilter}
                    onSubmit={handleFilterForm}
                    loading={loading}
                    inputFields={InputFieldsForSearch}
                    buttonText={"Search"}
                  />
                </div> */}

                  {/* <FormikForm
                    initialValues={initialValuesForFilter}
                    onSubmit={handleFilterForm}
                    loading={loading}
                    inputFields={InputFieldsForSearch}
                    buttonText={"Search"}
                    ClickButton={"ClearFilter"}
                  /> */}
                </div>
                <div className="col-3 px-2 " style={{ top: "-25px" }}>
                  <Select
                    value={options?.find(
                      (item) => item.value === filter?.status
                    )}
                    options={options}
                    placeholder="Select a payment status"
                    onChange={(data) =>
                      setFilter((pre) => ({ ...pre, status: data?.value }))
                    }
                    isClearable
                  />
                </div>
              </div>
            </div>
          </div>
          {!loading && (
            <Table
              data={qrData}
              columns={columns}
              setFilter={setFilter}
              totalCount={payment?.totalcount}
              handleTableChange={handlePageChange}
              filter={filter}
            />
          )}
          {!loading && qrData?.length === 0 && (
            <p style={{ textAlign: "center" }}>No Payments Found</p>
          )}
        </div>
      </section>
    </>
  );
};

export default Payment;

