const formInputFields = [
  {
    name: "contactPhoneNumber",
    type: "text",
    label: "Contact Phone Number",
    placeholder: "Contact Phone Number...",
  },
  {
    name: "contactEmailAddress",
    type: "email",
    label: "Contact Email Address",
    placeholder: "Contact Email Address...",
  },
  
  {
    name: "paymentMode",
    type: "ToggleSwitch",
    label: "Payment Mode",
    inactiveLabel: "Sandbox",
    activeLabel: "Live",
  },
  {
    name: "s3BaseUrl",
    type: "text",
    label: "s3BaseUrl",
    placeholder: "s3BaseUrl...",

  },
  {
    name: "youtube_tutorial_link",
    type: "text",
    label: "Youtube tutorial link",
    placeholder: "",

  },
  {
    name: "facebook",
    type: "text",
    label: "Facebook",
    placeholder: "",

  },
  {
    name: "instagram",
    type: "text",
    label: "Instagram",
    placeholder: "",

  },
  {
    name: "tiktok",
    type: "text",
    label: "Tiktok",
    placeholder: "",

  },
];

export { formInputFields };
