import React, { useState } from "react";
import Helmet from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

//imported
import * as Path from "routes/Path/index";
import { FORGOT_PASSWORD } from "common/viewContent";
// import { forgotPass } from "store/actions";

import FormikForm from "components/shared/FormikForm";
import Validation from "./Validation";
import InputFields from "./inputFields";

const ForgotPassword = ({ submitting }) => {
  const dispatch = useDispatch();

  const { loading } = useSelector((state) => state.Auth);
  //values
  const initialValues = {
    email: "",
  };

  //submit data
  const handleSubmit_ = async (values, { resetForm }) => {
    try {
      values = JSON.stringify(values);
      // await dispatch(forgotPass(values));
      resetForm();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Helmet title={FORGOT_PASSWORD.CURRENT_MODULE} />
      <div className="container mt-5" style={{ overflow: "hidden" }}>
        <div className="row">
          <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
            <div className="login-brand"></div>
            <div className="card card-primary">
              <div className="card-header">
                <h4>{FORGOT_PASSWORD.CURRENT_MODULE}</h4>
              </div>
              <div className="card-body">
                <p className="text-muted">{FORGOT_PASSWORD.MAIN_CONTENT}</p>
                <FormikForm
                  initialValues={initialValues}
                  validationSchema={Validation}
                  onSubmit={handleSubmit_}
                  loading={loading}
                  inputFields={InputFields}
                  submitting={submitting}
                  buttonText={FORGOT_PASSWORD.BUTTON}
                  is_block={true}
                  col={12}
                  is_passwordHide={false}
                />
                <div className="float-right">
                  <Link to={Path.login} className="text-small">
                    {FORGOT_PASSWORD.BACK}
                  </Link>
                </div>
              </div>
            </div>
            <div className="simple-footer">
              Copyright &copy; 2023 ICB <div className="bullet"></div> Design &
              Develop by Suffescom
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
