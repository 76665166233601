import React from "react";
//imported
import * as Path from "../Path";
//dashboard
import Dashboard from "views/dashboard/Dashboard";
//User
import UserList from "views/users/List";
import Login from "views/auth/Login/Login";
import ForgetPassword from "views/auth/Forget-password/ForgotPassword";
import GeneralSettings from "views/settings/General/index";
import LevelSettings from "views/settings/Level/List";
import Profile from "views/auth/profile/index";
import ChangePassword from "views/auth/change_password/index";
import FAQList from "views/faq/List";
import ContentPage from "views/pages/List";
import LanguageList from "views/language/List";
import BadgeList from "views/badges/List";
import AvatarList from "views/avatar/avatarList/List";
import AvatarCategory from "views/avatar/avatarCategory/List";
import AvatarCharacterstic from "views/avatar/avatarCharacterstic/List";
import ProfileSingle from "views/Relative/UserDetails";
import UserRelativeProfile from "views/Relative/UserRelativeProfile";
import QrCodeCreate from "views/createqrcode/QrCodeCreate";
import QrCreatePriceList from "views/QrPrice/QrCreatePriceList";
import Payment from "views/payments/payment";
import PaymentDetailsPage from "views/payments/paymentDetailPage";
import Marchant from "views/marchant/List";
import MarchantView from "views/marchant/MarchantViewDetails/MerchantView";
import QrLinkedHistory from "views/Qrhistory/list";
const routes = [
  {
    path: Path.dashboard,
    exact: true,
    auth: false,
    component: <Dashboard />,
  },
  {
    path: Path.users,
    exact: true,
    auth: true,
    component: <UserList />,
  },
  {
    path: Path.login,
    exact: true,
    auth: false,
    fallback: true,
    component: <Login />,
  },
  {
    path: Path.forgot_password,
    exact: true,
    auth: false,
    component: <ForgetPassword />,
  },
  {
    path: Path.changePassword,
    exact: true,
    auth: false,
    component: <ChangePassword />,
  },
  {
    path: Path.general_setting,
    exact: true,
    auth: false,
    component: <GeneralSettings />,
  },
  {
    path: Path.level_setting,
    exact: true,
    auth: false,
    component: <LevelSettings />,
  },
  {
    path: Path.profile,
    exact: true,
    auth: false,
    component: <Profile />,
  },
  {
    path: Path.faq,
    exact: true,
    auth: false,
    component: <FAQList />,
  },
  {
    path: Path.content_pages,
    exact: true,
    auth: false,
    component: <ContentPage />,
  },
  {
    path: Path.language,
    exact: true,
    auth: false,
    component: <LanguageList />,
  },
  {
    path: Path.badges,
    exact: true,
    auth: false,
    component: <BadgeList />,
  },
  {
    path: Path.avatars,
    exact: true,
    auth: false,
    component: <AvatarList />,
  },
  {
    path: Path.avatarCategories,
    exact: true,
    auth: false,
    component: <AvatarCategory />,
  },
  {
    path: Path.avatarCharacterstics,
    exact: true,
    auth: false,
    component: <AvatarCharacterstic />,
  },
  {
    path:Path.singleUser,
    exact:true,
    auth:false,
    component:<ProfileSingle/>
  },
  {
    path:Path.UserRelativeProfile,
    exact:true,
    auth:false,
    component:<UserRelativeProfile/>
  },
  {
    path:Path.QrCodeCreate,
    exact:true,
    auth:false,
    component:<QrCodeCreate/>
  },
  {
    path:Path.QrPriceCreate,
    exact:true,
    auth:false,
    component:<QrCreatePriceList/>
  },
  {
    path:Path.marchant,
    exact:true,
    auth:false,
    component:<Marchant/>
  },
  
  {
    path:Path.Paymentlist,
    exact:true,
    auth:false,
    component:<Payment/>
  },
  {
    path:Path.PaymentDetailPage,
    exact:true,
    auth:false,
    component:<PaymentDetailsPage/>
  },
  {
    path:Path.merchant_view,
    exact:true,
    auth:false,
    component:<MarchantView/>
  },
  {
    path:Path.qrhistory,
    exact:true,
    auth:false,
    component:<QrLinkedHistory/>
  }
];
export default routes;
