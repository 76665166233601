import { createAsyncThunk } from "@reduxjs/toolkit";

import * as URL from "helpers/url_helper";
import { get, post, put, del } from "helpers/api_helper";

export const getBadgesList = createAsyncThunk(
  "getBadgesList",
  async ({ data, callback }, Thunk) => {
    try {
      let response = await post(URL.GET_BADGE_LIST, data);
      callback && callback(response.data);
      return response;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);

export const AddBadge = createAsyncThunk(
  "AddBadge",
  async ({ data, callback }, Thunk) => {
    try {
      let response = await post(URL.ADD_BADGE, data);
      callback && callback(null, response);
      return response;
    } catch (error) {
      callback && callback(error.message, null);
      return Thunk.rejectWithValue(error);
    }
  }
);

export const editBadge = createAsyncThunk(
  "editBadge",
  async ({ data, callback }, Thunk) => {
    try {
      let response = await put(URL.EDIT_BADGE + data?._id, data);
      callback && callback(null, response);
      return response;
    } catch (error) {
      callback && callback(error.message, null);
      return Thunk.rejectWithValue(error);
    }
  }
);

export const deleteBadge = createAsyncThunk(
  "deleteBadge",
  async ({ data, callback }, Thunk) => {
    try {
      let response = await del(URL.DELETE_BADGE + data?._id);
      callback && callback(null, response);
      return response;
    } catch (error) {
      callback && callback(error.message, null);
      return Thunk.rejectWithValue(error);
    }
  }
);
