const inputFields = [
  {
    name: "languageName",
    type: "text",
    label: "Name",
    placeholder: "",
  },
  {
    name: "localeCode",
    type: "dynamic_select",
    label: "Code",
    placeholder: "",
    options: []
  },
];

export default inputFields;
