import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FadeLoader from "react-spinners/FadeLoader";
import { AVATAR } from "common/viewContent";
import * as Path from "routes/Path/index";
import { getAvatarList, AddAvatar, editAvatar, deleteAvatar } from "store/actions";
import { toast } from "react-toastify";
import Table from "components/Table";
import Columns from "./Columns";
import AddModal from "components/Modals/AddAvatar";
import FormikForm from "components/shared/FormikForm";

const InputFieldsForSearch = [
  {
    name: "search",
    type: "text",
    label: "",
    placeholder: "Search using name...",
  },
];
const initialValuesForFilter = {
  search: "",
};

const List = () => {
  const dispatch = useDispatch();
  const { avatars, loading, totalAvatars } = useSelector((state) => state.Avatar);
  const [columns, setcolumns] = useState([{ dataField: "", text: "" }]);

  const [filter, setFilter] = useState({
    pageNo: 1,
    limit: 20,
    order: -1,
    orderBy: "createdAt",
    search: "",
  });

  const [formData, setFormData] = useState({
    name: "",
    imageUrl: "",
    baseUrl: ""
  });

  const [addModalShow, setAddModalShow] = useState(false);

  const [editModalShow, setEditModalShow] = useState(false);

  useEffect(() => {
    getApiData(filter);
    setcolumns(Columns({ deleteHandler, handleEditHandler }));
  }, [filter]);

  async function getApiData(query) {
    try {
      dispatch(getAvatarList({ data: query }));
    } catch (error) { }
  }

  const deleteHandler = (id) => {
    try {
      const userConfirmed = window.confirm("Are you sure, you want to delete this avatar?");
      if (userConfirmed) {
        const callback = (err, res) => {
          if (err) {
            toast.error(err);
          } 
          else {
            getApiData(filter);
            toast.success(res.message);
          }
        };
        dispatch(  deleteAvatar({ data: { _id: id },  callback }));
      }
    } catch (error) {
      console.log(error, "<===err");
    }
  };

  const handleSubmit_ = (info) => {
    try {
      console.log(info, "info");
      const callback = (err, res) => {
        if (err) {
          toast.error(err);
          setAddModalShow(false);
        } 
        else {
          console.log(res);
          setAddModalShow(false);
          getApiData(filter);
          toast.success(res.message);
        }
      };
      dispatch(AddAvatar({ data: info,  callback }));
    } 
    catch (error) {
      console.log(error, "<===err");
    }
  };

  const handleFilterForm = (info) => {
    setFilter({
      ...filter,
      pageNo: 1,
      limit: 20,
      order: -1,
      orderBy: "createdAt",
      search: info.search,
    });
  };

  const handleEditHandler = (info) => {
    console.log({ ...formData, ...info });
    setFormData({ ...formData, ...info });
    setEditModalShow(true);
  };

  const handleEditSub = (info) => {
    try {
      const callback = (err, res) => {
        if (err) {
          toast.error(err);
          setEditModalShow(false);
        } 
        else {
          setEditModalShow(false);
          getApiData(filter);
          toast.success(res.message);
          setFormData({})
        }
      };
      let data = {
        _id: info._id,
        name: info.name,
        imageUrl: info.imageUrl,
        baseUrl: info.baseUrl,
      }
      dispatch( editAvatar({ data: data, callback }) );
    } 
    catch (error) {
      console.log(error, "<===err");
    }
  };
  return (
    <>
      <AddModal
        show={addModalShow}
        handleClose={() => {
          setAddModalShow(false);
        }}
        title={"Add Avatar"}
        content={""}
        handleConfirm={handleSubmit_}
        loading={loading}
        initialValues={formData}
      />

      <AddModal
        show={editModalShow}
        handleClose={() => {
          setEditModalShow(false);
        }}
        title={"Edit Avatar"}
        content={""}
        handleConfirm={handleEditSub}
        loading={loading}
        initialValues={formData}
      />
      {loading && (
        <div className="main-loader">
          <FadeLoader size={1000} />
        </div>
      )}
      <Helmet title={AVATAR.CURRENT_MODULE} />

      <section className="section">
        <div className="section-header">
          <h1>{AVATAR.CURRENT_MODULE}</h1>

          <div className="section-header-breadcrumb">
            <div className="breadcrumb-item active">
            <Link to={"/admin/"+ Path.dashboard}>Dashboard</Link>
            </div>
            <div className="breadcrumb-item">{AVATAR.CURRENT_MODULE} </div>
          </div>
        </div>
        <div className="section-body">
          <div className="card">
            <div className="row justify-content-between">
              <div className="col-6">
                <div className="card-header">
                  <h2 className="section-title">{AVATAR.TOP_CONTENT}</h2>
                </div>
              </div>
              <div className="col-6">
                <div className="card-header d-flex justify-content-end align-items-center">
                  <button
                    className="btn btn-primary"
                    onClick={() => setAddModalShow(true)}
                  >
                    Add Avatar
                  </button>
                </div>
              </div>
              <div className="col-12">
                <div className="card-header">
                  <FormikForm
                    initialValues={initialValuesForFilter}
                    onSubmit={handleFilterForm}
                    loading={loading}
                    inputFields={InputFieldsForSearch}
                    buttonText={"Search"}
                  />
                </div>
              </div>

            </div>
          </div>
          {avatars?.length > 0 && (
            <Table
              data={avatars}
              columns={columns}
              setFilter={setFilter}
              total={totalAvatars}
              filter={filter}
            />
          )}
          {!loading && avatars?.length === 0 && (
            <p style={{ textAlign: "center" }}>No Avatar Found</p>
          )}
        </div>
      </section>
    </>
  );
};

export default List;