import React, { useState } from "react";
import { Outlet, Navigate } from "react-router-dom";
import Helmet from "react-helmet";

//imported
import Header from "components/layout/Header";
import Sidebar from "components/layout/Sidebar";
import Footer from "components/layout/Footer";

const Layout = () => {
  return (
    <>
      <div className="w-100 d-flex adminLayout">
        <Sidebar type={"dashboard"} />
        <div className="main-wrapper">
          <Helmet titleTemplate={`%s | ${process.env.REACT_APP_APPNAME}`} />
          <Header />
          <div className="main-content" style={{ minHeight: "705px" }}>
            <Outlet />
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Layout;
