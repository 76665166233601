import * as Yup from 'yup';

const Validation = Yup.object({
    name: Yup.string().required("Please enter name!"),
    categoryId: Yup.string().required("Please select category!"),
    imageUrl: Yup.string().required("Please upload logo!"),
    price: Yup.number().required('Please enter price!')
});

export default Validation;
