import { createAsyncThunk } from "@reduxjs/toolkit";

import * as URL from "helpers/url_helper";

import { get, post, put, del } from "helpers/api_helper";

export const CreateQR = createAsyncThunk(
  "CreateQR",
  async ({ callback }, Thunk) => {
    try {
      let response = await post(URL.CREATE_QR_CODE);
      callback && callback(null, response.data);
      return response;
    } catch (error) {
      callback && callback(error, null);
      return Thunk.rejectWithValue(error);
    }
  }
);

//qr details api calling
export const QRDETAILS = createAsyncThunk(
  "QRDETAILS",
  async ({ data, callback }, Thunk) => {
    try {
      let response = await post(URL.QRCODE_LIST , data);
      callback && callback(null, response.data);
      return response;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);
