const inputFields = [
    {
      name: 'old_password',
      type: 'password',
      label: 'Current Password*',
      placeholder: 'Old Password...',
    },
    {
      name: 'new_password',
      type: 'password',
      label: 'New Password*',
      placeholder: 'New password...',
    },
    {
      name: 'confirm_password',
      type: 'password',
      label: 'Confirm Password*',
      placeholder: 'Confirm password...',
    },
];

export default inputFields;