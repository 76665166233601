import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSettings, updateSettings } from "store/actions";
import { Card, CardBody, CardTitle } from "reactstrap";
import { toast } from "react-toastify";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import FormikForm from "components/shared/FormikForm";
import ToggleSwitch from "components/ToggleSwitch";
import { formInputFields } from "./Formik/inputFields";
import { CONFIGURATION } from "common/viewContent";
import * as Path from "routes/Path/index";

const GeneralSetting = () => {
  const dispatch = useDispatch();
  const { settings, loading } = useSelector((state) => state.Settings);

  useEffect(() => {
    dispatch(getSettings());
  }, [dispatch]);

  const [fields, setFields] = useState({
    _id: "",
    contactEmailAddress: "",
    contactPhoneNumber: "",
    paymentMode: "",
    s3BaseUrl: "",
    youtube_tutorial_link: "",
     facebook: "", instagram: "", tiktok: "" ,
  });

  
  useEffect(() => {
    if (settings && Object.keys(settings).length !== 0) {
      setFields({
        _id: settings?._id,
        contactEmailAddress: settings.contactEmailAddress || "",
        contactPhoneNumber: settings.contactPhoneNumber || "",
        paymentMode: settings.paymentMode || "",
        s3BaseUrl: settings.s3BaseUrl || "",
        youtube_tutorial_link: settings.youtube_tutorial_link || "",
    
          facebook: settings?.socialmedia_link?.facebook || "",
          instagram: settings?.socialmedia_link?.instagram || "",
          tiktok: settings?.socialmedia_link?.tiktok || "",
    
      });
    }
  }, [settings]);

  const handleSubmit = (values) => {
    console.log(values, " values");
    const payload = {
      _id: settings?._id,
      contactEmailAddress: values.contactEmailAddress || "",
      contactPhoneNumber: values.contactPhoneNumber || "",
      paymentMode: values.paymentMode || "",
      s3BaseUrl: values.s3BaseUrl || "",
      youtube_tutorial_link: values.youtube_tutorial_link || "",
      socialmedia_link : {
        facebook : values?.facebook,
        tiktok : values?.tiktok,
        instagram : values?.instagram,
      } 
    };

    dispatch(
      updateSettings({
        data: payload,

        callback: (err) => {
          if (err) {
            toast.error(err);
          } else {
            toast.success("Settings updated successfully");
          }
        },
      })
    );
  };

  const handleModeChange = (event) => {
    setFields({
      ...fields,
      paymentMode: event.target.checked ? "Live" : "Sandbox",
    });
  };

  return (
    <React.Fragment>
      <Helmet title={CONFIGURATION.CURRENT_MODULE} />
      <section className="section">
        <div className="section-header">
          <h1>{CONFIGURATION.CURRENT_MODULE}</h1>
          <div className="section-header-breadcrumb">
            <div className="breadcrumb-item active">
              <Link to={"/admin/" + Path.dashboard}>Dashboard</Link>
            </div>
            <div className="breadcrumb-item">Configuration</div>
          </div>
        </div>
      </section>
      <Card>
        <CardBody className="spinner-content">
          <CardTitle>
            <b>General Settings</b>
          </CardTitle>
          <FormikForm
            initialValues={fields}
            onSubmit={handleSubmit}
            loading={loading}
            inputFields={formInputFields}
            submitting={loading}
            buttonText={"Update Configuration"}
            col={6}
          >
            <ToggleSwitch
              label="Payment Mode"
              checked={fields.paymentMode === "Live"}
              onChange={handleModeChange}
            />
          </FormikForm>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default GeneralSetting;
