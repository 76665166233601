import React, { useState, useEffect, useCallback } from "react";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FadeLoader from "react-spinners/FadeLoader";
import { LEVEL } from "common/viewContent";
import * as Path from "routes/Path/index";
import { getLevels, updateLevel, addLevel } from "store/actions";
import { toast } from "react-toastify";
import Table from "components/Table";
import Columns from "./Columns";
import AddLevelModal from "components/Modals/AddLevel";
import FormikForm from "components/shared/FormikForm";

const InputFieldsForSearch = [
  {
    name: "",
    type: "text",
    label: "",
    placeholder: "Search using level and numbers...",
  },
];
const initialValuesForFilter = {
  search: ""
};

const List = () => {
  const dispatch = useDispatch();

  const { levels, loading, totalCount } = useSelector((state) => state.Levels);

  const [columns, setcolumns] = useState([{ dataField: "", text: "" }]);

  const [filter, setFilter] = useState({
    pageNo: 1,
    limit: 20,
    order: -1,
    orderBy: "level",
    search: "",
  });

  const [formData, setFormData] = useState({
    _id: "",    
    level: "",
    numbers: "",
    frequencies: null,
    isReviewLevel: false,
    reviewData: null,
    status: "active",
    passCount: 0,
    timeInSec: 0,
    starsAwarded: 0
  });

  const [addModalShow, setAddModalShow] = useState(false);

  const [editModalShow, setEditModalShow] = useState(false);

  useEffect(() => {
    getApiData(filter);
    setcolumns(Columns({ handleEditHandler }));
  }, [filter]);

  async function getApiData(query) {
    try {
      dispatch(getLevels({ data: query }));
    } catch (error) { }
  }

  const handleEditHandler = (info) => {
    console.log("info--->",{ ...formData, ...info });
    setFormData((pre) => ({
      ...pre,
      _id: info._id,
      level: info.level,
      numbers: info.numbers?.join(','),
      frequencies: info?.frequencies? JSON.stringify(info?.frequencies): null,
      isReviewLevel: info.isReviewLevel,
      reviewData:  info?.reviewData? JSON.stringify(info?.reviewData) : null,
      status: info.status,
      passCount: info.passCount,
      timeInSec: info.timeInSec,
      starsAwarded: info.starsAwarded
    }))
    
    setEditModalShow(true);
  };

  const handleEditSub = (info) => {
    try {
      console.log('info----->', info);
      const updateData = {
        _id: info._id,
        level: parseInt(info.level),
        numbers: info.numbers?.split(','),
        frequencies:JSON.parse(info?.frequencies),
        isReviewLevel: info.isReviewLevel,
        reviewData:  JSON.parse(info?.reviewData),
        passCount: info.passCount,
        timeInSec: info.timeInSec,
        starsAwarded: info.starsAwarded
      }
      updateData.numbers = updateData.numbers.map(Number);
      console.log('updateData----->', updateData);
      // info.numbers = info.numbers.split(',');
      const callback = (err, res) => {
        if (err) {
          toast.error(err);
        } else {
          setEditModalShow(false);
          getApiData(filter);
          toast.success("Level updated successfully!");
          setFormData((pre) => ({
            ...pre,
            _id: "",
            level: "",
            numbers: "",
            frequencies: "",
            isReviewLevel: false,
            reviewData: "",
            status: "active",
            passCount: 0,
            timeInSec: 0,
            starsAwarded: 0
          }))
        }
      };
      dispatch(
        updateLevel({
          data: updateData,
          callback,
        })
      );
    } catch (error) {
      console.log(error, "<===err");
    }
  };

  const handleSubmit_ = (info) => {
    try {
      console.log('info----->', info);
      const addD = {
        level: parseInt(info.level),
        numbers: info.numbers?.split(','),
        frequencies:info?.frequencies,
        isReviewLevel: info.isReviewLevel,
        reviewData: info?.reviewData,
        passCount: info?.passCount,
        timeInSec: info?.timeInSec,
        starsAwarded: info?.starsAwarded
      }
      addD.numbers = addD.numbers.map(Number);
      console.log('addD----->', addD);
      const callback = (err, res) => {
        if (err) {
          toast.error(err);
        } 
        else {
          console.log(res);
          setAddModalShow(false);
          getApiData(filter);
          toast.success(res.message);
        }
      };
      dispatch( addLevel({  data: addD,  callback  }));
    } 
    catch (error) {
      console.log(error, "<===err");
    }
  };

  const handleFilterForm = (info) => {
    setFilter({
      ...filter,
      pageNo: 1,
      limit: 20,
      order: -1,
      orderBy: "level",
      search: info.search,
    });
  };

  return (
    <>
      <AddLevelModal
        show={addModalShow}
        handleClose={() => {
          setAddModalShow(false);
        }}
        title={"Add Level"}
        content={""}
        handleConfirm={handleSubmit_}
        loading={loading}
        initialValues={formData}
      />

      <AddLevelModal
        show={editModalShow}
        handleClose={() => {
          setEditModalShow(false);
        }}
        title={"Edit Project"}
        content={""}
        handleConfirm={handleEditSub}
        loading={loading}
        initialValues={formData}
      />
      {loading && (
        <div className="main-loader">
          <FadeLoader size={1000} />
        </div>
      )}
      <Helmet title={LEVEL.CURRENT_MODULE} />

      <section className="section">
        <div className="section-header">
          <h1>{LEVEL.CURRENT_MODULE}</h1>

          <div className="section-header-breadcrumb">
            <div className="breadcrumb-item active">
            <Link to={"/admin/"+ Path.dashboard}>Dashboard</Link>
            </div>
            <div className="breadcrumb-item">{LEVEL.CURRENT_MODULE} </div>
          </div>
        </div>
        <div className="section-body">
          <div className="card">
            <div className="row justify-content-between">
              <div className="col-6">
                <div className="card-header">
                  <h2 className="section-title">{LEVEL.TOP_CONTENT}</h2>
                </div>
              </div>
              <div className="col-6">
                <div className="card-header d-flex justify-content-end align-items-center">
                  <button
                    className="btn btn-primary"
                    onClick={() => setAddModalShow(true)}
                  >
                    Add Level
                  </button>
                </div>
              </div>
              <div className="col-12">
                <div className="card-header">
                  <FormikForm
                    initialValues={initialValuesForFilter}
                    onSubmit={handleFilterForm}
                    loading={loading}
                    inputFields={InputFieldsForSearch}
                    buttonText={"Search"}
                  />
                </div>
              </div>


            </div>
          </div>
          {levels?.length > 0 && (
            <Table
              data={levels}
              columns={columns}
              setFilter={setFilter}
              total={totalCount}
              filter={filter}
            />
          )}
          {!loading && levels?.length === 0 && (
            <p style={{ textAlign: "center" }}>No Level Found</p>
          )}
        </div>
      </section>
    </>
  );
};

export default List;