import { createAsyncThunk } from "@reduxjs/toolkit";

import * as URL from "helpers/url_helper";

import { get, post, put } from "helpers/api_helper";

export const getUsers = createAsyncThunk(
  "getUsers",
  async ({ data, callback }, Thunk) => {
    try {
      let response = await post(URL.GET_USERS, data);
      callback && callback(null, response.data);
      return response;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);

//userdetails api calling
export const USER_PROFILE = createAsyncThunk(
  "USER_PROFILE",
  async ({ data, callback }, Thunk) => {
    try {
      let response = await get(URL.USER_PROFILE + data);
      callback && callback(null, response.data);
      return response;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);


export const updateUser = createAsyncThunk(
  "updateUser",
  async ({ data, callback }, Thunk) => {
    try {
      let response = await put(URL.UPDATE_USER + data?._id, data);
      callback && callback(null, response.data);
      return response;
    } catch (error) {
      callback && callback(error?.data?.message || error.message || error);
      return Thunk.rejectWithValue(error);
    }
  }
);

export const AddUser = createAsyncThunk(
  "AddUser",
  async ({ data, callback }, Thunk) => {
    try {
      let response = await post(URL.ADD_USER, data);
      callback && callback(null, response);
      return response;
    } catch (error) {
      callback && callback(error?.data?.message || error.message || error);
      return Thunk.rejectWithValue(error);
    }
  }
);
