const inputFields = [
  {
    name: "categoryId",
    type: "dynamic_select",
    label: "Category*",
    placeholder: "Select Category...",
    options: [ ]
  },
  {
    name: "name",
    type: "text",
    label: "Name*",
    placeholder: "Name...",
  },
  {
    name: "price",
    type: "text",
    label: "Price*",
    placeholder: "Price...",
  },
  {
    name: "imageUrl",
    type: "image",
    label: "Logo*",
    placeholder: "Select Logo...",
  },
];

export default inputFields;
