import { DATE_FORMAT } from "helpers/common";
import React from "react";
import Badge from "react-bootstrap/Badge";
import { Link } from "react-router-dom";

const STATUS = {
  "active": "Active",
  "deleted": "Deleted"
}
const TYPE = {
  "outfit": "Outfit",
  "skin": "Skin"
}
const Columns = ({ deleteHandler, handleEditHandler }) => [
  {
    dataField: "name",
    text: "Name",
    formatter: (_, row) => <span className="text-capitalize">{row.name}</span>,
  },
  {
    dataField: "type",
    text: "Type",
    formatter: (_, row) => <span className="text-capitalize">{TYPE[row.type]}</span>,
  },
  {
    dataField: "imageUrl",
    text: "Logo",
    formatter: (_, row) => <img
      src={row?.baseUrl + row?.imageUrl}
      className="main-sidebar-logo img-fluid img-badge-logo"
    />,
  },
  {
    text: "Created At",
    dataField: "dateCreated",
    formatter: (_, row) => DATE_FORMAT(row?.createdAt),
  },
  {
    text: "Action",
    formatter: (_, row) => (
      <>
        <button
          className="fas fa-pen"
          style={{
            fontSize: "16px",
            backgroundColor: "#fff",
            border: "none",
          }}
          title="Edit"
          onClick={() =>
            handleEditHandler(row)
          }
        ></button>

        <button
          className="fas fa-trash-alt"
          style={{
            backgroundColor: "#fff",
            border: "none",
          }}
          title="Inactive"
          onClick={() => deleteHandler(row._id)}
        ></button>

      </>
    ),
  },
];
export default Columns;