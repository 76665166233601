import { DATE_FORMAT } from "helpers/common";
import Badge from "react-bootstrap/Badge";
import React from "react";
import { Link } from "react-router-dom";

const STATUS = {
  "yes":"Active", 
  "no":"Deleted"
}

const Columns = ({ deleteHandler, handleEdit }) => [
  {
    dataField: "languageName",
    text: "Name",
    formatter: (_, row) => (
      <span className="text-capitalize">{row?.languageName}</span>
    ),
  },
  {
    dataField: "languageNativeName",
    text: "Native Name",
    formatter: (_, row) => (
      <span className="text">{row?.languageNativeName}</span>
    ),
  },
  {
    dataField: "localeCode",
    text: "Code",
    formatter: (_, row) => (
      <span className="text">{row?.localeCode}</span>
    ),
  },
  {
    dataField: "countryName",
    text: "Country",
    formatter: (_, row) => (
      <span className="text-capitalize">{row?.countryName}</span>
    ),
  },
  {
    dataField: "flagUrl",
    text: "Flag",
    formatter: (_, row) => (
      <img
      src={row?.flagUrl}
      className="main-sidebar-logo img-fluid fullIcn"
    />
    ),
  },
  {
    text: "Status",
    dataField: "status",
    formatter: (cellContent, row) => (
      <Badge
        className={
          `text-capitalize font-size-13 badge-soft-${row.status == "yes"? "Active" : "Inactive"}`
        }
        color={row.badgeClass}
      >
        {(STATUS[row.status])}
      </Badge>
    ),
    // formatter: (_, row) => (
    //   <Badge bg="secondary">{row.status == "yes"? "Active" : "Inactive"}</Badge>
    // ),
  },
  {
    text: "Created At",
    dataField: "dateCreated",
    formatter: (_, row) => DATE_FORMAT(row.dateCreated),
  },
  {
    text: "Action",
    // dataField: "Inactive",
    formatter: (_, row) => (
      <>
        <button
          className="fas fa-pen"
          title="Edit"
          style={{
            backgroundColor: "#fff",
            border: "none",
          }}
          onClick={() => handleEdit(row)}
        ></button>
        {/* {row.status == "yes" ? (
          <button
            className="fas fa-toggle-on"
            style={{
              fontSize: "20px",
              color: "green",
              backgroundColor: "#fff",
              border: "none",
            }}
            title="Active"
            onClick={() => handleStatus(row._id, "inactive")}
          ></button>
        ) : (
          <button
            className="fas fa-toggle-off"
            style={{
              fontSize: "20px",
              color: "red", 
              backgroundColor: "#fff",
              border: "none",
            }}
            title="Inactive"
            onClick={() => handleStatus(row._id, "active")}
          ></button>
        )} */}

        {
          <button
            className="fas fa-trash-alt"
            style={{
              backgroundColor: "#fff",
              border: "none",
            }}
            title="Inactive"
            onClick={() => deleteHandler(row._id)}
          ></button>
        }
      </>
    ),
  },
];
export default Columns;
