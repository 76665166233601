const inputFields = [
  {
    name: "languageId",
    type: "dynamic_select",
    label: "Select Language",
    placeholder: "",
    options: [],
  },
  {
    name: "question",
    type: "textarea",
    label: "Question",
    placeholder: "",
  },
  {
    name: "answer",
    type: "ckeditor",
    label: "Answer",
    placeholder: "",
  },
];

export default inputFields;
