import { createSlice, current } from "@reduxjs/toolkit";
import * as Act from "./actions";

const initialState = {
  loading: false,
  relative: [],
  totalCount: 0,
};

const slice = createSlice({
  name: "relative",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    
    // view user relative
    builder.addCase(Act.USER_RELATIVE.pending, (state, action) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(Act.USER_RELATIVE.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
      // user: payload,
    }));
    builder.addCase(Act.USER_RELATIVE.rejected, (state, action) => ({
      ...state,
      loading: false,
      user: null,
    }));
    // user relative profile
    builder.addCase(Act.USER_RELATIVE_PROFILE.pending, (state, action) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(Act.USER_RELATIVE_PROFILE.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
      relative: payload,
    }));
    builder.addCase(Act.USER_RELATIVE_PROFILE.rejected, (state, action) => ({
      ...state,
      loading: false,
      relative: null,
    }));
    // delete photo
   builder.addCase(Act.deletePhoto.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    builder .addCase(Act.deletePhoto.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;

    })
    builder.addCase(Act.deletePhoto.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || action.error;
    });

    // delete video
    builder.addCase(Act.deleteVideo.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    builder .addCase(Act.deleteVideo.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;

    })
    builder.addCase(Act.deleteVideo.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || action.error;
    });
    // arcieve users relatives
    builder.addCase(Act.UPDATEUSER_RELATIVE_STATUS.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    builder .addCase(Act.UPDATEUSER_RELATIVE_STATUS.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;

    })
    builder.addCase(Act.UPDATEUSER_RELATIVE_STATUS.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || action.error;
    });
    //images update
   
    builder.addCase(Act.updateImage.pending, (state, action) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(Act.updateImage.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
    }));
    builder.addCase(Act.updateImage.rejected, (state, action) => ({
      ...state,
      loading: false,
    }));
    

    // videos update
    builder.addCase(Act.updateVideo.pending, (state, action) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(Act.updateVideo.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
    }));
    builder.addCase(Act.updateVideo.rejected, (state, action) => ({
      ...state,
      loading: false,
    }));
   
    
  },
});

export default slice;
