import React, { useState, useEffect, useCallback } from "react";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FadeLoader from "react-spinners/FadeLoader";
import { BADGE } from "common/viewContent";
import * as Path from "routes/Path/index";
import { getBadgesList, AddBadge, editBadge, deleteBadge } from "store/actions";
import { toast } from "react-toastify";
import Table from "components/Table";
import Columns from "./Columns";
import AddBadgeModal from "components/Modals/AddBadge";
import FormikForm from "components/shared/FormikForm";

const InputFieldsForSearch = [
  {
    name: "search",
    type: "text",
    label: "",
    placeholder: "Search using name, level...",
  },
  {
    name: "select",
    type: "select",
    label: "",
    placeholder: "Select Category",
    options: [
      { value: "", label: "All" },
      { value: "level", label: "Level" },
      { value: "operation", label: "Operation" },
      { value: "practiceDays", label: "Day's Practice" },
      { value: "practiceMins", label: "Min's Practice" },
    ],
  },
];
const initialValuesForFilter = {
  search: "",
  select: "",
};

const List = () => {
  const dispatch = useDispatch();
  const { badges, loading, totalCount } = useSelector((state) => state.Badge);
  const [columns, setcolumns] = useState([{ dataField: "", text: "" }]);

  const [filter, setFilter] = useState({
    pageNo: 1,
    limit: 20,
    order: -1,
    orderBy: "createdAt",
    search: "",
    category: ""
  });

  const [formData, setFormData] = useState({
    name: "",
    category: "",
    count: 0,
    levelType: "",
    imageUrl: "",
    baseUrl: ""
  });

  const [addModalShow, setAddModalShow] = useState(false);

  const [editModalShow, setEditModalShow] = useState(false);

  useEffect(() => {
    getApiData(filter);
    setcolumns(Columns({ deleteHandler, handleEditHandler }));
  }, [filter]);

  async function getApiData(query) {
    try {
      dispatch(getBadgesList({ data: query }));
    } catch (error) { }
  }

  const deleteHandler = (id) => {
    try {
      const userConfirmed = window.confirm("Are you sure, you want to delete this badge?");
      if (userConfirmed) {
        const callback = (err, res) => {
          if (err) {
            toast.error(err);
          } else {
            getApiData(filter);
            toast.success(res.message);
          }
        };

        dispatch(
          deleteBadge({
            data: { _id: id },
            callback,
          })
        );
      }
    } catch (error) {
      console.log(error, "<===err");
    }
  };

  const handleSubmit_ = (info) => {
    try {
      console.log(info, "info");
      const callback = (err, res) => {
        if (err) {
          toast.error(err);
        } else {
          console.log(res);
          setAddModalShow(false);
          getApiData(filter);
          toast.success(res.message);
        }
      };
      dispatch(
        AddBadge({
          data: info,
          callback,
        })
      );
    } 
    catch (error) {
      console.log(error, "<===err");
    }
  };

  const handleFilterForm = (info) => {
    setFilter({
      ...filter,
      pageNo: 1,
      limit: 20,
      order: -1,
      orderBy: "createdAt",
      search: info.search,
      category: info.select,
    });
  };

  const handleEditHandler = (info) => {
    console.log({ ...formData, ...info });
    setFormData({ ...formData, ...info });
    setEditModalShow(true);
  };

  const handleEditSub = (info) => {
    try {
      console.log('info', info);
      const callback = (err, res) => {
        if (err) {
          toast.error(err);
        } else {
          setEditModalShow(false);
          getApiData(filter);
          toast.success(res.message);
          setFormData({})
        }
      };
      let data = {
        _id: info._id,
        name: info.name,
        category: info.category,
        count: info.count,
        levelType: info.levelType,
        imageUrl: info.imageUrl,
        baseUrl: info.baseUrl,
      }
      dispatch(
        editBadge({
          data: data,
          callback,
        })
      );
    } catch (error) {
      console.log(error, "<===err");
    }
  };
  console.log("badges",badges);
  return (
    <>
      <AddBadgeModal
        show={addModalShow}
        handleClose={() => {
          setAddModalShow(false);
        }}
        title={"Add Badge"}
        content={""}
        handleConfirm={handleSubmit_}
        loading={loading}
        initialValues={formData}
      />

      <AddBadgeModal
        show={editModalShow}
        handleClose={() => {
          setEditModalShow(false);
        }}
        title={"Edit Badge"}
        content={""}
        handleConfirm={handleEditSub}
        loading={loading}
        initialValues={formData}
      />
      {loading && (
        <div className="main-loader">
          <FadeLoader size={1000} />
        </div>
      )}
      <Helmet title={BADGE.CURRENT_MODULE} />

      <section className="section">
        <div className="section-header">
          <h1>{BADGE.CURRENT_MODULE}</h1>

          <div className="section-header-breadcrumb">
            <div className="breadcrumb-item active">
            <Link to={"/admin/"+ Path.dashboard}>Dashboard</Link>
            </div>
            <div className="breadcrumb-item">{BADGE.CURRENT_MODULE} </div>
          </div>
        </div>
        <div className="section-body">
          <div className="card">
            <div className="row justify-content-between">
              <div className="col-6">
                <div className="card-header">
                  <h2 className="section-title">{BADGE.TOP_CONTENT}</h2>
                </div>
              </div>
              <div className="col-6">
                <div className="card-header d-flex justify-content-end align-items-center">
                  <button
                    className="btn btn-primary"
                    onClick={() => setAddModalShow(true)}
                  >
                    Add Badge
                  </button>
                </div>
              </div>
              <div className="col-12">
                <div className="card-header">
                  <FormikForm
                    initialValues={initialValuesForFilter}
                    onSubmit={handleFilterForm}
                    loading={loading}
                    inputFields={InputFieldsForSearch}
                    buttonText={"Search"}
                  />
                </div>
              </div>

            </div>
          </div>
          {badges?.length > 0 && (
            <Table
              data={badges}
              columns={columns}
              setFilter={setFilter}
              total={totalCount}
              filter={filter}
            />
          )}
          {!loading && badges?.length === 0 && (
            <p style={{ textAlign: "center" }}>No Badge Found</p>
          )}
        </div>
      </section>
    </>
  );
};

export default List;