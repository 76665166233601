import { createSlice, current } from "@reduxjs/toolkit";
import * as Act from "./actions";

const initialState = {
  loading: false,
  langList: [],
  totalCount: 0,
  languageCodes: [],
  languageDetail: {}
};

const slice = createSlice({
  name: "language",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get language list
    builder.addCase(Act.getLanguageList.pending, (state, action) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(Act.getLanguageList.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
      langList: payload.data,
      totalCount: payload.totalCount,
    }));
    builder.addCase(Act.getLanguageList.rejected, (state, action) => ({
      ...state,
      loading: false,
      langList: [],
      totalCount: 0,
    }));
    
    //get language codes
    builder.addCase(Act.getLanguagesCode.pending, (state, action) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(Act.getLanguagesCode.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
      languageCodes: payload.data,
    }));
    builder.addCase(Act.getLanguagesCode.rejected, (state, action) => ({
      ...state,
      loading: false,
      languageCodes: [],
    }));

    //add language
    builder.addCase(Act.addLanguage.pending, (state, action) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(Act.addLanguage.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
      languageDetail: payload.data,
    }));
    builder.addCase(Act.addLanguage.rejected, (state, action) => ({
      ...state,
      loading: false,
      languageDetail: {},
    }));

    //edit language
    builder.addCase(Act.editLanguage.pending, (state, action) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(Act.editLanguage.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
      languageDetail: payload.data,
    }));
    builder.addCase(Act.editLanguage.rejected, (state, action) => ({
      ...state,
      loading: false,
      languageDetail: {},
    }));
  },
});

export default slice;
