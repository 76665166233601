import React, { useEffect, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import LoadingButton from "components/shared/LoadingButton";
import FormikForm from "components/shared/FormikForm";
import Validation from "views/marchant/Validation";
import InputFields from "views/marchant/inputFields";
import { DEFAULT_COUNTY_CODE } from "helpers/common";

export default function EditMarchant({
  show,
  handleClose,
  handleConfirm,
  title,
  content,
  loading,
  initialValues,
}) {
  const countries = require("../../../helpers/json/countries.json");
  const [selectedCountry, setSelectedCountry] = useState(DEFAULT_COUNTY_CODE);
  
  useEffect(()=>{
    if(initialValues){
      const country = countries?.find(
        (item) => item?.phoneCode === initialValues?.countryCode?.toString()
      )?.countryCode;
      setSelectedCountry(country?.toLowerCase());
    }
  },[
    initialValues
  ])
 

  return (
    <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <Modal.Header className="py-3 text-dark" closeButton>
          <Modal.Title className="fw-bold text-dark">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormikForm
            initialValues={initialValues}
            validationSchema={Validation}
            onSubmit={handleConfirm}
            loading={loading}
            inputFields={InputFields}
            buttonText={"Submit"}
            formSize="md"
            col={6}
            selectedCountry={selectedCountry}
          />
        </Modal.Body>
      </Modal>
  );
}