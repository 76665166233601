const inputFields = [
  {
    name: "languageId",
    type: "dynamic_select",
    label: "Select Language",
    placeholder: "",
    options: [
      {
        value: "English", label: "English"
      },
      {
        value: "Georgian", label: "Georgian"
      }
    ],
  },
  {
    name: "name",
    type: "select",
    label: "Name",
    placeholder: "",
    options: [{
      value: "ABOUT_US", label: "ABOUT US"
    },{
      value: "PRIVACY_POLICY", label: "PRIVACY POLICY"
    },{
      value: "TERMS_CONDITIONS", label: "TERMS & CONDITIONS"
    },{
      value: "CONTACT_US", label: "CONTACT US"
    }],
  },
  {
    name: "content",
    type: "ckeditor",
    label: "Content",
    placeholder: "",
  },
];

export default inputFields;
