const inputFields = [
  {
    name: "name",
    type: "text",
    label: "Name*",
    placeholder: "Name...",
  },
  {
    name: "imageUrl",
    type: "image",
    label: "Logo*",
    placeholder: "Select Logo...",
  },
];

export default inputFields;
